import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { postApi } from "../../utils/api";
import { API_PUNCH } from "../../config/Endpoints";
import { toast } from "react-toastify";

const RaiseConcern = () => {
  const [formData, setFormData] = useState({
    concernDate: "",
    reason: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const accessToken = Cookies.get("accessToken");

  const handleInputChange = (event) => {
    const { name, value, type } = event.target;
    if (type === "date") {
      const year = value.split("-")[0];
      if (year.length <= 4) {
        const currentDate = new Date().toISOString().split("T")[0];
        // If the entered date is greater than the current date, revert to the current date
        if (value > currentDate) {
          toast.error(
            "Future dates are not allowed. Input reverted to current date.",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: currentDate,
          }));
        } else {
          setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
          }));
        }
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleReset = () => {
    setFormData({
      concernDate: "",
      reason: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const requestData = {
        concernDate: formData.concernDate,
        reason: formData.reason,
      };

      await postApi(API_PUNCH, requestData, accessToken)
        .then((res) => {
          setIsLoading(false);
          const modalClose =
            document.getElementsByClassName(" cancel-modal")[0];
          modalClose.click();
          toast.success("A concern request has been successfully raised!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
        .catch((e) => {
          toast.error("Something went wrong please try again !", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsLoading(false);
        });
    } catch (error) {
      setIsLoading(false);
    }
  };

  // Get today's date in the format yyyy-mm-dd
  const today = new Date().toISOString().split("T")[0];

  return (
    <form onSubmit={handleSubmit}>
      <div className="modal-header">
        <h5 className="modal-title text-center flex" id="staticBackdropLabel">
          Raise Concern
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleReset}
        ></button>
      </div>
      <div className="modal-body">
        <div className="row mt-3">
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <label htmlFor="concernDate" className="has-asterisk">
              Concern Date
            </label>
          </div>
          <div className="col-md-4 ">
            <input
              type="date"
              name="concernDate"
              className="border"
              value={formData.concernDate}
              placeholder="08/02/2019"
              onChange={handleInputChange}
              max={today}
              required
            />
          </div>
          <div className="col-md-2"></div>
        </div>

        <div className="row mt-3">
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <label htmlFor="reason" className="has-asterisk">
              Brief Reason
            </label>
          </div>
          <div className="col-md-4 ">
            <textarea
              id="reason"
              name="reason"
              required
              value={formData.reason}
              onChange={handleInputChange}
              rows={3}
              cols={20}
              className="border"
              maxLength={160}
              onKeyPress={(e) => {
                if (e.charCode === 32 && e.target.selectionStart === 0) {
                  e.preventDefault(); // Prevent adding space at the beginning
                }
              }}
            ></textarea>
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>

      <div className="justify-content-center modal-footer">
        <button
          type="submit"
          className={isLoading ? "theme-button disabled " : "theme-button "}
        >
          {isLoading ? (
            <ThreeDots
              height="20"
              width="53"
              radius="9"
              color="white"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          ) : (
            "Submit"
          )}
        </button>
        <button
          type="button"
          className="theme-button bg-grey mx-3 w-35 cancel-modal"
          data-bs-dismiss="modal"
          onClick={handleReset}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default RaiseConcern;
