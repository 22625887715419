import React, { useEffect, useState } from "react";
import { API_MY_ATTANDANCE } from "../../config/Endpoints";
import { getApi } from "../../utils/api";
import Cookies from "js-cookie";
import { TailSpin } from "react-loader-spinner";
import { formatLeaveType } from "../../utils/helpers";

const MyAttendanceCalendar = ({ selectedEmployee }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [punchData, setPunchData] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [selectedAttendance, setSelectedAttendance] = useState({});
  const accessToken = Cookies.get("accessToken");

  const currentMonth = new Date().toLocaleString("default", {
    month: "long",
    year: "numeric",
  });
  // month = currentDate.getMonth();
  useEffect(() => {
    if (selectedEmployee) {
      const currentMonth = new Date()
        .toLocaleString("en-US", { month: "long" })
        .toUpperCase();
      const currentYear = new Date().getFullYear();

      fetchPunchData(currentMonth, currentYear);
    } else {
      setCurrentDate(new Date());
    }
  }, [selectedEmployee]);

  const fetchPunchData = async (month, year) => {
    setIsLoading(true);
    await getApi(
      API_MY_ATTANDANCE +
        `?month=${month}&year=${year}&empid=${selectedEmployee?.id}`,
      accessToken
    )
      .then((res) => {
        setPunchData(res?.data?.data);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setPunchData([]);
        setIsLoading(false);
      });
  };

  const goToPreviousMonth = () => {
    const previousMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 1,
      1
    );
    setCurrentDate(previousMonth);
    const currentMonth = new Date(previousMonth)
      .toLocaleString("en-US", { month: "long" })
      .toUpperCase();
    const year = previousMonth.getFullYear();
    fetchPunchData(currentMonth, year);
  };

  const goToNextMonth = () => {
    const nextMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      1
    );
    setCurrentDate(nextMonth);
    const currentMonth = new Date(nextMonth)
      .toLocaleString("en-US", { month: "long" })
      .toUpperCase();
    const year = nextMonth.getFullYear();
    fetchPunchData(currentMonth, year);
  };

  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const getWeekday = (year, month, day) => {
    return new Date(year, month, day).getDay(); // 0 for Sunday, 1 for Monday, etc.
  };

  const generateCalendar = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const daysInMonth = getDaysInMonth(year, month);
    const firstDayOfWeek = getWeekday(year, month, 1);

    const calendar = [];
    let day = 1;
    for (let i = 0; i < 6; i++) {
      // Assuming a maximum of 6 rows for the calendar
      const week = [];
      for (let j = 0; j < 7; j++) {
        if ((i === 0 && j < firstDayOfWeek) || day > daysInMonth) {
          week.push(<td key={`${i}-${j}`} className="empty"></td>);
        } else {
          const weekday = getWeekday(year, month, day);
          const isWeekend = weekday === 0 || weekday === 6;
          let dayData;
          if (punchData.length > 0) {
            const dateStr = `${getDayOfWeek(day, month, year)}, ${getMonthName(
              month
            )} ${day.toString().padStart(2, "0")}`;
            dayData = punchData.find((d) => d.date === dateStr);
          }

          const dayClass = isWeekend ? "weekoff" : ""; // Mark weekends as week-offs
          week.push(
            <td
              key={`${i}-${j}`}
              className={
                dayData
                  ? dayData.leave_type === "Present"
                    ? "positive"
                    : dayData.leave_type === "Absent"
                    ? "negative"
                    : dayData.leave_type === "Holiday"
                    ? "holiday"
                    : dayData.leave_type === "PrivilegeLeave"
                    ? "leave"
                    : dayClass
                  : dayClass
              }
              data-bs-toggle={
                dayData && dayData?.leave_type !== "Week-off" && "modal"
              }
              data-bs-target={
                dayData &&
                dayData?.leave_type !== "Week-off" &&
                "#staticBackdropView"
              }
              onClick={
                dayData && dayData?.leave_type !== "Week-off"
                  ? () => setSelectedAttendance(dayData)
                  : null
              }
            >
              <span>{day}</span>
              <p className="status">
                {dayData
                  ? formatLeaveType(dayData.leave_type)
                  : isWeekend
                  ? "Week off"
                  : ""}
              </p>
              <p className="time">
                {dayData &&
                (dayData?.punch_in_time !== "NA" ||
                  dayData?.punch_in_out !== "NA")
                  ? `${dayData.punch_in_time} - ${dayData.punch_in_out}`
                  : ""}
              </p>
            </td>
          );
          day++;
        }
      }
      calendar.push(<tr key={i}>{week}</tr>);
      if (day > daysInMonth) break;
    }
    return calendar;
  };

  const getDayOfWeek = (day, month, year) => {
    const date = new Date(year, month, day);
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    return days[date.getDay()];
  };

  const getMonthName = (month) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return monthNames[month];
  };
  return (
    <div className="custom_calendar">
      {isLoading && (
        <div className="overlay">
          <div className="mt-5">
            <TailSpin
              height="100"
              width="100"
              radius="1"
              color="blue"
              ariaLabel="tail-spin-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}
      <div className="calendar_nav">
        <span onClick={goToPreviousMonth}>&lt;</span>
        <h2 className="heading">
          {currentDate.toLocaleString("default", {
            month: "long",
            year: "numeric",
          })}
        </h2>
        <span
          onClick={
            currentDate.getMonth() >= new Date().getMonth() &&
            currentDate.getFullYear() >= new Date().getFullYear()
              ? null
              : goToNextMonth
          }
          className={
            currentDate.getMonth() >= new Date().getMonth() &&
            currentDate.getFullYear() >= new Date().getFullYear()
              ? "disabled-span"
              : ""
          }
        >
          &gt;
        </span>
      </div>
      <table>
        <thead>
          <tr>
            <th>Sun</th>
            <th>Mon</th>
            <th>Tue</th>
            <th>Wed</th>
            <th>Thu</th>
            <th>Fri</th>
            <th>Sat</th>
          </tr>
        </thead>
        <tbody>{isLoading === false && generateCalendar()}</tbody>
      </table>

      <div
        className="modal fade"
        id="staticBackdropView"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropView"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title text-center flex"
                id="staticBackdropLabel"
              >
                Attendance Detail
              </h5>

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body mb-3">
              <div className="row">
                <div className="col-md-1"></div>
                <div className="col-md-2">
                  <label className="label-h">Date</label>
                </div>
                <div className="col-md-2">{selectedAttendance?.date}</div>
                <div className="col-md-1"></div>
                <div className="col-md-2">
                  <label className="label-h">Type</label>
                </div>
                <div className="col-md-2">
                  {formatLeaveType(selectedAttendance?.leave_type)}
                </div>
              </div>
              {selectedAttendance?.leave_type === "Present" && (
                <>
                  <div className="row mt-3">
                    <div className="col-md-1"></div>
                    <div className="col-md-2">
                      <label className="label-h">Punch In</label>
                    </div>
                    <div className="col-md-2">
                      {selectedAttendance?.punch_in_time}
                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-md-2">
                      <label className="label-h">Punch Out</label>
                    </div>
                    <div className="col-md-2">
                      {selectedAttendance?.punch_in_out}
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-1"></div>
                    <div className="col-md-2">
                      <label className="label-h">Total Hours</label>
                    </div>
                    <div className="col-md-2">
                      {selectedAttendance?.total_hour}
                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-md-2">
                      <label className="label-h">Shift </label>
                    </div>
                    <div className="col-md-3">
                      {selectedAttendance?.is_positive_hour ? (
                        <span className="badge bg-success">Completed</span>
                      ) : (
                        <span className="badge bg-danger">Not-Completed</span>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="justify-content-center modal-footer">
              <button
                type="button"
                className="theme-button  mx-3 w-35 cancel-modal-dependent-delete-attach"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyAttendanceCalendar;
