import React from "react";
import Chart from "./chart";
// import "../../Styles/components/organization.scss";

function IndexChart() {
  return (
    <div className="App">
      <div className="container">
        <Chart />
      </div>
    </div>
  );
}

export default IndexChart;
