import React from "react";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const handlePrevPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <div>
      {/* <button onClick={handlePrevPage} disabled={currentPage === 1}>
        Previous
      </button>
      <span>{`Page ${currentPage} of ${totalPages}`}</span>
      <button onClick={handleNextPage} disabled={currentPage === totalPages}>
        Next
      </button> */}

      <ul className="pagination justify-content-end mt-3">
        <li
          className={currentPage === 1 ? "page-item disabled" : "page-item "}
          onClick={handlePrevPage}
        >
          <a className="page-link" href="#" tabIndex="-1">
            Previous
          </a>
        </li>
        <li className="page-item ">
          <a className="page-link text-dark disabled" href="javascript:void(0)">
            {`Page ${currentPage} of ${totalPages}`}
          </a>
        </li>
        <li
          className={
            currentPage === totalPages ? "page-item disabled" : "page-item "
          }
          onClick={handleNextPage}
        >
          <a className="page-link" href="#">
            Next
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Pagination;
