import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { ThreeDots } from "react-loader-spinner";
import { toggleMenu } from "../../utils/helpers";
import Footer from "../../components/Footer";

function ProjectList() {
  const [projectList, setProjectList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const accessToken = Cookies.get("accessToken");
  const navigate = useNavigate();

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);
    const filtered = projectList.filter((item) =>
      item.projectName.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredItems(filtered);
  };

  return (
    <div className="wrapper-body">
      {/* Page Heading */}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <h1>Project List</h1>
        <div className="total">
          Total Project : <span>{filteredItems?.length}</span>
        </div>
      </div>

      {/* Filters */}
      <div className="filters">
        {/* <form action="index.html" id="form_filters" className="d-f"> */}
        {/* Search By project name */}
        <div className="search">
          <input
            type="text"
            name="search"
            placeholder="Search By Project Name."
            value={searchTerm}
            onChange={handleSearch}
          />
          <img src="/assets/images/search-ico.svg" width="14" alt="Search" />
        </div>
        {/* </form> */}
        <Link className="btn btn-primary" to="/dashboard/createProject">
          + New Project
        </Link>
        {/* Actions */}
        <div className="actions"></div>
      </div>
      {isLoading && (
        <div className="centered-loader">
          <ThreeDots
            height="100"
            width="100"
            radius="9"
            color="blue"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}
      <div className="row emp-card">
        {!isLoading &&
          filteredItems?.length > 0 &&
          filteredItems.map((project, index) => (
            <div className="col-md-2" key={index}></div>
          ))}
        {!isLoading && filteredItems?.length === 0 && (
          <div className="fs-4 text-secondary text-center">
            No Record Available
          </div>
        )}
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default ProjectList;
