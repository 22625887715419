import Cookies from "js-cookie";

export function getAuthToken(key) {
  return Cookies.get(key);
}

export function getUser(key) {
  return localStorage.getItem(key);
}

export function logoutUser() {
  Cookies.remove("accessToken");
  localStorage.clear();
  window.location.replace("/");
}
