import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getApi, postApi } from "../../utils/api";
import {
  ALLOCATE_ASSET,
  ASSET_LIST,
  ASSET_TYPE_LIST,
  EMPLOYEE_LIST,
} from "../../config/Endpoints";
import { TailSpin, ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import Select, { components } from "react-select";
import { toggleMenu } from "../../utils/helpers";
import Footer from "../../components/Footer";

const AssignAsset = () => {
  const [assetList, setAssetList] = useState([]);
  const [assetTypeList, setAssetTypeList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [error, setError] = useState("");
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAssetLoading, setIsAssetLoading] = useState(false);
  const [isListLoading, setIsListLoading] = useState(false);

  const [selectedAssetId, setSelectedAssetId] = useState();
  const [selectedAssetDataText, setSelectedAssetDataText] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [selectedAsset, setSelectedAsset] = useState("");

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredItems, setFilteredItems] = useState();

  const accessToken = Cookies.get("accessToken");
  const [formData, setFormData] = useState({
    selectedAsset: "",
    serialNumber: "",
    comment: "",
    selectedEmployee: "",
  });

  const optionList = employeeList?.map((item) => ({
    value: item.id,
    label: item.empName,
    type: item.employeeType,
  }));

  const Option = (props) => {
    const { data } = props;
    const { label, type } = data;

    return (
      <components.Option {...props}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>{label}</span>
          <span style={{ fontSize: "12px", color: "gray" }}>({type})</span>
        </div>
      </components.Option>
    );
  };

  const optionAssetList = assetTypeList?.map((item) => ({
    value: item.assestId,
    label: item.name,
  }));

  let isAssetSelect = false;

  const navigate = useNavigate();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleInputChangeDropdown = (selectedOption) => {
    setFormData({
      ...formData,
      selectedOption,
    });
  };

  const fetchEmployeeList = async () => {
    await getApi(EMPLOYEE_LIST, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setEmployeeList(res.data.data);
          setIsListLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsListLoading(false);
      });
  };

  const fetchAssetTypeList = async () => {
    await getApi(ASSET_TYPE_LIST, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setAssetTypeList(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsListLoading(false);
      });
  };

  const handleChangeAssetType = async (e) => {
    setIsAssetLoading(true);
    console.log(e);
    const selectedValue = e.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      selectedAsset: selectedValue,
    }));
    setSelectedAsset(e);

    if (e.value === "") {
      setSelectedAssetDataText("");
    }
    // Get asset list regardless of whether the asset type is the same or different
    await getApi(ASSET_LIST + `?assestTypeId=${selectedValue}`, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setAssetList(res.data.data);
          setFilteredItems(res.data.data);
          const modalClose = document.getElementsByClassName("open-modal")[0];
          modalClose.click();
          setSelectedAssetDataText("");
          setSelectedAssetId("");
          setIsAssetLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsAssetLoading(false);
      });
  };

  useEffect(() => {
    setIsListLoading(true);
    fetchAssetTypeList();
    fetchEmployeeList();
  }, []);

  useEffect(() => {}, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      let requestData = {
        assestId: selectedAssetId,
        comment: formData.comment,
        employeeId: Number(formData.selectedEmployee),
      };
      await postApi(ALLOCATE_ASSET, requestData, accessToken)
        .then((res) => {
          if (res.status === 200) {
            setIsLoading(false);
            setFormData({
              selectedAsset: "",
              serialNumber: "",
              comment: "",
              selectedEmployee: "",
            });
            setSelectedAssetId("");
            setSelectedAssetDataText("");
            setSelectedEmployee({ value: "", label: "" });
            navigate("/dashboard/assetAllocation");
            toast.success("Successfully assigned!", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            setIsError(true);
            setIsLoading(false);
            setError("Something went wrong. Please try again!");
            setTimeout(() => {
              setIsError(false);
            }, 4000);
          }
        })
        .catch((e) => {
          setIsError(true);
          setIsLoading(false);
          setError("Something went wrong. Please try again!");
          setTimeout(() => {
            setIsError(false);
          }, 4000);
        });
    } catch (error) {
      console.log(error);
      setIsError(true);
      setIsLoading(false);
      setError("Something went wrong. Please try again!");
      setTimeout(() => {
        setIsError(false);
      }, 4000);
    }
  };

  const handleChangeAsset = (e) => {
    const assetId = Number(e.target.value);
    setSelectedAssetId(assetId);
    isAssetSelect = true;
    const selectedAsset = filteredItems.find((asset) => asset.id === assetId);
    if (selectedAsset) {
      let assetInfo = "";
      for (const key in selectedAsset) {
        if (selectedAsset.hasOwnProperty(key)) {
          assetInfo += `${key === "assestName" ? "assetName" : key}: ${
            selectedAsset[key]
          }\n`;
        }
      }
      setSelectedAssetDataText(assetInfo);
      const modalClose = document.getElementsByClassName("close-class")[0];
      modalClose.click();
    } else {
      setSelectedAssetDataText("");
    }
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);
    const filtered = assetList.filter((item) =>
      item.serialNumber.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredItems(filtered);
  };
  const handleClose = () => {
    setSearchTerm("");
    if (!isAssetSelect || filteredItems?.length === 0) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        selectedAsset: "",
      }));
      setSelectedAsset("");
    } else {
      isAssetSelect = false;
    }
  };

  return (
    <div className="wrapper-body">
      {(isAssetLoading || isListLoading) && (
        <div className="overlay">
          <div className="mt-5">
            <TailSpin
              height="100"
              width="100"
              radius="1"
              color="blue"
              ariaLabel="tail-spin-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}

      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <h1>Assign Asset</h1>
        <div className="total">
          <Link to="/dashboard/assetAllocation" className="brand-color">
            Back to Assigned Asset List
          </Link>
        </div>
      </div>

      <form id="assign-asset" onSubmit={handleSubmit}>
        <div className="card">
          <h3 className="heading">Asset Management</h3>
          <div className="d-f f-w inputs gap-15">
            <div className="input-group">
              <label htmlFor="asset_list" className="has-asterisk">
                Select an asset type
              </label>
              {/* <select
                id="asset_list"
                value={formData.selectedAsset}
                required
                onChange={handleChangeAssetType}
                name="selectedAsset"
                // onClick={(e) => {
                //   if (formData.selectedAsset === e.target.value) {
                //     // If it is the same, clear the selection
                //     setFormData((prevFormData) => ({
                //       ...prevFormData,
                //       selectedAsset: "",
                //     }));
                //   }
                // }}
              >
                <option value="">Choose</option>
                {assetTypeList &&
                  assetTypeList.map((item) => (
                    <option value={item.assestId} key={item.assestId}>
                      {item.name}
                    </option>
                  ))}
              </select> */}
              <Select
                id="asset_list"
                name="selectedAsset"
                options={optionAssetList}
                onChange={handleChangeAssetType}
                value={selectedAsset}
                placeholder="Choose"
                isSearchable={true}
                required
              />
            </div>
            <div className="input-group">
              <label className="has-asterisk">Select Employee</label>
              <div className="dropdown-container">
                <Select
                  options={optionList}
                  components={{ Option }}
                  onChange={(selectedOption) => {
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      selectedEmployee: selectedOption.value,
                    }));
                    setSelectedEmployee(selectedOption);
                  }}
                  value={selectedEmployee}
                  placeholder="Choose"
                  isSearchable={true}
                  required
                />
              </div>
            </div>
          </div>
          <div className="d-f f-w inputs gap-15">
            {selectedAssetDataText !== "" && (
              <div className="input-group ">
                <label>Selected Asset</label>
                <textarea
                  value={selectedAssetDataText}
                  rows={10}
                  cols={30}
                  readOnly
                />
              </div>
            )}
            <div className="input-group">
              <label htmlFor="comment" className="has-asterisk">
                Comment
              </label>
              <textarea
                id="comment"
                name="comment"
                required
                value={formData.comment}
                onChange={handleInputChange}
                rows={selectedAssetDataText !== "" ? 10 : 2}
                cols={30}
              ></textarea>
            </div>
          </div>
          <div className="text-center">
            {isError && <div className="error mb-3 ">{error}</div>}
          </div>
          <div className="action text-center">
            <button
              className={isLoading ? "theme-button disabled " : "theme-button "}
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? (
                <ThreeDots
                  height="25"
                  width="80"
                  radius="9"
                  color="white"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              ) : (
                "Submit"
              )}
            </button>

            <a
              data-bs-toggle="modal"
              data-bs-target="#staticBackdropAsset"
              className="open-modal visually-hidden"
            >
              click
            </a>
          </div>
        </div>
      </form>

      <div
        className="modal fade"
        id="staticBackdropAsset"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title text-center flex"
                id="staticBackdropLabel"
              >
                Assign Asset
              </h5>
              <button
                type="button"
                className="btn-close close-class"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              ></button>
            </div>
            <div className="table-responsive">
              <div className="filters">
                {/* <form action="index.html" id="form_filters" className="d-f"> */}
                {/* Search By Company */}
                {assetList?.length !== 0 && (
                  <div className="search">
                    <input
                      type="text"
                      name="search"
                      placeholder="Search By Serial Number"
                      value={searchTerm}
                      className="form-input border border-2"
                      onChange={handleSearch}
                    />
                    <img
                      src="/assets/images/search-ico.svg"
                      width="14"
                      alt="Search"
                    />
                  </div>
                )}

                {/* </form> */}
              </div>
              {filteredItems?.length > 0 && (
                <table className="table align-middle">
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Manufacture</th>
                      <th>Asset Name</th>
                      <th>Serial Number</th>
                      <th>Owner</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredItems.map((asset, index) => (
                      <tr key={index}>
                        <td>{asset.id}</td>
                        <td>{asset.manufacture}</td>
                        <td>{asset.assestName}</td>
                        <td>{asset.serialNumber}</td>
                        <td>{asset.owner}</td>
                        <td>
                          <div className="radio-group">
                            <input
                              type="radio"
                              id={asset.id}
                              name="selectAsset"
                              value={asset.id}
                              checked={selectedAssetId === asset.id}
                              onChange={handleChangeAsset}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              {filteredItems?.length === 0 && (
                <div className="text-center">
                  <h4>Assets are not available for selected type !</h4>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default AssignAsset;

// const validationSchema = Yup.object().shape({
//   email: Yup.string().required("Email is required"),
//   password: Yup.string().required("Password is required"),
// });
