import React, { useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { API_CHANGE_PASSWORD } from "../../config/Endpoints";
import { toast } from "react-toastify";
import { postApi } from "../../utils/api";
import Cookies from "js-cookie";
import * as Yup from "yup";
import { logoutUser } from "../../utils/Storage";

const ChangePassword = () => {
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const accessToken = Cookies.get("accessToken");
  const [errors, setErrors] = useState({});

  const handleReset = () => {
    setFormData({
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
    setErrors({});
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setErrors((prevFormData) => ({
      ...prevFormData,
      [name]: "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await schema.validate(
        {
          newPassword: formData.newPassword,
          confirmPassword: formData.confirmPassword,
        },
        { abortEarly: false }
      );
      if (formData.newPassword === formData.confirmPassword) {
        let requestData = {
          newPassword: formData.newPassword,
          oldPassword: formData.currentPassword,
        };
        await postApi(API_CHANGE_PASSWORD, requestData, accessToken)
          .then((res) => {
            if (res.status === 200) {
              setIsLoading(false);
              toast.success("Password Successfully updated!", {
                position: toast.POSITION.TOP_RIGHT,
              });
              const modalClose = document.getElementsByClassName(
                "cancel-modal-password"
              )[0];
              modalClose.click();
              logoutUser();
            } else {
              setIsLoading(false);
            }
          })
          .catch((e) => {
            toast.error(e?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setIsLoading(false);
          });
      } else {
        toast.error("New password and confirm password does not match.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsLoading(false);
      }
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });
      setErrors(validationErrors);
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="modal-header">
        <h5 className="modal-title text-center flex" id="staticBackdropLabel">
          Change Password
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleReset}
        ></button>
      </div>
      <div className="modal-body">
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <label htmlFor="currentPassword" className="has-asterisk">
              Current Password
            </label>
          </div>

          <div className="col-md-4">
            <h6>
              <div className="input-group">
                <input
                  type="password"
                  className="border"
                  name="currentPassword"
                  value={formData.currentPassword}
                  onChange={handleInputChange}
                  placeholder="Enter Current Password"
                  required
                />
              </div>
            </h6>
          </div>
          <div className="col-md-2"></div>
        </div>
        <div className="row mt-3">
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <label htmlFor="newPassword" className="has-asterisk">
              New Password
            </label>
          </div>
          <div className="col-md-4 ">
            <input
              type="password"
              name="newPassword"
              className="border"
              value={formData.newPassword}
              onChange={handleInputChange}
              placeholder="Enter New Password"
              required
            />
            {errors.newPassword && (
              <small className="text-danger">{errors.newPassword}</small>
            )}
          </div>
          <div className="col-md-2"></div>
        </div>
        <div className="row mt-3">
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <label htmlFor="confirmPassword" className="has-asterisk">
              Confirm Password
            </label>
          </div>
          <div className="col-md-4 ">
            <input
              type="password"
              name="confirmPassword"
              placeholder="Enter Confirm Password"
              required
              className="border"
              value={formData.confirmPassword}
              onChange={handleInputChange}
            ></input>
            {errors.confirmPassword && (
              <small className="text-danger">{errors.confirmPassword}</small>
            )}
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>

      <div className="justify-content-center modal-footer">
        <button
          type="submit"
          className={isLoading ? "theme-button disabled " : "theme-button "}
        >
          {isLoading ? (
            <ThreeDots
              height="20"
              width="53"
              radius="9"
              color="white"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          ) : (
            "Submit"
          )}
        </button>
        <button
          type="button"
          className="theme-button bg-grey mx-3 w-35 cancel-modal-password"
          data-bs-dismiss="modal"
          onClick={handleReset}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default ChangePassword;

const schema = Yup.object().shape({
  newPassword: Yup.string()
    .required("New Password is required")
    .min(6, "Password must be at least 6 characters long"),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("newPassword")], "Passwords must match"),
});
