import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import moment from "moment";
import { getApi, postApi } from "../../../utils/api";
import {
  API_USER_UPDATE_EMPLOYEE,
  USER_PROFILE,
} from "../../../config/Endpoints";
import { toggleMenu } from "../../../utils/helpers";
import { ThreeDots } from "react-loader-spinner";
import EmployeeAsset from "../EmployeeAsset";
import Footer from "../../../components/Footer";
import BiometricList from "./BiometricList";

const BiometricEmployeeProfile = () => {
  const { state } = useLocation();
  const { data } = state || {};
  const [userData, setUserData] = useState(data ? data : {});
  const accessToken = Cookies.get("accessToken");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (data) {
      fetchUser();
    }
  }, [data]);

  const fetchUser = () => {
    setIsLoading(true);
    getApi(USER_PROFILE + "?employeeId=" + data, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setUserData(res.data.data);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <div className="wrapper-body">
        <div className="page-heading">
          <div
            id="showMenuBtn"
            className="collapse-button"
            onClick={toggleMenu}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        {isLoading && (
          <div className="centered-loader">
            <ThreeDots
              height="100"
              width="100"
              radius="9"
              color="blue"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        )}

        {!isLoading && (
          <div className="card">
            <div className="user-type-tag">
              <span
                className={`badge bg-${
                  userData?.personalDetail?.employeeType === "REGULAR"
                    ? "success"
                    : "primary"
                }`}
              >
                {userData?.personalDetail?.employeeType}
              </span>
            </div>
            <div className="row">
              <div className="col-md-2 border-right">
                <div className="d-flex flex-column align-items-center text-center p-3 py-2">
                  <img
                    className="employee-profile"
                    width="150px"
                    src={`${
                      !userData?.personalDetail?.photoPath.includes("photoDir")
                        ? "../assets/profile.png"
                        : process.env.REACT_APP_IMAGE_URL +
                          userData?.personalDetail?.photoPath
                    }`}
                  />
                </div>
              </div>
              <div className="col-md-7">
                <div className="d-flex flex-column  p-1 py-3">
                  <h4>
                    <b>{userData?.personalDetail?.empName}</b>
                  </h4>
                  <span className="mt-1">
                    - {userData?.personalDetail?.designation}
                  </span>
                  {userData?.personalDetail?.ccEmail && (
                    <span className="text-black font-small mt-1">
                      <a href="mailto:manjeet.saini@caeliusconsulting.com">
                        {userData?.personalDetail?.ccEmail}
                      </a>
                    </span>
                  )}
                  {userData?.personalDetail?.phoneNumber && (
                    <span className="text-black font-small ">
                      <a href="tel:123-456-7890">
                        <i className="fa fa-mobile" aria-hidden="true"></i>
                        <span className="ms-2">
                          {userData?.personalDetail?.phoneNumber}
                        </span>
                      </a>
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-2"></div>
              <div className="col-md-1"></div>
            </div>
            <div className="row mt-5">
              <div className="container">
                <ul className="nav nav-tabs" id="myTabs" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="tab1-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#tab1"
                      type="button"
                      role="tab"
                      aria-controls="tab1"
                      aria-selected="true"
                    >
                      General Info
                    </button>
                  </li>

                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="tab4-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#tab4"
                      type="button"
                      role="tab"
                      aria-controls="tab4"
                      aria-selected="false"
                    >
                      Asset
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="tab11-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#tab11"
                      type="button"
                      role="tab"
                      aria-controls="tab11"
                      aria-selected="false"
                    >
                      Biometric
                    </button>
                  </li>
                </ul>

                <div className="tab-content" id="myTabsContent">
                  <div
                    className="tab-pane fade show active mt-3"
                    id="tab1"
                    role="tabpanel"
                    aria-labelledby="tab1-tab"
                  >
                    <div className="accordion" id="accordion1">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="heading1">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse1"
                            aria-expanded="false"
                            aria-controls="collapse1"
                          >
                            Basic info
                          </button>
                        </h2>
                        <div
                          id="collapse1"
                          className="accordion-collapse collapse"
                          aria-labelledby="heading1"
                          data-bs-parent="#accordion1"
                        >
                          <div className="accordion-body">
                            <div className="row">
                              <div className="col-md-3">
                                <div className="d-flex flex-column  text-black-50">
                                  <p>First Name</p>
                                  <p>Last Name</p>
                                  <p>Employee Id</p>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="d-flex flex-column">
                                  <p>{userData?.personalDetail?.firstName}</p>
                                  <p>{userData?.personalDetail?.lastName}</p>
                                  <p>{userData?.personalDetail?.employeeId}</p>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="d-flex flex-column  text-black-50">
                                  <p>Middle Name</p>
                                  <p>Email</p>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="d-flex flex-column">
                                  <p>
                                    {userData?.personalDetail?.middleName
                                      ? userData?.personalDetail?.middleName
                                      : "-"}
                                  </p>

                                  <p>{userData?.personalDetail?.ccEmail}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="heading2">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse2"
                            aria-expanded="false"
                            aria-controls="collapse2"
                          >
                            Work
                          </button>
                        </h2>
                        <div
                          id="collapse2"
                          className="accordion-collapse collapse"
                          aria-labelledby="heading2"
                          data-bs-parent="#accordion1"
                        >
                          <div className="accordion-body">
                            <div className="row">
                              <div className="col-md-3">
                                <div className="d-flex flex-column  text-black-50">
                                  <p>Department</p>
                                  <p>Designation</p>
                                  <p>Reporting To</p>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="d-flex flex-column">
                                  <p>
                                    {userData?.personalDetail?.department
                                      ? userData?.personalDetail?.department
                                      : "-"}
                                  </p>
                                  <p>
                                    {userData?.personalDetail?.designation
                                      ? userData?.personalDetail?.designation
                                      : "-"}
                                  </p>
                                  <p>
                                    {userData?.personalDetail?.managerName
                                      ? userData?.personalDetail?.managerName
                                      : "-"}
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="d-flex flex-column  text-black-50">
                                  <p>Date of Joining</p>
                                  <p>Employee Status</p>
                                  <p>Employee Type</p>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="d-flex flex-column">
                                  <p>
                                    {userData?.personalDetail?.doj
                                      ? userData?.personalDetail?.doj
                                      : "-"}
                                  </p>
                                  <p>
                                    {userData?.personalDetail?.status
                                      ? userData?.personalDetail?.status
                                      : "-"}
                                  </p>
                                  <p>Full Time</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="heading3">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse3"
                            aria-expanded="false"
                            aria-controls="collapse3"
                          >
                            Personal Details
                          </button>
                        </h2>
                        <div
                          id="collapse3"
                          className="accordion-collapse collapse"
                          aria-labelledby="heading3"
                          data-bs-parent="#accordion1"
                        >
                          <div className="accordion-body">
                            <div className="row">
                              <div className="col-md-3">
                                <div className="d-flex flex-column  text-black-50">
                                  <p>Date of Birth</p>
                                  <p>Aadhar Card Number</p>
                                  <p>Passport Number</p>
                                  <p>PAN Number</p>
                                  <p>Present Address</p>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="d-flex flex-column">
                                  <p>
                                    {userData?.personalDetail?.dob
                                      ? userData?.personalDetail?.dob
                                      : "-"}
                                  </p>
                                  <p>
                                    {userData?.personalDetail?.aadharNum
                                      ? userData?.personalDetail?.aadharNum
                                      : "-"}
                                  </p>
                                  <p>
                                    {userData?.personalDetail?.passportNum
                                      ? userData?.personalDetail?.passportNum
                                      : "-"}
                                  </p>
                                  <p>
                                    {userData?.personalDetail?.panNum
                                      ? userData?.personalDetail?.panNum
                                      : "-"}
                                  </p>
                                  <p>
                                    {userData?.personalDetail?.presentAddress
                                      ? userData?.personalDetail?.presentAddress
                                      : "-"}
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="d-flex flex-column  text-black-50">
                                  <p>Blood Group</p>
                                  <p>Gender</p>
                                  <p>Marital Status</p>
                                  <p>Alternate Number</p>
                                  <p>Permanent Address</p>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="d-flex flex-column">
                                  <p>
                                    {userData?.personalDetail?.bloodGroup
                                      ? userData?.personalDetail?.bloodGroup
                                      : "-"}
                                  </p>
                                  <p>
                                    {userData?.personalDetail?.gender
                                      ? userData?.personalDetail?.gender
                                      : "-"}
                                  </p>
                                  <p>
                                    {userData?.personalDetail?.maritalStatus
                                      ? userData?.personalDetail?.maritalStatus
                                      : "-"}
                                  </p>
                                  <p>
                                    {userData?.personalDetail?.alternateNumber
                                      ? userData?.personalDetail
                                          ?.alternateNumber
                                      : "-"}
                                  </p>
                                  <p>
                                    {userData?.personalDetail?.permanentAddress
                                      ? userData?.personalDetail
                                          ?.permanentAddress
                                      : "-"}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="heading4">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse4"
                            aria-expanded="false"
                            aria-controls="collapse4"
                          >
                            Work Experience
                          </button>
                        </h2>
                        <div
                          id="collapse4"
                          className="accordion-collapse collapse"
                          aria-labelledby="heading4"
                          data-bs-parent="#accordion1"
                        >
                          <div className="accordion-body">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="table-responsive">
                                  {userData?.experience?.length === 0 &&
                                    userData?.experience && (
                                      <div className="fs-4 text-secondary text-center">
                                        No records available
                                      </div>
                                    )}
                                  {userData?.experience?.length !== 0 && (
                                    <table className="resume custom">
                                      {/* Table Headings */}
                                      <thead>
                                        <tr>
                                          <th>Job Title</th>
                                          <th>Previous Company</th>
                                          <th>From</th>
                                          <th>To</th>
                                          <th>Description</th>
                                        </tr>
                                      </thead>

                                      {/* Table Body */}
                                      <tbody>
                                        {userData?.experience &&
                                          userData?.experience?.map((item) => (
                                            <tr>
                                              <td>{item.jobTitle}</td>
                                              <td>{item.previousCompany}</td>
                                              <td>{item.fromDate}</td>
                                              <td>{item.toDate}</td>
                                              <td>
                                                {item.jobDescription &&
                                                item.jobDescription.trim() !==
                                                  ""
                                                  ? item.jobDescription
                                                  : "-"}
                                              </td>
                                            </tr>
                                          ))}
                                      </tbody>
                                    </table>
                                  )}
                                </div>
                                {/* <WorkExperience workData={userData?.experience} /> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="heading5">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse5"
                            aria-expanded="false"
                            aria-controls="collapse5"
                          >
                            Education
                          </button>
                        </h2>
                        <div
                          id="collapse5"
                          className="accordion-collapse collapse"
                          aria-labelledby="heading5"
                          data-bs-parent="#accordion1"
                        >
                          <div className="accordion-body">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="table-responsive">
                                  {userData?.education?.length === 0 &&
                                    userData?.education && (
                                      <div className="fs-4 text-secondary text-center">
                                        No records available
                                      </div>
                                    )}
                                  {userData?.education?.length !== 0 && (
                                    <table className="resume custom">
                                      {/* Table Headings */}
                                      <thead>
                                        <tr>
                                          <th>Highest Degree</th>
                                          <th>College</th>
                                          <th>From</th>
                                          <th>To</th>
                                        </tr>
                                      </thead>

                                      {/* Table Body */}
                                      <tbody>
                                        {userData?.education &&
                                          userData?.education?.map((item) => (
                                            <tr>
                                              <td>{item.highestDegree}</td>
                                              <td>{item.college}</td>
                                              <td>{item.fromDate}</td>
                                              <td>{item.toDate}</td>
                                            </tr>
                                          ))}
                                      </tbody>
                                    </table>
                                  )}
                                </div>
                                {/* <Education educationData={userData?.education} /> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="heading6">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse6"
                            aria-expanded="false"
                            aria-controls="collapse6"
                          >
                            Dependents
                          </button>
                        </h2>
                        <div
                          id="collapse6"
                          className="accordion-collapse collapse"
                          aria-labelledby="heading6"
                          data-bs-parent="#accordion1"
                        >
                          <div className="accordion-body">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="table-responsive">
                                  {userData?.dependents?.length === 0 &&
                                    userData?.dependents && (
                                      <div className="fs-4 text-secondary text-center">
                                        No records available
                                      </div>
                                    )}
                                  {userData?.dependents?.length !== 0 && (
                                    <table className="resume custom">
                                      {/* Table Headings */}
                                      <thead>
                                        <tr>
                                          <th>Name</th>
                                          <th>Gender</th>
                                          <th>DOB</th>
                                          <th>Relation</th>
                                          <th>Mobile Number</th>
                                        </tr>
                                      </thead>

                                      {/* Table Body */}
                                      <tbody>
                                        {userData?.dependents &&
                                          userData?.dependents?.map((item) => (
                                            <tr>
                                              <td>{item.name}</td>
                                              <td>{item.gender}</td>
                                              <td>{item.dob}</td>
                                              <td>{item.relationship}</td>
                                              <td>{item.number}</td>
                                            </tr>
                                          ))}
                                      </tbody>
                                    </table>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="tab4"
                    role="tabpanel"
                    aria-labelledby="tab4-tab"
                  >
                    <EmployeeAsset
                      assestData={userData?.assestDetail}
                      employeeId={data}
                    />
                  </div>

                  <div
                    className="tab-pane fade"
                    id="tab11"
                    role="tabpanel"
                    aria-labelledby="tab11-tab"
                  >
                    <BiometricList employeeId={data} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <Footer />
      </div>
    </>
  );
};

export default BiometricEmployeeProfile;
