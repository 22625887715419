import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { getApi } from "../../utils/api";
import { API_POLICY } from "../../config/Endpoints";
import { toggleMenu } from "../../utils/helpers";
import { Link } from "react-router-dom";
import PolicyModal from "./PolicyModal";
import Pagination from "../../components/Pagination";
import moment from "moment";
import Footer from "../../components/Footer";

const ModifyPolicy = () => {
  const [policyList, setPolicyList] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredItems, setFilteredItems] = useState();
  const [selectedPolicy, setSelectedPolicy] = useState({});

  const accessToken = Cookies.get("accessToken");
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPolicy, setTotalPolicy] = useState(0);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "",
  });
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    fetchList();
  }, [currentPage, limit]);

  const fetchList = () => {
    setisLoading(true);
    getApi(API_POLICY + `?pageSize=${limit}&page=${currentPage}`, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setPolicyList(res.data.data.data);
          setFilteredItems(res.data.data.data);
          setTotalPolicy(res.data.data.totalPolicy);
          setTotalPages(res.data.data.totalPages);
          setisLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setisLoading(false);
      });
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);
    const filtered = policyList.filter((item) =>
      item.title.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredItems(filtered);
  };

  const handleViewDetails = (data) => {
    setSelectedPolicy(data);
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
    const sortedData = [...filteredItems].sort((a, b) => {
      if (key === "validFrom") {
        const dateA = new Date(a[key]);
        const dateB = new Date(b[key]);
        if (dateA < dateB) {
          return direction === "ascending" ? -1 : 1;
        }
        if (dateA > dateB) {
          return direction === "ascending" ? 1 : -1;
        }
        return 0;
      } else {
        if (a[key] < b[key]) {
          return direction === "ascending" ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return direction === "ascending" ? 1 : -1;
        }
        return 0;
      }
    });
    setFilteredItems(sortedData);
  };

  return (
    <div className="wrapper-body">
      {/* Page Heading */}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <h1>Modify Policies</h1>
        <div className="total">
          Total Policy : <span>{totalPolicy}</span>
        </div>
      </div>

      {/* Filters */}
      <div className="filters">
        {/* <form action="index.html" id="form_filters" className="d-f"> */}
        {/* Search By Company */}
        <div className="search">
          <input
            type="text"
            name="search"
            placeholder="Search By Policy Title"
            value={searchTerm}
            onChange={handleSearch}
          />
          <img src="/assets/images/search-ico.svg" width="14" alt="Search" />
        </div>
        {/* <button type="submit" form="form_filters"> 
             Search
             </button> */}
        {/* </form> */}

        {/* Actions */}
        <div className="actions">
          <Link
            className="export theme-button"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdropPolicy"
          >
            + Add Policy
          </Link>
        </div>
      </div>

      {/* Data Table */}
      {isLoading && (
        <div className="centered-loader">
          <ThreeDots
            height="100"
            width="100"
            radius="9"
            color="blue"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}
      {!isLoading && filteredItems?.length === 0 && (
        <div className="fs-4 text-secondary text-center">
          No Record Available
        </div>
      )}
      {!isLoading && filteredItems?.length > 0 && (
        <>
          <div className="table-responsive">
            <table className="resume custom">
              {/* Table Headings */}
              <thead>
                <tr>
                  {/* <th>Serial No</th> */}
                  <th onClick={() => handleSort("policyViewId")}>
                    Policy Id{" "}
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                  <th onClick={() => handleSort("title")}>
                    Title{" "}
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                  <th onClick={() => handleSort("validFrom")}>
                    Valid From{" "}
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                  <th onClick={() => handleSort("description")}>
                    Description{" "}
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                  <th>Document</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>

              {/* Table Body */}
              <tbody>
                {filteredItems &&
                  filteredItems.map((item, index) => (
                    <tr kwy={index}>
                      <td data-title="Name">{item.policyViewId}</td>
                      <td data-title="Email">{item.title}</td>
                      <td data-title="validFrom">
                        {moment(item.validFrom).format("DD-MM-yyyy")}
                      </td>
                      <td data-title="description">{item.description}</td>
                      <td>
                        <a
                          href={
                            process.env.REACT_APP_IMAGE_URL + item.documentPath
                          }
                          download
                          target="_blank"
                        >
                          View
                        </a>
                      </td>
                      <td className="text-center">
                        <button
                          className="export theme-button"
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdropPolicy"
                          onClick={() => handleViewDetails(item)}
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div>
                {/* Dropdown for selecting items per page */}
                <label htmlFor="itemsPerPage">Items per page </label>
                <select
                  id="itemsPerPage"
                  onChange={handleLimitChange}
                  value={limit}
                  className="border ms-3 w-25  p-2"
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={30}>30</option>
                  <option value={40}>40</option>
                </select>
              </div>
            </div>
            <div className="col-md-9">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </>
      )}

      <div
        className="modal fade"
        id="staticBackdropPolicy"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <PolicyModal
              selectedPolicy={selectedPolicy}
              setSelectedPolicy={setSelectedPolicy}
              fetchList={fetchList}
            />
          </div>
        </div>
      </div>
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default ModifyPolicy;
