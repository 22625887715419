import { TailSpin, ThreeDots } from "react-loader-spinner";
import Footer from "../../components/Footer";
import Pagination from "../../components/Pagination";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { formatLeaveType, toggleMenu } from "../../utils/helpers";
import { getApi } from "../../utils/api";
import { API_LEAVE_TODAY, GET_EMPLOYEE_LIST } from "../../config/Endpoints";
import Select, { components } from "react-select";
import TruncateText from "../../components/TruncateText";

const OnLeaveToday = () => {
  const [leaveList, setLeaveList] = useState([]);
  const [searchDate, setSearchDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [isLoading, setIsLoading] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);
  const accessToken = Cookies.get("accessToken");
  const [employeeList, setEmployeeList] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState("");

  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "",
  });

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
    const sortedData = [...filteredItems].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
    setFilteredItems(sortedData);
  };

  useEffect(() => {
    fetchList();
  }, [selectedEmployee, searchDate]);

  useEffect(() => {
    fetchListEmployee();
  }, []);

  const fetchListEmployee = () => {
    setIsLoading(true);
    getApi(GET_EMPLOYEE_LIST, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setEmployeeList(res.data.data);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  const fetchList = () => {
    setIsLoading(true);
    getApi(
      API_LEAVE_TODAY +
        `?date=${searchDate}&empid=${
          selectedEmployee && selectedEmployee?.value
        }`,
      accessToken
    )
      .then((res) => {
        if (res.status === 200) {
          setLeaveList(res.data?.data ? res.data?.data : []);
          setFilteredItems(res.data?.data ? res.data?.data : []);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  const optionList = employeeList?.map((item) => ({
    value: item.id,
    label: item.empName,
    type: item.employeeType,
  }));

  const Option = (props) => {
    const { data } = props;
    const { label, type } = data;

    return (
      <components.Option {...props}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>{label}</span>
          <span style={{ fontSize: "12px", color: "gray" }}>({type})</span>
        </div>
      </components.Option>
    );
  };
  return (
    <div className="wrapper-body">
      {/* Page Heading */}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <h1>On Leave</h1>

        <div className="total">
          Total Leaves : <span>{filteredItems?.length}</span>
        </div>
      </div>
      {/* Filters */}
      <div className="filters">
        {/* Search By Company */}
        <div className="search">
          <input
            type="date"
            name="search"
            value={searchDate}
            onChange={(e) => setSearchDate(e.target.value)}
            onKeyPress={(e) => {
              if (e.charCode === 32 && e.target.selectionStart === 0) {
                e.preventDefault(); // Prevent adding space at the beginning
              }
            }}
          />
        </div>
        {/*
        <div className="search">
          <Select
            options={optionList}
            components={{ Option }}
            onChange={(option) => {
              setSelectedEmployee(option);
            }}
            value={selectedEmployee}
            placeholder="Select employee"
            isSearchable={true}
            isDisabled={employeeList?.length === 0}
            isRequired={true}
            styles={{
              container: (provided) => ({ ...provided, width: "100%" }),
            }}
          />
        </div>
        */}
      </div>

      {/* Data Table */}
      {!isLoading && filteredItems?.length > 0 && (
        <>
          <div className="table-responsive">
            <table className="resume custom">
              {/* Table Headings */}
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>
                    Employee
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                      onClick={() => handleSort("name")}
                    />
                  </th>
                  <th>
                    From{" "}
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                      onClick={() => handleSort("startDate")}
                    />
                  </th>

                  <th>
                    To{" "}
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                      onClick={() => handleSort("endDate")}
                    />
                  </th>
                  <th>
                    Type{" "}
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                      onClick={() => handleSort("typeOfLeave")}
                    />
                  </th>
                  <th>
                    Leave Count
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                      onClick={() => handleSort("noOfDays")}
                    />
                  </th>
                  <th>
                    Reason
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                      onClick={() => handleSort("reasonOfLeave")}
                    />
                  </th>

                  {/* <th onClick={() => handleSort("leaveFinalStatus")}>
                    Status
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th> */}
                </tr>
              </thead>

              {/* Table Body */}
              <tbody>
                {filteredItems?.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.name}</td>
                    <td>{item.startDate}</td>
                    <td>{item.endDate}</td>
                    <td>{formatLeaveType(item.typeOfLeave)}</td>
                    <td>{item.noOfDays}</td>
                    <td style={{ maxWidth: "250px" }}>
                      <TruncateText text={item.reasonOfLeave} />
                    </td>

                    {/* <td>{item.leaveFinalStatus}</td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
      {!isLoading && filteredItems?.length === 0 && (
        <div className="fs-4 text-secondary text-center">
          No Record Available
        </div>
      )}
      {isLoading && (
        <div className="centered-loader">
          <ThreeDots
            height="100"
            width="100"
            radius="9"
            color="blue"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default OnLeaveToday;
