const ReviewEmployeeData = ({ empData }) => {
  return (
    <div>
      <h6 className="heading mt-4">Personal Details</h6>
      <div className="row">
        <div className="col-md-2">
          <div className="d-flex flex-column  text-black-50">
            <p>First Name</p>
            <p>Middle Name</p>
            <p>Last Name</p>
            <p>Personal Email</p>
          </div>
        </div>
        <div className="col-md-2">
          <div className="d-flex flex-column">
            <p className="truncate-text">{empData.firstName}</p>
            <p className="truncate-text">
              {empData.middleName ? empData.middleName : "-"}
            </p>
            <p className="truncate-text">{empData.lastName}</p>
            <p>{empData.personalEmail}</p>
          </div>
        </div>
        <div className="col-md-2">
          <div className="d-flex flex-column  text-black-50">
            <p>Gender</p>
            <p>Blood Group</p>
            <p>Date of Joining</p>
            <p>Marital Status</p>
          </div>
        </div>
        <div className="col-md-2">
          <div className="d-flex flex-column">
            <p>{empData.gender}</p>
            <p>{empData.bloodGroup}</p>
            <p>{empData.joiningDate}</p>
            <p>
              {empData.maritalStatus} {empData.anniversaryDate}
            </p>
          </div>
        </div>
        <div className="col-md-2">
          <div className="d-flex flex-column  text-black-50">
            <p>Date of Birth</p>
            <p>Aadhar No.</p>
            <p>PAN Card No.</p>
            <p>Passport No.</p>
          </div>
        </div>
        <div className="col-md-2">
          <div className="d-flex flex-column">
            <p>{empData.dob}</p>
            <p>{empData.aadharNumber}</p>
            <p>{empData.panCardNumber}</p>
            <p>{empData.passportNumber ? empData.passportNumber : "-"}</p>
          </div>
        </div>
      </div>
      <h6 className="heading mt-2">Contact Details</h6>
      <div className="row">
        <div className="col-md-2">
          <div className="d-flex flex-column  text-black-50">
            <p>Phone Number</p>

            <p>Alternate Name</p>
          </div>
        </div>
        <div className="col-md-2">
          <div className="d-flex flex-column">
            <p>{empData.phoneNumber}</p>

            <p className="truncate-text">{empData.alternateName}</p>
          </div>
        </div>
        <div className="col-md-2">
          <div className="d-flex flex-column  text-black-50">
            <p>Alternate Number</p>

            <p>Present Address</p>
          </div>
        </div>
        <div className="col-md-2">
          <div className="d-flex flex-column">
            <p>{empData.alternateNumber}</p>
            <p>{empData.presentAddress}</p>
          </div>
        </div>
        <div className="col-md-2">
          <div className="d-flex flex-column  text-black-50">
            <p>Rel. with alternate </p>
            <p>Permanent Address</p>
          </div>
        </div>
        <div className="col-md-2">
          <div className="d-flex flex-column">
            <p>{empData.relationWithAlternateNo}</p>
            <p>{empData.permanentAddress}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewEmployeeData;
