import Cookies from "js-cookie";
import React, { useState } from "react";
import { TailSpin } from "react-loader-spinner";
import {
  API_REIMBURSEMENT,
  API_REIMBURSEMENT_EXPORT,
} from "../../config/Endpoints";
import { postApi } from "../../utils/api";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { generateYear, toggleMenu } from "../../utils/helpers";
import Footer from "../../components/Footer";

const DownloadReimbursement = () => {
  const accessToken = Cookies.get("accessToken");
  // Calculate the date 30 days ago
  const today = new Date();
  const defaultFromDate = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000); // Subtracting 30 days
  const defaultFromDateString = defaultFromDate.toISOString().split("T")[0]; // Convert to string in YYYY-MM-DD format
  // Get today's date in the format yyyy-mm-dd
  const currentDate = new Date().toISOString().split("T")[0];
  const [formData, setFormData] = useState({
    reimbursementType: "",
    from: defaultFromDateString ? defaultFromDateString : "",
    to: currentDate,
    status: "",
  });
  const [isLoading, setIsLoading] = useState();
  const [yearList] = useState(generateYear(2020));
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await fetch(
        `${API_REIMBURSEMENT_EXPORT}?fromDate=${formData.from}&toDate=${formData.to}&reimbursemenType=${formData.reimbursementType}&status=${formData.status}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/vnd.ms-excel",
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            toast.error("No record available to export", {
              position: toast.POSITION.TOP_RIGHT,
            });
            throw new Error("Network response was not ok");
          }
          return response.blob();
        })
        .then((blob) => {
          // Create a URL object from the blob
          const url = URL.createObjectURL(blob);
          // Create a temporary link element to trigger the download
          const link = document.createElement("a");
          link.href = url;
          link.download = `Reimbursement_${formData.from}_${formData.to}_${formData.reimbursementType}_${formData.status}_list.xlsx`;
          link.click();
          // Clean up the URL object
          URL.revokeObjectURL(url);
          setIsLoading(false);
          handleReset();
        })
        .catch((error) => {
          console.error("Error downloading file:", error);
          setIsLoading(false);
          toast.error(error?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } catch (error) {
      setIsLoading(false);
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleReset = () => {
    setFormData({
      reimbursementType: "",
      from: defaultFromDateString,
      to: currentDate,
      status: "",
    });
  };

  const handleInputChange = (event) => {
    const { name, value, type } = event.target;
    if (type === "date") {
      const year = value.split("-")[0];
      if (year.length <= 4) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  return (
    <div className="wrapper-body">
      {isLoading && (
        <div className="overlay">
          <div className="loader-container">
            <TailSpin
              height="100"
              width="100"
              radius="1"
              color="blue"
              ariaLabel="tail-spin-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <h1></h1>
        {/* <div className="total"> */}
        {/* <Link to="/dashboard/reimbursement" className="brand-color">
            Back to Reimbursement Request List
          </Link> */}
        {/* </div> */}
      </div>
      <form id="assign-asset" onSubmit={handleSubmit}>
        <div className="card">
          <h3 className="heading">Download Reimbursement</h3>
          <div className="inputs">
            <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-2">
                <label htmlFor="reimbursementType" className="has-asterisk">
                  Reimbursement Type
                </label>
              </div>
              <div className="col-md-5">
                <div className="input-group">
                  <select
                    id="reimbursementType"
                    required
                    name="reimbursementType"
                    value={formData.reimbursementType}
                    onChange={handleInputChange}
                  >
                    <option value="">Select type</option>
                    <option>Internet Claim</option>
                    <option>Travel Expense</option>
                    <option>Miscellaneous</option>
                  </select>
                </div>
              </div>
              <div className="col-md-3"></div>
            </div>

            <div className="row mt-1">
              <div className="col-md-2"></div>
              <div className="col-md-2 ">
                <label htmlFor="year" className="has-asterisk">
                  From Date
                </label>
              </div>
              <div className="col-md-5">
                <div className="input-group">
                  <input
                    type="date"
                    required
                    name="from"
                    value={formData.from}
                    placeholder="08/02/2019"
                    onChange={handleInputChange}
                    pattern="\d{2}/\d{2}/\d{4}"
                  />
                </div>
              </div>
              <div className="col-md-3"></div>
            </div>
            <div className="row mt-1">
              <div className="col-md-2"></div>
              <div className="col-md-2 ">
                <label htmlFor="month" className="has-asterisk">
                  To Date
                </label>
              </div>
              <div className="col-md-5">
                <div className="input-group">
                  <input
                    type="date"
                    required
                    name="to"
                    value={formData.to}
                    placeholder="08/02/2019"
                    onChange={handleInputChange}
                    min={formData.from}
                  />
                </div>
              </div>
              <div className="col-md-3"></div>
            </div>
            <div className="row mt-1">
              <div className="col-md-2"></div>
              <div className="col-md-2 ">
                <label htmlFor="status" className="has-asterisk">
                  Status
                </label>
              </div>
              <div className="col-md-5">
                <div className="input-group">
                  <select
                    id="status"
                    required
                    name="status"
                    onChange={handleInputChange}
                    value={formData.status}
                  >
                    <option value="">Select Status</option>
                    <option value="ALL">All</option>
                    <option value="APPROVED">Approved</option>
                    <option value="REJECTED">Rejected</option>
                  </select>
                </div>
              </div>
              <div className="col-md-3"></div>
            </div>
          </div>
          <br />

          <div className="action text-center">
            <button className="btn btn-secondary" onClick={handleReset}>
              Reset
            </button>
            <button
              className={
                isLoading ? "theme-button disabled ms-2 " : "theme-button ms-2 "
              }
              type="submit"
            >
              Download
            </button>
          </div>
        </div>
      </form>

      <Footer />
    </div>
  );
};

export default DownloadReimbursement;
