import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { deleteApi, getApi } from "../../utils/api";
import { API_EMPLOYEE_DEPENDENT } from "../../config/Endpoints";
import { toast } from "react-toastify";
import AddEducation from "./AddEducation";
import AddDependent from "./AddDependent";
import { ThreeDots } from "react-loader-spinner";
import moment from "moment";

const Dependents = ({ dependentData }) => {
  const [dependentList, setDependentList] = useState([]);
  const accessToken = Cookies.get("accessToken");
  const [selectedDependent, setSelectedDependent] = useState({});
  const [selectedDependentId, setSelectedDependentId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setDependentList(dependentData);
  }, [dependentData]);

  const handleDelete = () => {
    setIsLoading(true);
    deleteApi(API_EMPLOYEE_DEPENDENT + `/${selectedDependentId}`, accessToken)
      .then((res) => {
        if (res.status === 200) {
          let newArray = dependentList.filter(
            (item) => item.dependentId !== selectedDependentId
          );
          setDependentList(newArray);
          setIsLoading(false);
          const modalClose = document.getElementsByClassName(
            "cancel-modal-dependent-delete"
          )[0];
          modalClose.click();
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };
  const handleDeleteAlert = (id) => {
    setSelectedDependentId(id);
  };

  const handleView = (item) => {
    setSelectedDependent(item);
  };

  return (
    <>
      <div className="table-responsive">
        {dependentList?.length === 0 && dependentList && (
          <div className="fs-4 text-secondary text-center">
            No records available
          </div>
        )}
        {dependentList?.length !== 0 && (
          <table className="resume custom">
            {/* Table Headings */}
            <thead>
              <tr>
                <th>Name</th>
                <th>Gender</th>
                <th>DOB</th>
                <th>Relation</th>
                <th>Mobile Number</th>
                <th>Actions</th>
              </tr>
            </thead>

            {/* Table Body */}
            <tbody>
              {dependentList &&
                dependentList?.map((item, index) => (
                  <tr key={index}>
                    <td>{item.name}</td>
                    <td>{item.gender}</td>
                    <td>{moment(item.dob).format("DD-MM-yyyy")}</td>
                    <td>{item.relationship}</td>
                    <td>{item.number}</td>
                    <td>
                      <a
                        href="javascript:void(0)"
                        onClick={() => handleDeleteAlert(item.dependentId)}
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdropDependentDelete"
                        className="text-danger"
                      >
                        Delete
                      </a>
                      <a
                        href="javascript:void(0)"
                        onClick={() => handleView(item)}
                        className="ms-2"
                      >
                        Edit
                      </a>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>
      <button
        className="btn btn-primary mt-3 add-dependent"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdropDependent"
      >
        + Add Dependents
      </button>

      <div
        className="modal fade"
        id="staticBackdropDependent"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <AddDependent
              setDependentList={setDependentList}
              selectedDependent={selectedDependent}
              setSelectedDependent={setSelectedDependent}
            />
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="staticBackdropDependentDelete"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-body">
              <h5 className="text-center">
                Are you sure you want to delete this dependent?
              </h5>
            </div>
            <div className="justify-content-center modal-footer">
              <button
                type="button"
                className={
                  isLoading ? "theme-button disabled " : "theme-button "
                }
                onClick={handleDelete}
              >
                {isLoading ? (
                  <ThreeDots
                    height="20"
                    width="53"
                    radius="9"
                    color="white"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                ) : (
                  "Yes"
                )}
              </button>
              <button
                type="button"
                className="theme-button bg-grey mx-3 w-35 cancel-modal-dependent-delete"
                data-bs-dismiss="modal"
                onClick={() => setSelectedDependentId("")}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dependents;
