import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
// import { logoutUser } from "../utils/Storage";
// import { Link } from "react-router-dom";
import ChangePassword from "./Employee/ChangePassword";
import { getUser, logoutUser } from "../utils/Storage";
import { formatLeaveType, getGreeting, toggleMenu } from "../utils/helpers";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import {
  API_ANNOUNCEMENT_DASHBOARD,
  API_DOCUMENT_STATUS,
  API_LEAVE_TODAY,
  API_PUNCH,
} from "../config/Endpoints";
import { getApi } from "../utils/api";
import { TailSpin } from "react-loader-spinner";
import { toast } from "react-toastify";
import RaiseConcern from "./Dashboard/RaiseConcern";
import DocumentUpload from "./Dashboard/DocumentUpload";
import moment from "moment";
import TruncateText from "../components/TruncateText";
// import UploadDocument from "./EmployeeOnboard/CreateEmployee/UploadDocument";
// import DocumentUpload from "./Dashboard/DocumentUpload";

const MainDashboard = () => {
  const myChart = {
    options: {
      dataLabels: {
        enabled: false,
      },
      chart: {
        width: "100%",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      legend: {
        position: "top",
        horizontalAlign: "right",
      },
      colors: ["#b9cfe9", "#9fb4cd", "#6a7e95", "#81a8d6"],
      stroke: {
        curve: "smooth",
        width: 2,
      },
      // title: {
      //   text: "Attendance",
      //   align: "left",
      //   fontSize: "30px",
      // },
      // subtitle: {
      //   text: "May- June 2023",
      //   align: "left",
      // },
      tooltip: {
        enabled: false,
      },
      responsive: [
        {
          breakpoint: 320,
          options: {
            plotOptions: {
              line: {
                horizontal: true,
              },
            },
            legend: {
              position: "top",
              horizontalAlign: "right",
            },
          },
        },
      ],
      toolbar: {
        show: false,
      },
      grid: {
        padding: {
          left: 10,
          right: 10,
        },
      },
      xaxis: {
        categories: ["August", "September", "October"],
      },
    },

    series: [
      {
        name: "Work from office",
        data: [15, 18, 12],
      },
      {
        name: "Work from home",
        data: [7, 4, 10],
      },
      {
        name: "Days off",
        data: [1, 2, 0],
      },
    ],
  };

  const chartOptions = {
    labels: ["Task", "Project", "Progress", "Deadline"],
    dataLabels: { enabled: false }, // Disable data labels
  };

  const chartSeries = [44, 55, 13, 43];
  const userData = JSON.parse(getUser("userData"));

  const punchInData = [
    {
      date: "Mon, Oct 22",
      punch_in_time: "02:30 pm",
      punch_in_out: "10:00 pm",
      total_hour: "7:30 hrs",
      is_positive_hour: true,
    },
    {
      date: "Tue, Oct 23",
      punch_in_time: "03:17 pm",
      punch_in_out: "10:00 pm",
      total_hour: "7:30 hrs",
      is_positive_hour: false,
    },
  ];
  const [punchList, setPunchList] = useState([]);
  const [classroomSession, setClassroomSession] = useState([]);
  const [coffeeLearnSession, setCoffeeLearnSession] = useState([]);
  const [salesforceSession, setSalesforceSession] = useState([]);
  const [muleSoftMeetups, setMuleSoftMeetups] = useState([]);
  const [leaveList, setLeaveList] = useState([]);
  const [session, setSession] = useState([]);
  const [isLoading, setIsLoading] = useState(null);
  const accessToken = Cookies.get("accessToken");
  const [documentList, setDocumentList] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    getApi(API_PUNCH, accessToken)
      .then((res) => {
        setPunchList(res?.data?.data);
        setIsLoading(false);
      })
      .catch((e) => {
        toast.error("Something went wrong please try again !!", {
          position: toast.POSITION.TOP_RIGHT,
        });

        setIsLoading(false);
      });
    fetchSessions();
  }, []);

  const fetchSessions = () => {
    getApi(API_ANNOUNCEMENT_DASHBOARD, accessToken)
      .then((res) => {
        if (res?.data?.data) {
          const resultData = res?.data?.data?.data;
          setSession(resultData);
          const classSession = resultData?.filter(
            (it) => it.eventType === "Classroom Session"
          );
          const coffeeSession = resultData?.filter(
            (it) => it.eventType === "Coffee & Learn Session"
          );
          const muleSoft = resultData?.filter(
            (it) => it.eventType === "MuleSoft Meetups"
          );
          const salesforce = resultData?.filter(
            (it) => it.eventType === "Salesforce Session"
          );
          setClassroomSession(classSession);
          setCoffeeLearnSession(coffeeSession);
          setMuleSoftMeetups(muleSoft);
          setSalesforceSession(salesforce);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        if (e?.response?.data?.message !== "Announcement not found !") {
          toast.error(
            e?.response?.data?.message
              ? e?.response?.data?.message
              : "Something went wrong please try again !!",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        }

        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchDocumentStatus();
    fetchList();
  }, []);

  const fetchDocumentStatus = () => {
    setIsLoading(true);
    getApi(API_DOCUMENT_STATUS, accessToken)
      .then((res) => {
        setDocumentList(res?.data?.data);
        setIsLoading(false);
      })
      .catch((e) => {
        toast.error("Something went wrong please try again !!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsLoading(false);
      });
  };

  const fetchList = () => {
    setIsLoading(true);
    getApi(
      API_LEAVE_TODAY + `?date=${new Date().toISOString().slice(0, 10)}`,
      accessToken
    )
      .then((res) => {
        if (res.status === 200) {
          setLeaveList(res.data?.data ? res.data?.data : []);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (documentList.length > 1) {
      const hasPendingFiles = documentList.filter(
        (doc) => doc.isMandatory === true && !doc.isUploaded
      );
      const localData = localStorage.getItem("documentModal", "Closed");
      if (hasPendingFiles.length > 1 && localData !== "Closed") {
        // Open the modal for uploading
        const openModalButton =
          document.getElementsByClassName("click-modal-upload")[0];
        if (openModalButton) {
          openModalButton.click();
        }
      }
      if (hasPendingFiles.length === 0) {
        // Close the modal if all mandatory documents are uploaded
        const closeModalButton =
          document.getElementsByClassName("btn-close-document")[0];
        if (closeModalButton) {
          closeModalButton.click();
        }
      }
    }
  }, [documentList]);

  function getDisplaySessions(sessions) {
    return sessions.filter((item) => item.eventType === "Display Session");
  }
  const displaySessions = getDisplaySessions(session);

  return (
    <div className="wrapper-body">
      {/* Page Heading */}
      {isLoading && (
        <div className="overlay">
          <div className="loader-container">
            <TailSpin
              height="100"
              width="100"
              radius="1"
              color="blue"
              ariaLabel="tail-spin-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <h1>
          {getGreeting()}!
          <span> {userData ? userData?.name : "Loading..."}</span>.
        </h1>
        <div className="dropdown">
          <div
            className="btn btn-mute dropdown-toggle "
            type="button"
            id="userDropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              src={
                !userData?.photoPath.includes("photoDir")
                  ? "../assets/profile.png"
                  : process.env.REACT_APP_IMAGE_URL + userData?.photoPath
              }
              alt="profile"
              className="avatar"
            />
          </div>
          <ul className="dropdown-menu" aria-labelledby="userDropdown">
            <li>
              {/* <a
                className="dropdown-item"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdropChangePassword"
              >
                Change Password
              </a> */}
              <Link className="dropdown-item" to="/dashboard/myProfile">
                Profile
              </Link>
            </li>
            <li>
              <Link className="dropdown-item" onClick={logoutUser}>
                Logout
              </Link>
            </li>
          </ul>
        </div>
      </div>

      {/* <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <h1>Employee Dashboard</h1>
        <div className="total">
          <div className="dropdown">
            <button
              className="btn btn-mute dropdown-toggle"
              type="button"
              id="userDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                style={{ width: "30px", height: "30px" }}
                src="../assets/profile.png"
                alt="profile"
              />{" "}
              User
            </button>
            <ul className="dropdown-menu" aria-labelledby="userDropdown">
              <li>
                <a
                  className="dropdown-item"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdropChangePassword"
                >
                  Change Password
                </a>
              </li>
              <li>
                <Link className="dropdown-item" onClick={logoutUser}>
                  Logout
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div> */}

      <div className="row">
        <div className="col-12">
          <marquee
            direction="left"
            className="marquee-dashboard"
            style={{ whiteSpace: "nowrap" }}
          >
            {displaySessions.map((event) => (
              <span
                key={event.id}
                style={{ display: "inline", marginRight: "20px" }}
              >
                - {event.title} (
                {moment(event.startDateTime)
                  .utcOffset("+05:30")
                  .format("ddd, DD MMM, YYYY | HH:mm") + " IST"}
                )
              </span>
            ))}
          </marquee>
        </div>
      </div>

      <div className="punchin">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <h3 className="heading">
                Punch in-out timings (Current Week)
                <Link
                  className="theme-button p-2 float-end"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdropRaiseConcern"
                >
                  Raise Concern
                </Link>
              </h3>
              <div className="row g-2">
                {/* <div className="col-md-2">
                  <p className="text-grey">This week</p>
                </div> */}
                {punchList?.map((item, index) => (
                  <div className="col" key={index}>
                    <div className="bg-white punchin--box p-2 rounded shadow-sm">
                      <h6 className="border-2 border-bottom pb-2">
                        {item.date}
                      </h6>
                      <div className="d-grid punchin--box_outer">
                        <p>
                          <span>In</span>
                        </p>
                        <p>{item.punch_in_time}</p>
                        <p>
                          <span>Out</span>
                        </p>
                        <p>{item.punch_in_out}</p>
                        <p>
                          <span>Hours</span>
                        </p>
                        <p
                          className={
                            item.is_positive_hour
                              ? "text-success"
                              : "text-danger"
                          }
                        >
                          {item.total_hour}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}

                {/* <div className="col">
                  <div className="bg-white punchin--box p-2 rounded shadow-sm">
                    <h6 className="border-2 border-bottom pb-2">Mon, Oct 22</h6>
                    <div className="d-grid punchin--box_outer">
                      <p>
                        <span>In</span>
                      </p>
                      <p>02:30 pm</p>
                      <p>
                        <span>Out</span>
                      </p>
                      <p>10:00 pm</p>
                      <p>
                        <span>Hours</span>
                      </p>
                      <p className="text-danger">7:30 hrs</p>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="bg-white punchin--box p-2 rounded shadow-sm">
                    <h6 className="border-2 border-bottom pb-2">Tue, Oct 23</h6>
                    <div className="d-grid punchin--box_outer">
                      <p>
                        <span>In</span>
                      </p>
                      <p>03:17 pm</p>
                      <p>
                        <span>Out</span>
                      </p>
                      <p>-</p>
                      <p>
                        <span>Hours</span>
                      </p>
                      <p className="text-danger">-</p>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="bg-white punchin--box p-2 rounded shadow-sm">
                    <h6 className="border-2 border-bottom pb-2">Wed, Oct 24</h6>
                    <div className="d-grid punchin--box_outer">
                      <p>
                        <span>In</span>
                      </p>
                      <p>12:00 pm</p>
                      <p>
                        <span>Out</span>
                      </p>
                      <p>09:00 pm</p>
                      <p>
                        <span>Hours</span>
                      </p>
                      <p className="text-success">9:00 hrs</p>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="bg-white punchin--box p-2 rounded shadow-sm">
                    <h6 className="border-2 border-bottom pb-2">Thu, Oct 25</h6>
                    <div className="d-grid punchin--box_outer">
                      <p>
                        <span>In</span>
                      </p>
                      <p>01:00 pm</p>
                      <p>
                        <span>Out</span>
                      </p>
                      <p>10:30 pm</p>
                      <p>
                        <span>Hours</span>
                      </p>
                      <p className="text-success">9:30 hrs</p>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="card min-dashboard-card">
            <h3 className="heading d-f a-i-c justify-content-between">
              Classroom Sessions
              {classroomSession?.length > 0 && (
                <Link
                  className="theme-button p-2"
                  to="/dashboard/view-announcement#tab1"
                >
                  View All
                </Link>
              )}
            </h3>
            <div className="row gx-2">
              {classroomSession?.map((item, index) => (
                <div className="col-md-6" key={index}>
                  <div className="outer border p-2">
                    <Link to="/dashboard/view-announcement#tab1">
                      <h6 className="text-capitalize">{item.title}</h6>
                      <p className="mb-1">
                        <small>
                          {moment(item.startDateTime)
                            .utcOffset("+05:30")
                            .format("ddd, DD MMM, YYYY | HH:mm") + " IST"}
                        </small>
                      </p>
                      <p className="mb-0">
                        <small className="text-capitalize">
                          {item.presentedBy}
                        </small>
                      </p>
                    </Link>
                  </div>
                </div>
              ))}
              {classroomSession?.length === 0 && (
                <div className="col-md-12">
                  <div className="outer border p-2 text-center">
                    <h6 className="space-text">
                      No upcoming training sessions are currently scheduled.
                    </h6>
                  </div>
                </div>
              )}

              {/* <div className="col-md-6">
                <div className="outer border p-2">
                  <Link>
                    <h6>MuleSoft migration 4 - 3</h6>
                    <p className="mb-1">
                      <small>Fri, 20 Jun, 2024 | 17:00 IST</small>
                    </p>
                    <p className="mb-0">
                      <small>Anubhav Mangla</small>
                    </p>
                  </Link>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card min-dashboard-card">
            <h3 className="heading d-f a-i-c justify-content-between">
              Coffee & Learn Sessions
              {coffeeLearnSession?.length > 0 && (
                <Link
                  className="theme-button p-2"
                  to="/dashboard/view-announcement#tab2"
                >
                  View All
                </Link>
              )}
            </h3>
            <div className="row gx-2">
              {coffeeLearnSession?.map((item, index) => (
                <div className="col-md-6" key={index}>
                  <div className="outer border p-2">
                    <Link to="/dashboard/view-announcement#tab2">
                      <h6 className="text-capitalize">{item.title}</h6>
                      <p className="mb-1">
                        <small>
                          {moment(item.startDateTime)
                            .utcOffset("+05:30")
                            .format("ddd, DD MMM, YYYY | HH:mm") + " IST"}
                        </small>
                      </p>
                      <p className="mb-0">
                        <small className="text-capitalize">
                          {item.presentedBy}
                        </small>
                      </p>
                    </Link>
                  </div>
                </div>
              ))}
              {coffeeLearnSession?.length === 0 && (
                <div className="col-md-12">
                  <div className="outer border p-2 text-center ">
                    <h6 className="space-text">
                      No upcoming training sessions are currently scheduled.
                    </h6>
                  </div>
                </div>
              )}
              {/* <div className="col-md-6">
                <div className="outer border p-2">
                  <Link>
                    <h6>MuleSoft migration 4 - 3</h6>
                    <p className="mb-1">
                      <small>Fri, 20 Jun, 2024 | 17:00 IST</small>
                    </p>
                    <p className="mb-0">
                      <small>Anubhav Mangla</small>
                    </p>
                  </Link>
                </div>
              </div>
              <div className="col-md-6">
                <div className="outer border p-2">
                  <Link>
                    <h6>MuleSoft migration 4 - 3</h6>
                    <p className="mb-1">
                      <small>Fri, 20 Jun, 2024 | 17:00 IST</small>
                    </p>
                    <p className="mb-0">
                      <small>Anubhav Mangla</small>
                    </p>
                  </Link>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card min-dashboard-card">
            <h3 className="heading d-f a-i-c justify-content-between">
              MuleSoft Meetups
              {muleSoftMeetups?.length > 0 && (
                <Link
                  className="theme-button p-2"
                  to="/dashboard/view-announcement#tab3"
                >
                  View All
                </Link>
              )}
            </h3>
            <div className="row gx-2">
              {muleSoftMeetups?.map((item, index) => (
                <div className="col-md-6" key={index}>
                  <div className="outer border p-2">
                    <Link to="/dashboard/view-announcement#tab3">
                      <h6 className="text-capitalize">{item.title}</h6>
                      <p className="mb-1">
                        <small>
                          {moment(item.startDateTime)
                            .utcOffset("+05:30")
                            .format("ddd, DD MMM, YYYY | HH:mm") + " IST"}
                        </small>
                      </p>
                      <p className="mb-0">
                        <small className="text-capitalize">
                          {item.presentedBy}
                        </small>
                      </p>
                    </Link>
                  </div>
                </div>
              ))}
              {muleSoftMeetups?.length === 0 && (
                <div className="col-md-12">
                  <div className="outer border p-2 text-center ">
                    <h6 className="space-text">
                      No upcoming training sessions are currently scheduled.
                    </h6>
                  </div>
                </div>
              )}
              {/* <div className="col-md-6">
                <div className="outer border p-2">
                  <Link>
                    <h6>MuleSoft migration 4 - 3</h6>
                    <p className="mb-1">
                      <small>Fri, 20 Jun, 2024 | 17:00 IST</small>
                    </p>
                    <p className="mb-0">
                      <small>Anubhav Mangla</small>
                    </p>
                  </Link>
                </div>
              </div>
              <div className="col-md-6">
                <div className="outer border p-2">
                  <Link>
                    <h6>MuleSoft migration 4 - 3</h6>
                    <p className="mb-1">
                      <small>Fri, 20 Jun, 2024 | 17:00 IST</small>
                    </p>
                    <p className="mb-0">
                      <small>Anubhav Mangla</small>
                    </p>
                  </Link>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card min-dashboard-card">
            <h3 className="heading d-f a-i-c justify-content-between">
              Salesforce Sessions
              {salesforceSession?.length > 0 && (
                <Link
                  className="theme-button p-2"
                  to="/dashboard/view-announcement#tab4"
                >
                  View All
                </Link>
              )}
            </h3>
            <div className="row gx-2">
              {salesforceSession?.map((item, index) => (
                <div className="col-md-6" key={index}>
                  <div className="outer border p-2">
                    <Link to="/dashboard/view-announcement#tab4">
                      <h6 className="text-capitalize">{item.title}</h6>
                      <p className="mb-1">
                        <small>
                          {moment(item.startDateTime)
                            .utcOffset("+05:30")
                            .format("ddd, DD MMM, YYYY | HH:mm") + " IST"}
                        </small>
                      </p>
                      <p className="mb-0">
                        <small className="text-capitalize">
                          {item.presentedBy}
                        </small>
                      </p>
                    </Link>
                  </div>
                </div>
              ))}
              {salesforceSession?.length === 0 && (
                <div className="col-md-12">
                  <div className="outer border p-2 text-center ">
                    <h6 className="space-text">
                      No upcoming training sessions are currently scheduled.
                    </h6>
                  </div>
                </div>
              )}
              {/* <div className="col-md-6">
                <div className="outer border p-2">
                  <Link>
                    <h6>MuleSoft migration 4 - 3</h6>
                    <p className="mb-1">
                      <small>Fri, 20 Jun, 2024 | 17:00 IST</small>
                    </p>
                    <p className="mb-0">
                      <small>Anubhav Mangla</small>
                    </p>
                  </Link>
                </div>
              </div>
              <div className="col-md-6">
                <div className="outer border p-2">
                  <Link>
                    <h6>MuleSoft migration 4 - 3</h6>
                    <p className="mb-1">
                      <small>Fri, 20 Jun, 2024 | 17:00 IST</small>
                    </p>
                    <p className="mb-0">
                      <small>Anubhav Mangla</small>
                    </p>
                  </Link>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="row">
        <div className="col-md-3 ">
          <div className="card text-center">
            <h3 className="theme-color">3</h3>
            <p className="mb-0">No. of projects</p>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card text-center">
            <h3 className="theme-color">236</h3>
            <p className="mb-0">Days present</p>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card text-center">
            <h3 className="text-danger">8</h3>
            <p className="mb-0">Days off</p>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card text-center">
            <h3 className="text-success">3</h3>
            <p className="mb-0">Privilege leaves</p>
          </div>
        </div>
      </div> */}

      <div className="row">
        {leaveList?.length > 0 && (
          <div className="col-md-12">
            <div className="employee_leaves">
              <div className="card ">
                <h3 className="heading d-f a-i-c justify-content-between">
                  Employees on leave
                  {leaveList?.length > 5 && (
                    <Link
                      className="theme-button p-2"
                      to="/dashboard/on-leave-today"
                    >
                      View All
                    </Link>
                  )}
                </h3>

                {/* Data Table */}
                {!isLoading && leaveList?.length > 0 && (
                  <table className="resume custom">
                    {/* Table Headings */}
                    <thead>
                      <tr>
                        <th>Id</th>
                        <th>Employee</th>
                        <th>From</th>
                        <th>To</th>
                        <th>Type</th>
                        <th>Reason</th>
                        <th>Leave Count</th>
                      </tr>
                    </thead>
                    {/* Table Body */}
                    <tbody>
                      {leaveList?.slice(0, 5).map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.name}</td>
                          <td>{item.startDate}</td>
                          <td>{item.endDate}</td>
                          <td>{formatLeaveType(item.typeOfLeave)}</td>
                          <td style={{ maxWidth: "250px" }}>
                            <TruncateText text={item.reasonOfLeave} />
                          </td>
                          <td>{item.noOfDays}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        )}
        {/* <div className="col-md-6">
          <div className="projects">
            <div className="card">
              <h3 className="heading">Projects</h3>
              <table className="table mb-0">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Week hours</th>
                    <th>Total hours</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>MuleSoft RPA - Case 1</td>
                    <td>30 hrs</td>
                    <td>280 hrs</td>
                  </tr>
                  <tr>
                    <td>Boulevard</td>
                    <td>18 hrs</td>
                    <td>29 hrs</td>
                  </tr>
                  <tr>
                    <td>MuleSoft RPA - Case 1</td>
                    <td>30 hrs</td>
                    <td>280 hrs</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
       
        <div className="col-md-3">
          <div className="notifications_panel">
            <div className="card">
              <h3 className="heading">Notifications</h3>
              <table className="table mb-0">
                <tbody>
                  <tr>
                    <td>
                      <em>
                        <a href="#!">
                          Celebrating cybersecurity week from Oct 30th - Nov
                          3rd. Join us.{" "}
                        </a>
                      </em>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <em>
                        <a href="#!">
                          Another notification comes here. It's a hyperlink.
                        </a>
                      </em>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div> */}
      </div>
      {/* <div className="row">
        <div className="col-md-6">
          <div className="card">
            <h3 className="heading">Attendance</h3>
            <p>Last 3 months</p>
            <Chart
              options={myChart.options}
              series={myChart.series}
              type="bar"
              height="300"
              width="100%"
            />
          </div>
        </div>

        <div className="col-md-6">
          <div className="card">
            <h3 className="heading">Top 5 employees</h3>
            <p>Year 2023-24</p>
          </div>
        </div>
      </div> */}
      <div
        className="modal fade"
        id="staticBackdropChangePassword"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <ChangePassword />
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="staticBackdropRaiseConcern"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <RaiseConcern />
          </div>
        </div>
      </div>
      <button
        className="click-modal-upload"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdropDocument"
        style={{ display: "none" }}
      >
        click
      </button>
      <div
        className="modal fade"
        id="staticBackdropDocument"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <button
            type="button"
            className="btn-close-document"
            data-bs-dismiss="modal"
            aria-label="Close"
            style={{ display: "none" }}
          ></button>
          <div className="modal-content">
            <DocumentUpload
              documentList={documentList}
              fetchDocumentStatus={fetchDocumentStatus}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainDashboard;
