import React, { useEffect, useState } from "react";
import { getApi } from "../../utils/api";
import Cookies from "js-cookie";
import { API_APPRAISAL, API_DOWNLOAD_APPRAISAL } from "../../config/Endpoints";
import moment from "moment";
import { getUser } from "../../utils/Storage";

const AppraisalList = () => {
  const [appraisalList, setAppraisalList] = useState([]);
  const accessToken = Cookies.get("accessToken");
  const userData = JSON.parse(getUser("userData"));

  const fetchData = async () => {
    await getApi(API_APPRAISAL, accessToken)
      .then((res) => {
        setAppraisalList(res.data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDownload = (e, item) => {
    e.preventDefault();
    fetch(
      `${API_DOWNLOAD_APPRAISAL}?fileName=${item.documentPath}&appraisalId=${item.documentId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        // Create a URL object from the blob
        const url = URL.createObjectURL(blob);

        // Create a temporary link element to trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.download = `Appraisal_${userData?.name}.pdf`;
        link.click();

        // Clean up the URL object
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  };
  return (
    <div className="table-responsive mt-4">
      {appraisalList?.length === 0 && appraisalList && (
        <div className="fs-4 text-secondary text-center">
          No records available
        </div>
      )}
      {appraisalList?.length !== 0 && (
        <table className="resume custom">
          {/* Table Headings */}
          <thead>
            <tr>
              <th>Document ID</th>
              <th>Letter Issue Date</th>
              <th>Effective Date</th>
              <th>Action</th>
            </tr>
          </thead>

          {/* Table Body */}
          <tbody>
            {appraisalList &&
              appraisalList?.map((item, index) => (
                <tr key={index}>
                  <td>{item.documentId}</td>
                  <td>{moment(item.letterIssueDate).format("DD-MM-yyyy")}</td>
                  <td>{moment(item.effectiveDate).format("DD-MM-yyyy")}</td>
                  <td>
                    <a
                      href="javascript:void(0)"
                      download
                      onClick={(e) => handleDownload(e, item)}
                    >
                      View
                    </a>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default AppraisalList;
