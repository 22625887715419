import React from "react";
import { toggleMenu } from "../../utils/helpers";
import Footer from "../../components/Footer";
import { getUser } from "../../utils/Storage";
import MyAttendanceCalendar from "./MyAttendanceCalendar";

const MyAttendance = () => {
  const userData = JSON.parse(getUser("userData"));

  return (
    <div className="wrapper-body">
      {/* Page Heading */}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <h1>My Attendance</h1>
      </div>

      {Object.keys(userData).length > 0 && (
        <MyAttendanceCalendar selectedEmployee={userData} />
      )}

      <Footer />
    </div>
  );
};

export default MyAttendance;
