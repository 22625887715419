import React from "react";
import moment from "moment";

const GenerateProgress = (assetProgressData) => {
  if (!assetProgressData) return null;

  const timeline = [];
  let currentStatus;
  if (assetProgressData.fourthApprovalDateTime === null) {
    currentStatus = "In Progress at Store";
  } else if (assetProgressData.thirdApprovalDateTime === null) {
    currentStatus = "In Progress at IT Team";
  } else if (assetProgressData.secondApprovalDateTime === null) {
    currentStatus = "In Progress at Management";
  } else if (assetProgressData.firstApprovalDateTime === null) {
    currentStatus = "In Progress at HR Team";
  }

  // First Approval (HR Team)
  if (assetProgressData.firstApprovalDateTime) {
    if (
      assetProgressData.trackingStatus === "Rejected" &&
      assetProgressData.secondApprovalDateTime === null
    ) {
      currentStatus = "Rejected";
    } else {
      currentStatus = "Allocated";
    }

    timeline.push(
      <li
        key="firstApproval"
        className={
          assetProgressData.trackingStatus === "Approved1"
            ? "approved"
            : currentStatus === "Rejected"
            ? "rejected"
            : "approved"
        }
      >
        <h6>HR Team</h6>
        <p>
          <small>
            {moment(assetProgressData.firstApprovalDateTime).format("HH:mm")}
            {".  "}
            {moment(assetProgressData.firstApprovalDateTime).format(
              "MMMM DD, YYYY"
            )}
          </small>
        </p>
        <p>{assetProgressData.firstApproverComment}</p>
      </li>
    );
  } else {
    timeline.push(
      <li
        key="firstApprovalPending"
        className={
          assetProgressData.trackingStatus !== "Rejected" ? "in-progress" : ""
        }
      >
        <h6>HR Team</h6>
      </li>
    );
  }

  // Second Approval (Management)
  if (assetProgressData.secondApprovalDateTime) {
    if (
      assetProgressData.trackingStatus === "Rejected" &&
      assetProgressData.thirdApprovalDateTime === null
    ) {
      currentStatus = "Rejected";
    } else if (assetProgressData.trackingStatus === "Approved2") {
      currentStatus = "Approved";
    }

    timeline.push(
      <li
        className={
          assetProgressData.trackingStatus === "Approved2"
            ? "approved"
            : currentStatus === "Rejected"
            ? "rejected"
            : "approved"
        }
      >
        <h6>Management Team</h6>
        <p>
          <small>
            {moment(assetProgressData.secondApprovalDateTime).format("HH:mm")}
            {".  "}
            {moment(assetProgressData.secondApprovalDateTime).format(
              "MMMM DD, YYYY"
            )}
          </small>
        </p>
        <p>{assetProgressData.secondApproverComment}</p>
      </li>
    );
  }
  // if (
  //   assetProgressData.secondApprovalDateTime === null &&
  //   assetProgressData.firstApprovalDateTime &&
  //   assetProgressData.trackingStatus !== "Rejected"
  // )
  else {
    timeline.push(
      <li
        key="secondApprovalRejected"
        className={
          assetProgressData.trackingStatus !== "Rejected" ? "in-progress" : ""
        }
      >
        <h6>Management Team</h6>
      </li>
    );
  }

  // Third Approval (IT Team)
  if (assetProgressData.thirdApprovalDateTime) {
    if (
      assetProgressData.trackingStatus === "Rejected" &&
      assetProgressData.fourthApprover === null
    ) {
      currentStatus = "Rejected";
    } else {
      currentStatus = "Allocated";
    }

    timeline.push(
      <li
        className={
          assetProgressData.trackingStatus === "Allocated"
            ? "approved"
            : currentStatus === "Rejected"
            ? "rejected"
            : "approved"
        }
        key="thirdApproval"
      >
        <h6>IT Team</h6>
        <p>
          <small>
            {moment(assetProgressData.thirdApprovalDateTime).format("HH:mm")}
            {".  "}
            {moment(assetProgressData.thirdApprovalDateTime).format(
              "MMMM DD, YYYY"
            )}
          </small>
        </p>
        <p>{assetProgressData.thirdApproverComment}</p>
      </li>
    );
  }
  // if (
  //   assetProgressData.thirdApprovalDateTime === null &&
  //   assetProgressData.secondApprovalDateTime &&
  //   assetProgressData.trackingStatus !== "Rejected"
  // )
  else {
    timeline.push(
      <li
        key="thirdApprovalRejected"
        className={
          assetProgressData.trackingStatus !== "Rejected" ? "in-progress" : ""
        }
      >
        <h6>IT Team</h6>
      </li>
    );
  }

  // Fourth Approval (Admin)
  if (assetProgressData.fourthApprovalDateTime) {
    if (assetProgressData.trackingStatus === "Rejected") {
      currentStatus = "Rejected";
    } else {
      currentStatus = "Delivered";
    }
    timeline.push(
      <li
        key="fourthApproval"
        className={
          assetProgressData.trackingStatus === "Delivered"
            ? "approved"
            : currentStatus === "Rejected"
            ? "rejected"
            : "approved"
        }
      >
        <h6>Store</h6>
        <p>
          <small>
            {moment(assetProgressData.fourthApprovalDateTime).format("HH:mm")}
            {".  "}
            {moment(assetProgressData.fourthApprovalDateTime).format(
              "MMMM DD, YYYY"
            )}
          </small>
        </p>
        <p>{assetProgressData.fourthApproverComment}</p>
      </li>
    );
  }
  // if (
  //   assetProgressData.fourthApprovalDateTime === null &&
  //   assetProgressData.thirdApprovalDateTime &&
  //   assetProgressData.trackingStatus !== "Rejected"
  // )
  else {
    timeline.push(
      <li
        key="fourthApprovalRejected"
        className={
          assetProgressData.trackingStatus !== "Rejected" ? "in-progress" : ""
        }
      >
        <h6>Store</h6>
      </li>
    );
  }

  // Delivery
  if (assetProgressData.trackingStatus === "Delivered") {
    timeline.push(
      <li key="delivery" className="approved">
        <h6>
          <i>Delivered</i>
        </h6>
      </li>
    );
  }

  return <>{timeline}</>;
};

export default GenerateProgress;
