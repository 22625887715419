import React, { useEffect, useState } from "react";
import {
  GET_ONBOARDING_EMPLOYEE_LIST,
  GET_ONBOARDING_EMPLOYEE_LIST_PAGINATION,
} from "../../config/Endpoints";
import Cookies from "js-cookie";
import { getApi } from "../../utils/api";
import { ThreeDots } from "react-loader-spinner";
import UpdateOnboardingView from "./UpdateOnboardingView";
import { toggleMenu } from "../../utils/helpers";
import Pagination from "../../components/Pagination";
import AssignManager from "../AssignManager/AssignManager";
import Footer from "../../components/Footer";

function UpdateEmployeeOnboarding() {
  const [employeeList, setEmployeeList] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState({});
  const accessToken = Cookies.get("accessToken");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredItems, setFilteredItems] = useState();

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    fetchApprovedUser();
  }, [currentPage, limit]);

  const fetchApprovedUser = () => {
    setisLoading(true);
    getApi(
      GET_ONBOARDING_EMPLOYEE_LIST_PAGINATION +
        `?status=PENDING&pageSize=${limit}&page=${currentPage}`,
      accessToken
    )
      .then((res) => {
        if (res.status === 200) {
          setEmployeeList(res.data.data.data);
          setFilteredItems(res.data.data.data);
          setTotalPages(res.data.data.totalPages);
          setTotalRecord(res.data.data.totalElements);
          setisLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setisLoading(false);
      });
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);
    const filtered = employeeList.filter((item) =>
      item.firstName.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredItems(filtered);
  };

  return (
    <div className="wrapper-body">
      {/* Page Heading */}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <h1>L1 Approval</h1>
        <div className="total">
          Total Employees : <span>{totalRecord}</span>
        </div>
      </div>

      {/* Filters */}
      <div className="filters">
        {/* <form action="index.html" id="form_filters" className="d-f"> */}
        {/* Search By Company */}
        <div className="search">
          <input
            type="text"
            name="search"
            placeholder="Search By Name."
            value={searchTerm}
            onChange={handleSearch}
          />
          <img src="/assets/images/search-ico.svg" width="14" alt="Search" />
        </div>

        {/* <button type="submit" form="form_filters">
            Search
          </button> */}
        {/* </form> */}

        {/* Actions */}
        <div className="actions"></div>
      </div>

      {isLoading && (
        <div className="centered-loader">
          <ThreeDots
            height="100"
            width="100"
            radius="9"
            color="blue"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}
      {!isLoading && filteredItems?.length === 0 && (
        <div className="fs-4 text-secondary text-center">
          No Record Available
        </div>
      )}
      {/* Data Table */}
      {!isLoading && filteredItems?.length > 0 && (
        <>
          <div className="table-responsive">
            <table className="resume custom">
              {/* Table Headings */}
              <thead>
                <tr>
                  <th>Serial No.</th>
                  <th>Employee Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Gender</th>
                  <th className="text-center">Actions</th>
                </tr>
              </thead>

              {/* Table Body */}
              <tbody>
                {filteredItems &&
                  filteredItems.map((item, index) => (
                    <tr key={index}>
                      <td data-title="Serial_No">{index + 1}</td>
                      <td data-title="Name">{item.firstName}</td>
                      <td data-title="Email">{item.personalEmail}</td>
                      <td data-title="Phone">{item.phoneNumber}</td>
                      <td data-title="ID">{item.gender}</td>
                      <td className="text-center">
                        <a
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdrop"
                          onClick={() => setSelectedEmployee(item)}
                        >
                          View
                        </a>
                      </td>

                      <td className="text-center"></td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div>
                {/* Dropdown for selecting items per page */}
                <label htmlFor="itemsPerPage">Items per page </label>
                <select
                  id="itemsPerPage"
                  onChange={handleLimitChange}
                  value={limit}
                  className="border ms-3 w-25  p-2"
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={30}>30</option>
                  <option value={40}>40</option>
                </select>
              </div>
            </div>
            <div className="col-md-9">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </>
      )}
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <AssignManager
              selectedEmployee={selectedEmployee}
              setSelectedEmployee={setSelectedEmployee}
              fetchList={fetchApprovedUser}
              titleHeading="L1 Approval Action"
              isEditable={false}
            />
          </div>
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default UpdateEmployeeOnboarding;
