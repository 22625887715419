import React, { useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";

const UploadDocument = () => {
  // drag state
  const [isLoading, setIsLoading] = useState(false);
  const [dragActive, setDragActive] = useState(false);
  const [files, setFiles] = useState(null);
  const [formData, setFormData] = useState({
    documentType: "",
    documentFile: null,
    comment: "",
  });
  // ref
  const inputRef = React.useRef(null);

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFiles((prevFiles) => {
        const newFile = e.dataTransfer.files[0];
        const updatedFiles = [...prevFiles, newFile];
        return updatedFiles;
      });
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      if (files !== null) {
        setFiles((prevFiles) => {
          const newFile = e.target.files[0];
          const updatedFiles = [...prevFiles, newFile];
          return updatedFiles;
        });
      } else {
        setFiles([e.target.files[0]]);
      }
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = (e) => {
    e.preventDefault();

    inputRef.current.click();
  };

  const handleRemove = (indexToRemove) => {
    setFiles((prevFiles) => {
      // Filter out the file to remove based on its index
      const updatedFiles = prevFiles.filter(
        (_, index) => index !== indexToRemove
      );
      return updatedFiles;
    });
  };

  const handleInputChange = (event) => {
    const { name, value, type } = event.target;
    if (type === "file") {
      const file = event.target.files[0];
      // Define an array of accepted file types
      const acceptedFileTypes = ["application/pdf"];

      if (file) {
        if (acceptedFileTypes.includes(file.type)) {
          // File type is supported
          setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: file,
          }));
        } else {
          // File type is not supported, show an alert
          toast.error(
            "Unsupported file type. Please select a valid  PDF file.",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          event.target.value = null; // Clear the file input
        }
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleReset = () => {
    setFormData({
      documentType: "",
      documentFile: null,
      comment: "",
    });
    document.getElementById("file-input").value = "";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // try {
    //   const formdata = new FormData();
    //   // formdata.append("docType", formData.documentType);
    //   // formdata.append("docFile", formData.documentFile);

    //   await postApi(API_EMPLOYEE_DOCUMENT, formdata, accessToken)
    //     .then((res) => {
    //       setIsLoading(false);
    //       handleReset();
    //       toast.success("Document Successfully Uploaded.", {
    //         position: toast.POSITION.TOP_RIGHT,
    //       });
    //     })
    //     .catch((e) => {
    //       setIsLoading(false);
    //       toast.error(e.response.data.message, {
    //         position: toast.POSITION.TOP_RIGHT,
    //       });
    //     });
    // } catch (error) {
    //   setIsLoading(false);
    //   toast.error("Something went wrong. Please try again!", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    // }
  };
  return (
    <div className="page">
      {/* <form
            id="form-file-upload"
            onDragEnter={handleDrag}
            // onSubmit={(e) => e.preventDefault()}
          >
            <input
              ref={inputRef}
              type="file"
              id="input-file-upload"
              multiple={true}
              onChange={handleChange}
            />
            <label
              id="label-file-upload"
              htmlFor="input-file-upload"
              className={dragActive ? "drag-active" : ""}
            >
              <div>
                <p>Drag and drop your file here or</p>
                <button
                  className="upload-button"
                  type="button"
                  onClick={onButtonClick}
                >
                  Upload a file
                </button>
              </div>
            </label>
            {dragActive && (
              <div
                id="drag-file-element"
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}
              ></div>
            )}
          </form> */}
      <button
        className="upload-button mt-5"
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdropDownload"
      >
        Upload a document
      </button>

      {files?.length > 0 && (
        <>
          {/* <h6 className="heading mt-4">Uploaded Document</h6> */}
          {/* <ul id="uploaded-documents">
            {files.map((item, index) => (
              <li key={index}>
                {item?.name}
                <span onClick={() => handleRemove(index)}>X</span>
              </li>
            ))}
          </ul> */}
          <table className="resume custom mt-5">
            {/* Table Headings */}
            <thead>
              <tr>
                <th>Serial No.</th>
                <th>Document Name</th>
                <th>Document Type</th>
                <th>Action</th>
              </tr>
            </thead>

            {/* Table Body */}
            <tbody>
              {files &&
                files?.map((item, index) => (
                  <tr key={index}>
                    <td data-title="Serial_No">{index + 1}</td>
                    <td data-title="name"> {item?.name}</td>
                    <td data-title="docType">{item.docType}</td>
                    <td data-title="docAction">
                      <span
                        className="text-danger fw-5"
                        onClick={() => handleRemove(index)}
                      >
                        X
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </>
      )}
      {files?.length === 0 && (
        <div className="text-center text-muted mt-5">
          No document uploaded yet!
        </div>
      )}

      <div
        className="modal fade"
        id="staticBackdropDownload"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <form onSubmit={handleSubmit}>
              <div className="modal-header">
                <h5
                  className="modal-title text-center flex"
                  id="staticBackdropLabel"
                >
                  Upload Document
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleReset}
                ></button>
              </div>
              <div className="modal-body">
                <div className="row  mt-1">
                  <div className="col-md-2"></div>
                  <div className="col-md-4">
                    <label htmlFor="documentType" className="has-asterisk">
                      Document Type
                    </label>
                  </div>
                  <div className="col-md-4">
                    <div className="input-group">
                      <select
                        id="documentType"
                        required
                        name="documentType"
                        value={formData.documentType}
                        onChange={handleInputChange}
                        className="border"
                      >
                        <option value="">Select Document Type</option>
                        <option value="INSURANCECARD">Insurance Card</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-2"></div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-2"></div>
                  <div className="col-md-4">
                    <label htmlFor="document">Document*</label>
                  </div>
                  <div className="col-md-4 ">
                    <input
                      type="file"
                      name="offerLetter"
                      id="file-input"
                      onChange={handleChange}
                      accept=".doc,.docx,.pdf,application/pdf"
                      required
                      className="border"
                    />
                    <small className="text-danger pt-0">
                      Note: Please upload <strong>.pdf</strong> file only.
                    </small>
                  </div>
                  <div className="col-md-2"></div>
                </div>
              </div>

              <div className="justify-content-center modal-footer">
                <button
                  type="button"
                  className={
                    isLoading ? "theme-button disabled " : "theme-button "
                  }
                  onClick={handleReset}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  {isLoading ? (
                    <ThreeDots
                      height="20"
                      width="53"
                      radius="9"
                      color="white"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    "Submit"
                  )}
                </button>
                <button
                  type="button"
                  className="theme-button bg-grey mx-3 w-35 cancel-modal"
                  data-bs-dismiss="modal"
                  onClick={handleReset}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadDocument;
