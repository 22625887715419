import React, { useEffect, useRef, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import { API_EXPERIENCE } from "../../config/Endpoints";
import Cookies from "js-cookie";
import { postApi, putApi } from "../../utils/api";
import * as Yup from "yup";

const AddWorkExperience = ({
  setExperienceList,
  selectedWork,
  setSelectedWork,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const accessToken = Cookies.get("accessToken");

  const formRef = useRef(null);

  const [formData, setFormData] = useState({
    title: "",
    company: "",
    from: "",
    to: "",
    jobDescription: "",
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (event) => {
    const { name, value, type } = event.target;
    if (type === "date") {
      const year = value.split("-")[0];
      if (year.length <= 4) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
    setErrors((prevFormData) => ({
      ...prevFormData,
      [name]: "",
    }));
  };

  const handleAdd = async (e) => {
    e.preventDefault();
    try {
      await WorkExperienceSchema.validate(
        { from: formData.from, to: formData.to },
        { abortEarly: false }
      );
      const requestData = {
        from: formData.from,
        jobDescription: formData.jobDescription,
        jobTitle: formData.title,
        previousCompany: formData.company,
        to: formData.to,
      };
      await postApi(API_EXPERIENCE, requestData, accessToken)
        .then((res) => {
          if (res?.data?.data) {
            setExperienceList(res.data.data);
          }
          setIsLoading(false);
          const modalClose =
            document.getElementsByClassName("cancel-modal-work")[0];
          modalClose.click();
          toast.success("Experience successfully added!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
        .catch((e) => {
          toast.error("Something went wrong please try again !", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsLoading(false);
        });
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });
      setErrors(validationErrors);
      setIsLoading(false);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      await WorkExperienceSchema.validate(
        { from: formData.from, to: formData.to },
        { abortEarly: false }
      );
      const requestData = {
        from: formData.from,
        jobDescription: formData.jobDescription,
        jobTitle: formData.title,
        previousCompany: formData.company,
        to: formData.to,
      };
      await putApi(
        API_EXPERIENCE + `/${selectedWork?.experienceId}`,
        requestData,
        accessToken
      )
        .then((res) => {
          if (res?.data?.data) {
            setExperienceList(res.data.data);
          }
          setIsLoading(false);
          const modalClose =
            document.getElementsByClassName("cancel-modal-work")[0];
          modalClose.click();
          toast.success("Experience successfully updated!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
        .catch((e) => {
          toast.error("Something went wrong please try again !", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsLoading(false);
        });
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });
      setErrors(validationErrors);
      setIsLoading(false);
    }
  };

  const handleManualSubmit = (e) => {
    e.preventDefault();
    const form = formRef.current;
    if (form.checkValidity()) {
      setIsLoading(true);
      if (selectedWork?.experienceId) {
        handleUpdate(e);
      } else {
        handleAdd(e);
      }
    } else {
      form.reportValidity();
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (Object.keys(selectedWork).length !== 0) {
      setFormData({
        title: selectedWork?.jobTitle,
        company: selectedWork?.previousCompany,
        from: selectedWork?.fromDate,
        to: selectedWork?.toDate,
        jobDescription: selectedWork?.jobDescription,
      });
      const modalClose = document.getElementsByClassName("add-experience")[0];
      modalClose.click();
    }
  }, [selectedWork?.experienceId]);

  const handleReset = () => {
    setFormData({
      title: "",
      company: "",
      from: "",
      to: "",
      jobDescription: "",
    });
    setSelectedWork({});
    setErrors({});
  };

  // Get today's date in the format yyyy-mm-dd
  const today = new Date().toISOString().split("T")[0];

  return (
    <form ref={formRef}>
      <div className="modal-header">
        <h5 className="modal-title text-center flex" id="staticBackdropLabel">
          {selectedWork?.experienceId ? "Update Experience" : "Add Experience"}
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleReset}
        ></button>
      </div>
      <div className="modal-body">
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <label htmlFor="title" className="has-asterisk">
              Job Title
            </label>
          </div>

          <div className="col-md-4">
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              required
              maxLength={100}
              className="border"
              onKeyPress={(e) => {
                if (e.charCode === 32 && e.target.selectionStart === 0) {
                  e.preventDefault(); // Prevent adding space at the beginning
                }
              }}
            />
          </div>
          <div className="col-md-2"></div>
        </div>
        <div className="row mt-3">
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <label htmlFor="company" className="has-asterisk">
              Company
            </label>
          </div>
          <div className="col-md-4 ">
            <input
              type="text"
              name="company"
              value={formData.company}
              onChange={handleInputChange}
              maxLength={100}
              required
              className="border"
              onKeyPress={(e) => {
                if (e.charCode === 32 && e.target.selectionStart === 0) {
                  e.preventDefault(); // Prevent adding space at the beginning
                }
              }}
            />
          </div>
          <div className="col-md-2"></div>
        </div>
        <div className="row mt-3">
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <label htmlFor="from" className="has-asterisk">
              From
            </label>
          </div>
          <div className="col-md-4 ">
            <input
              type="date"
              required
              name="from"
              value={formData.from}
              placeholder="08/02/2019"
              onChange={handleInputChange}
              className="border"
              max={today}
            />
            {errors.from && (
              <small className="text-danger">{errors.from}</small>
            )}
          </div>
          <div className="col-md-2"></div>
        </div>
        <div className="row mt-3">
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <label htmlFor="to" className="has-asterisk">
              To
            </label>
          </div>
          <div className="col-md-4 ">
            <input
              type="date"
              required
              name="to"
              value={formData.to}
              placeholder="08/02/2019"
              onChange={handleInputChange}
              className="border"
              max={today}
            />
            {errors.to && <small className="text-danger">{errors.to}</small>}
          </div>
          <div className="col-md-2"></div>
        </div>

        <div className="row mt-3">
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <label htmlFor="jobDescription" className="has-asterisk">
              Job Description
            </label>
          </div>
          <div className="col-md-4 ">
            <textarea
              id="jobDescription"
              name="jobDescription"
              required
              value={formData.jobDescription}
              onChange={handleInputChange}
              rows={3}
              cols={20}
              maxLength={254}
              className="border"
              onKeyPress={(e) => {
                if (e.charCode === 32 && e.target.selectionStart === 0) {
                  e.preventDefault(); // Prevent adding space at the beginning
                }
              }}
              onPaste={(e) => {
                e.preventDefault();
              }}
            ></textarea>
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>

      <div className="justify-content-center modal-footer">
        <button
          type="button"
          className={isLoading ? "theme-button disabled " : "theme-button "}
          onClick={handleManualSubmit}
        >
          {isLoading ? (
            <ThreeDots
              height="20"
              width="53"
              radius="9"
              color="white"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          ) : (
            "Submit"
          )}
        </button>
        <button
          type="button"
          className="theme-button bg-grey mx-3 w-35 cancel-modal-work"
          data-bs-dismiss="modal"
          onClick={handleReset}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default AddWorkExperience;

const WorkExperienceSchema = Yup.object().shape({
  from: Yup.date().required("From Date is required"),
  to: Yup.date()
    .required("To Date is required")
    .when("from", (from, schema) => {
      return schema.test({
        test: (to) => {
          return new Date(to) >= new Date(from);
        },
        message: "'To Date' must be later than or equal to 'From Date'",
      });
    }),
});
