import { useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { API_UPDATE_EMPLOYEE_ACCESS } from "../../config/Endpoints";
import { putApi } from "../../utils/api";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const EmployeeAccess = ({ userData }) => {
  const [isLoading, setIsLoading] = useState();
  const [status, setStatus] = useState("");
  const accessToken = Cookies.get("accessToken");
  const [managerId, setManagerId] = useState("");
  const [leftoutDate, setLeftoutDate] = useState("");

  const [designationId, setDesignationId] = useState("");
  const [reason, setReason] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (status !== "") {
        setIsLoading(true);

        const URL =
          status === "LEFTOUT"
            ? `${API_UPDATE_EMPLOYEE_ACCESS}?employeeId=${userData?.id}&status=${status}&leftOutReason=${reason}&leftOutDate=${leftoutDate}`
            : `${API_UPDATE_EMPLOYEE_ACCESS}?employeeId=${userData?.id}&status=${status}`;

        await putApi(URL, {}, accessToken)
          .then((res) => {
            if (res.status === 200) {
              setIsLoading(false);
              toast.success(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              navigate("/dashboard/employee-list");
            }
          })
          .catch((e) => {
            setIsLoading(false);
            toast.error(e?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            console.log(e);
          });
      } else {
        toast.error("Please select status field.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      toast.error(e?.response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });

      setIsLoading(false);
      console.log(error);
    }
  };

  const handleChangeStatus = (e) => {
    setStatus(e.target.value);
  };

  const handleInputChange = (event) => {
    const { value, type } = event.target;
    if (type === "date") {
      const year = value.split("-")[0];

      if (year.length <= 4) {
        setLeftoutDate(value);
      }
    } else {
      setLeftoutDate(value);
    }
  };

  // Get today's date in the format yyyy-mm-dd
  const today = new Date().toISOString().split("T")[0];
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="modal-body">
          <div className="row mt-3">
            <div className="col-md-2"></div>
            <div className="col-md-3">
              <label htmlFor="managerName">Current Status</label>
            </div>
            <div className="col-md-4">
              <input
                type="text"
                name="status"
                className="border"
                value={userData?.status}
                readOnly
              />
            </div>
            <div className="col-md-2"></div>
          </div>
          <div className="row mt-3">
            <div className="col-md-2"></div>
            <div className="col-md-3">
              <label htmlFor="status" className="has-asterisk">
                Status
              </label>
            </div>
            <div className="col-md-4">
              <select
                id="status"
                className="border"
                required
                name="status"
                value={status}
                onChange={handleChangeStatus}
              >
                <option value="">Select Status</option>
                <option value="VERIFIED">VERIFIED</option>
                <option value="BLOCKED">BLOCKED (Temporally Disable)</option>
                <option value="LEFTOUT">LEFTOUT (Permanently Disable)</option>
              </select>
            </div>
            <div className="col-md-2"></div>
          </div>
          {status === "LEFTOUT" && (
            <>
              <div className="row mt-3">
                <div className="col-md-2"></div>
                <div className="col-md-3">
                  <label htmlFor="status" className="has-asterisk">
                    Leftout Date
                  </label>
                </div>
                <div className="col-md-4">
                  <input
                    type="date"
                    required
                    name="dob"
                    className="border"
                    value={leftoutDate}
                    placeholder="08/02/2019"
                    onChange={handleInputChange}
                    max={today}
                  />
                </div>
                <div className="col-md-2"></div>
              </div>
              <div className="row mt-3">
                <div className="col-md-2"></div>
                <div className="col-md-3">
                  <label htmlFor="status" className="has-asterisk">
                    Leftout Reason
                  </label>
                </div>
                <div className="col-md-4">
                  <textarea
                    cols={15}
                    rows={5}
                    className="border"
                    maxLength={225}
                    required
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    name="comment"
                    minLength={5}
                    onKeyPress={(e) => {
                      if (e.charCode === 32 && e.target.selectionStart === 0) {
                        e.preventDefault(); // Prevent adding space at the beginning
                      }
                    }}
                  ></textarea>
                </div>
                <div className="col-md-2"></div>
              </div>
            </>
          )}
        </div>
        <div className="justify-content-center modal-footer">
          <button
            type="submit"
            // onClick={handleSubmit}
            className={isLoading ? "theme-button disabled " : "theme-button "}
          >
            {isLoading ? (
              <ThreeDots
                height="20"
                width="53"
                radius="9"
                color="white"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            ) : (
              "Submit"
            )}
          </button>
          {/* <button
          type="button"
          className="theme-button bg-grey mx-3 w-35 cancel-promote"
          data-bs-dismiss="modal"
          //   onClick={handleClose}
        >
          Cancel
        </button> */}
        </div>
      </form>
    </>
  );
};

export default EmployeeAccess;
