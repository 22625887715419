import { useEffect, useState } from "react";
import { API_HOLIDAY } from "../../config/Endpoints";
import { getApi } from "../../utils/api";
import Cookies from "js-cookie";
import { generateYearAll, toggleMenu } from "../../utils/helpers";
import { ThreeDots } from "react-loader-spinner";
import Footer from "../../components/Footer";

const HolidayList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [holidayList, setHolidayList] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());
  const [yearList] = useState(generateYearAll(2024));
  const accessToken = Cookies.get("accessToken");
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "",
  });
  useEffect(() => {
    fetchListHoliday();
  }, [year]);

  const fetchListHoliday = () => {
    setIsLoading(true);
    getApi(API_HOLIDAY + `?year=${year}&status=approved`, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setHolidayList(res.data.data.data);
          setFilteredItems(res.data.data.data);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
    const sortedData = [...filteredItems].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setFilteredItems(sortedData);
  };

  return (
    <div className="wrapper-body">
      {/* Page Heading */}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <h1>Holiday List</h1>

        <div className="total">
          Total Holiday : <span>{holidayList?.length}</span>
        </div>
      </div>
      {/* Filters */}
      <div className="filters">
        {/* Search By Year */}
        <div className="search">
          <select
            id="year"
            required
            name="year"
            value={year}
            onChange={(e) => setYear(e.target.value)}
          >
            {yearList &&
              yearList.map((item) => (
                <option value={item} key={item}>
                  {item}
                </option>
              ))}
          </select>
        </div>
      </div>

      {/* Data Table */}
      {!isLoading && filteredItems?.length > 0 && (
        <>
          <div className="table-responsive">
            <table className="resume custom">
              {/* Table Headings */}
              <thead>
                <tr>
                  <th onClick={() => handleSort("id")}>
                    ID
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                  <th onClick={() => handleSort("nameOfHoliday")}>
                    Holiday
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                  <th onClick={() => handleSort("year")}>
                    Year
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                  <th onClick={() => handleSort("date")}>
                    Date
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                </tr>
              </thead>

              {/* Table Body */}
              <tbody>
                {filteredItems?.map((item, index) => (
                  <tr key={index}>
                    <td>{item.id}</td>
                    <td>{item.nameOfHoliday}</td>
                    <td>{item.year}</td>
                    <td>{item.date}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
      {!isLoading && filteredItems?.length === 0 && (
        <div className="fs-4 text-secondary text-center">
          No Record Available
        </div>
      )}
      {isLoading && (
        <div className="centered-loader">
          <ThreeDots
            height="100"
            width="100"
            radius="9"
            color="blue"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default HolidayList;
