import React, { useState } from "react";
import { generateYear } from "../../utils/helpers";
import { API_DOWNLOAD_SALARY, API_SALARY } from "../../config/Endpoints";
import { getApi } from "../../utils/api";
import Cookies from "js-cookie";
import { getUser } from "../../utils/Storage";

const Salary = () => {
  const [yearList] = useState(generateYear(2020));
  const [selectedYear, setSelectedYear] = useState("");
  const [salaryList, setSalaryList] = useState([]);
  const accessToken = Cookies.get("accessToken");
  const userData = JSON.parse(getUser("userData"));

  const handleYearChnage = (e) => {
    setSelectedYear(e.target.value);
    let value = e.target.value;
    getApi(API_SALARY + "?year=" + value, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setSalaryList(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleDownload = (e, item) => {
    e.preventDefault();
    fetch(
      `${API_DOWNLOAD_SALARY}?fileName=${item.salarySlipPath}&salaryId=${item.id}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        // Create a URL object from the blob
        const url = URL.createObjectURL(blob);

        // Create a temporary link element to trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.download = `SalarySlip_${userData?.name}_${item.month}_${item.year}.pdf`;
        link.click();

        // Clean up the URL object
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  };
  return (
    <>
      <div className="row mt-4">
        <div className="col-md-2">
          <select
            id="year"
            required
            name="yearSalary"
            onChange={handleYearChnage}
          >
            <option value="">Select Year</option>
            {yearList &&
              yearList.map((item) => (
                <option value={item} key={item}>
                  {item}
                </option>
              ))}
          </select>
        </div>
        <div className="col-md-8"></div>
      </div>
      {salaryList.length === 0 && salaryList && (
        <div className="fs-4 m-5 text-secondary text-center">
          No records available
        </div>
      )}
      {salaryList.length > 0 && (
        <div className="row mt-4">
          <div className="col-md-12">
            <div className="table-responsive">
              <table className="resume custom">
                {/* Table Headings */}
                <thead>
                  <tr>
                    <th>Month</th>
                    <th>Year</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>

                {/* Table Body */}
                <tbody>
                  {salaryList.map((item, index) => (
                    <tr key={index}>
                      <td>{item.month}</td>
                      <td>{item.year}</td>
                      <td>Paid</td>
                      <td>
                        <a
                          href="javascript:void(0)"
                          download
                          onClick={(e) => handleDownload(e, item)}
                        >
                          Download
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Salary;
