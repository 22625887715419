import React, { Component, useEffect } from "react";
import { toggleMenu } from "../../utils/helpers";
import Footer from "../../components/Footer";

const Timesheets = () => {
  return (
    <div className="wrapper-body">
      {/* Page Heading */}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <h1>Timesheets</h1>
        <div className="total">
          Total Invoice : <span>7</span>
        </div>
      </div>

      {/* Filters */}
      <div className="filters">
        {/* <form action="index.html" id="form_filters" className="d-f"> */}
        {/* Search By Company */}
        <div className="search">
          <input
            type="text"
            name="search"
            placeholder="Search By Name, Project. etc."
          />
          <img src="/assets/images/search-ico.svg" width="14" alt="Search" />
        </div>

        {/* Select From Date */}
        <div className="search" style={{ maxWidth: "200px" }}>
          <input type="date" name="from_date" placeholder="From Date" />
        </div>

        {/* Select To Date */}
        <div className="search" style={{ maxWidth: "200px" }}>
          <input type="date" name="to_date" placeholder="To Date" />
        </div>

        <button type="Submit" form="form_filters">
          Search
        </button>
        {/* </form> */}

        {/* Actions */}
        <div className="actions">
          <a href="javascript:void(0)" className="export theme-button">
            Export &nbsp;&nbsp;
            <img
              src="/assets/images/export-ico.svg"
              style={{ filter: "invert(1)" }}
              width="15"
              alt="Export"
            />
          </a>
        </div>
      </div>

      {/* Data Table */}
      <div className="table-responsive">
        <table className="resume custom">
          {/* Table Headings */}
          <thead>
            <tr>
              <th>Employee Name</th>
              <th>Project</th>
              <th className="text-center">Days/Week</th>
              <th className="text-center">Hours/Week</th>
              <th className="text-center">Total Hours</th>
              <th className="text-center">Actions</th>
            </tr>
          </thead>

          {/* Table Body */}
          <tbody>
            <tr>
              <td>Anubhav Mangla</td>
              <td>Airbnb Catalyst Optimize</td>
              <td className="text-center days">
                <span>S</span>
                <span className="yes">M</span>
                <span className="yes">T</span>
                <span>W</span>
                <span>Th</span>
                <span>F</span>
                <span>S</span>
              </td>
              <td className="text-center">38</td>
              <td className="text-center">159</td>
              <td className="text-center">
                <a href="/dashboard/timeSheetView">View</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* footer */}
      <Footer />
    </div>
  );
};

export default Timesheets;
