import React from "react";
import { getCurrentYear } from "../utils/helpers";

const Footer = () => {
  return (
    <>
      {/* Footer */}
      <p className="copyright">
        © {getCurrentYear()} Caelius Consulting INC – All Right Reserved
      </p>
    </>
  );
};

export default Footer;
