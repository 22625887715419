import React, { useState } from "react";

const TruncateText = ({ text }) => {
  const [isHovered, setIsHovered] = useState(false);

  const truncatedText = text.length > 25 ? text.substring(0, 25) + "..." : text;

  return (
    <div
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
      style={{ position: "relative" }}
    >
      <span>{truncatedText}</span>
      {isHovered && text.length > 25 && (
        <div
          style={{
            position: "absolute",
            backgroundColor: "white",
            border: "1px solid black",
            padding: "5px",
            zIndex: 1,
          }}
        >
          {text}
        </div>
      )}
    </div>
  );
};

export default TruncateText;
