import React, { useEffect, useRef, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import { API_EMPLOYEE_DEPENDENT } from "../../config/Endpoints";
import Cookies from "js-cookie";
import { postApi, putApi } from "../../utils/api";

const AddDependent = ({
  setDependentList,
  selectedDependent,
  setSelectedDependent,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const accessToken = Cookies.get("accessToken");

  const formRef = useRef(null);

  const [formData, setFormData] = useState({
    dob: "",
    gender: "",
    name: "",
    number: "",
    relationship: "",
  });

  const handleInputChange = (event) => {
    const { name, value, type } = event.target;
    if (type === "date") {
      const year = value.split("-")[0];

      if (year.length <= 4) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleAdd = async (e) => {
    e.preventDefault();
    try {
      const requestData = {
        name: formData.name,
        number: formData.number,
        relationship: formData.relationship,
        dob: formData.dob,
        gender: formData.gender,
      };

      await postApi(API_EMPLOYEE_DEPENDENT, requestData, accessToken)
        .then((res) => {
          if (res?.data?.data) {
            setDependentList(res.data.data);
          }
          setIsLoading(false);

          const modalClose = document.getElementsByClassName(
            "cancel-modal-dependent"
          )[0];
          modalClose.click();

          toast.success("Dependent successfully added!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
        .catch((e) => {
          toast.error("Something went wrong please try again !", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsLoading(false);
        });
    } catch (error) {
      toast.error("Something went wrong please try again !", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsLoading(false);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const requestData = {
        name: formData.name,
        number: formData.number,
        relationship: formData.relationship,
        dob: formData.dob,
        gender: formData.gender,
      };

      await putApi(
        API_EMPLOYEE_DEPENDENT + `/${selectedDependent?.dependentId}`,
        requestData,
        accessToken
      )
        .then((res) => {
          if (res?.data?.data) {
            setDependentList(res.data.data);
          }
          setIsLoading(false);

          const modalClose = document.getElementsByClassName(
            "cancel-modal-dependent"
          )[0];
          modalClose.click();
          toast.success("Dependent successfully updated!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
        .catch((e) => {
          toast.error("Something went wrong please try again !", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsLoading(false);
        });
    } catch (error) {
      toast.error("Something went wrong please try again !", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsLoading(false);
    }
  };

  const handleManualSubmit = (e) => {
    e.preventDefault();
    const form = formRef.current;
    if (form.checkValidity()) {
      setIsLoading(true);
      let checkRelation = handleRelation();
      if (checkRelation) {
        if (selectedDependent?.dependentId) {
          handleUpdate(e);
        } else {
          handleAdd(e);
        }
      } else {
        console.log("add dependent", checkRelation);
      }
    } else {
      form.reportValidity();
      setIsLoading(false);
    }
  };

  const handleRelation = () => {
    const { gender, relationship } = formData;

    // Define the valid relations for each gender
    const validRelations = {
      male: ["father", "husband"],
      female: ["mother", "wife"],
      other: ["mother", "father", "husband", "wife"],
    };

    // Check if the selected relation is valid for the selected gender
    return (
      validRelations[gender] && validRelations[gender].includes(relationship)
    );
  };

  const validRelations = {
    male: ["father", "husband"],
    female: ["mother", "wife"],
    other: ["mother", "father", "husband", "wife"],
  };

  useEffect(() => {
    if (Object.keys(selectedDependent).length !== 0) {
      setFormData({
        dob: selectedDependent?.dob,
        gender: selectedDependent?.gender,
        name: selectedDependent?.name,
        number: selectedDependent?.number,
        relationship: selectedDependent?.relationship,
      });
      const modalClose = document.getElementsByClassName("add-dependent")[0];
      modalClose.click();
    }
  }, [selectedDependent?.dependentId]);

  const handleReset = () => {
    setFormData({
      dob: "",
      gender: "",
      name: "",
      number: "",
      relationship: "",
    });
    setSelectedDependent({});
  };
  // Get today's date in the format yyyy-mm-dd
  const today = new Date().toISOString().split("T")[0];
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <form ref={formRef}>
      <div className="modal-header">
        <h5 className="modal-title text-center flex" id="staticBackdropLabel">
          {selectedDependent?.dependentId
            ? "Update Dependent"
            : "Add Dependent"}
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleReset}
        ></button>
      </div>
      <div className="modal-body">
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <label htmlFor="name" className="has-asterisk">
              Name
            </label>
          </div>

          <div className="col-md-4">
            <input
              type="text"
              name="name"
              value={formData.name}
              maxLength={100}
              onChange={handleInputChange}
              required
              className="border"
              onKeyPress={(e) => {
                const inputValue = String.fromCharCode(e.charCode);
                if (
                  (e.charCode === 32 && e.target.selectionStart === 0) ||
                  inputValue.match(/^\d+$/)
                ) {
                  e.preventDefault(); // Prevent adding space at the beginning  of the string or numbers only
                }
                // Check for special characters
                if (!/^[a-zA-Z0-9]+$/.test(inputValue)) {
                  e.preventDefault(); // Prevent adding special characters
                }
              }}
            />
          </div>
          <div className="col-md-2"></div>
        </div>
        <div className="row mt-3">
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <label htmlFor="gender" className="has-asterisk">
              Gender
            </label>
          </div>
          <div className="col-md-4 ">
            <select
              id="gender"
              name="gender"
              value={formData.gender}
              onChange={handleInputChange}
              className="border"
              required
            >
              <option value="">Select Gender</option>
              {Object.keys(validRelations).map((key, index) => (
                <option value={key} key={index}>
                  {capitalizeFirstLetter(key)}
                </option>
              ))}
              {/* <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option> */}
            </select>
          </div>
          <div className="col-md-2"></div>
        </div>
        <div className="row mt-3">
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <label htmlFor="relationship" className="has-asterisk">
              Relation
            </label>
          </div>
          <div className="col-md-4 ">
            <select
              id="relationship"
              name="relationship"
              value={formData.relationship}
              onChange={handleInputChange}
              className="border"
              required
            >
              <option value="">Select Relation</option>
              {formData.gender &&
                validRelations[formData.gender].map((relation, index) => (
                  <option key={index} value={relation}>
                    {capitalizeFirstLetter(relation)}
                  </option>
                ))}
            </select>
          </div>
          <div className="col-md-2"></div>
        </div>

        <div className="row mt-3">
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <label htmlFor="number" className="has-asterisk">
              Phone Number
            </label>
          </div>
          <div className="col-md-4 ">
            <input
              type="tel"
              required
              name="number"
              minLength="10"
              maxLength="10"
              value={formData.number}
              onChange={handleInputChange}
              placeholder="Phone Number"
              className="border"
              onKeyPress={(e) => {
                const regex = /^[0-9\b]+$/;
                if (!regex.test(e.key)) {
                  e.preventDefault();
                }
              }}
            />
          </div>
          <div className="col-md-2"></div>
        </div>
        <div className="row mt-3">
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <label htmlFor="dob" className="has-asterisk">
              DOB
            </label>
          </div>
          <div className="col-md-4 ">
            <input
              type="date"
              required
              name="dob"
              value={formData.dob}
              placeholder="08/02/2019"
              onChange={handleInputChange}
              className="border"
              max={today}
            />
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>

      <div className="justify-content-center modal-footer">
        <button
          type="button"
          className={isLoading ? "theme-button disabled " : "theme-button "}
          onClick={handleManualSubmit}
        >
          {isLoading ? (
            <ThreeDots
              height="20"
              width="53"
              radius="9"
              color="white"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          ) : (
            "Submit"
          )}
        </button>
        <button
          type="button"
          className="theme-button bg-grey mx-3 w-35 cancel-modal-dependent"
          data-bs-dismiss="modal"
          onClick={handleReset}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default AddDependent;
