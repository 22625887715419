import React from "react";
import img from "../../Styles/images/profile3.png";
import { useNavigate } from "react-router-dom";

const EmployeeCard = ({ employee }) => {
  const navigate = useNavigate();

  const handleProfile = (e) => {
    e.preventDefault();
    navigate("/dashboard/employeeProfile", {
      state: {
        data: employee?.id,
      },
    });
  };
  return (
    <div
      className="card"
      style={{ position: "relative" }}
      onClick={handleProfile}
    >
      <div className="user-type-tag">
        <span
          className={`badge bg-${
            employee.employeeType === "REGULAR" ? "success" : "primary"
          }`}
        >
          {employee.employeeType}
        </span>
      </div>

      <img
        src={`${
          !employee?.photoPath.includes("photoDir")
            ? "../assets/profile.png"
            : process.env.REACT_APP_IMAGE_URL + employee?.photoPath
        }`}
        className="round-profile m-auto"
        width={180}
        height={180}
        alt={employee.firstName}
      />

      <div className="card-body text-center px-0">
        <h6 className="card-title text-primary">{employee.empName}</h6>
        <h6 className="card-text">
          <small>{employee.designation}</small>
        </h6>
        <small className="card-text">{employee.department}</small>
      </div>
    </div>
  );
};

export default EmployeeCard;
