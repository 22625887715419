import React, { useEffect, useState } from "react";
import { ASSET_TYPE_LIST, CREATE_ASSET } from "../../config/Endpoints";
import { getApi, postApi } from "../../utils/api";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import { TailSpin, ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import { toggleMenu } from "../../utils/helpers";
import Footer from "../../components/Footer";

const CreateAsset = () => {
  const [assetTypeList, setAssetTypeList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isListLoading, setIsListLoading] = useState(false);
  const [isLaptop, setIsLaptop] = useState(false);
  const accessToken = Cookies.get("accessToken");
  const [formData, setFormData] = useState({
    selectedAssetId: "",
    serialNumber: "",
    comment: "",
    manufacture: "",
    model: "",
    owner: "",
    purchaseDate: "",
    warrantyExpired: "",
    processor: "",
    ram: "",
    ssd: "",
  });

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      let requestData = {
        assestTypeId: formData.selectedAssetId,
        comments: formData.comment,
        faultOrUpgradation: "",
        manufacture: formData.manufacture,
        model: formData.model,
        owner: formData.owner,
        processor: formData.processor,
        purchaseDate: formData.purchaseDate,
        ram: formData.ram,
        serialNumber: formData.serialNumber,
        ssd: formData.ssd,
        warrantyExpired: formData.warrantyExpired,
      };
      await postApi(CREATE_ASSET, requestData, accessToken)
        .then((res) => {
          setIsLoading(false);
          setIsLaptop(false);
          setFormData({
            selectedAssetId: "",
            serialNumber: "",
            comment: "",
            manufacture: "",
            model: "",
            owner: "",
            purchaseDate: "",
            warrantyExpired: "",
            processor: "",
            ram: "",
            ssd: "",
          });
          toast.success("Successfully Created!", {
            position: toast.POSITION.TOP_RIGHT,
          });
          navigate("/dashboard/assetAllocation");
        })
        .catch((e) => {
          toast.error(e?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsLoading(false);
        });
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAssetTypeList();
  }, []);
  const fetchAssetTypeList = async () => {
    setIsListLoading(true);
    await getApi(ASSET_TYPE_LIST, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setAssetTypeList(res.data.data);
          setIsListLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsListLoading(false);
      });
  };

  const handleInputChange = (event) => {
    const { name, value, type } = event.target;
    if (type === "date") {
      const year = value.split("-")[0];
      if (year.length <= 4) {
        const currentDate = new Date().toISOString().split("T")[0];
        // If the entered date is greater than the current date, revert to the current date
        if (value > currentDate) {
          toast.error(
            "Future dates are not allowed. Input reverted to current date.",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: currentDate,
          }));
        } else {
          setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
          }));
        }
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
    if (name === "selectedAssetId") {
      let dummyData = assetTypeList.filter(
        (item) => `${item.assestId}` === value
      )[0];
      setIsLaptop(dummyData?.ram);
    }
  };

  // Function to get the current date in the format "YYYY-MM-DD"
  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  return (
    <div className="wrapper-body">
      {isListLoading && (
        <div className="overlay">
          <div className="mt-5">
            <TailSpin
              height="100"
              width="100"
              radius="1"
              color="blue"
              ariaLabel="tail-spin-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}

      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <h1>Create Asset</h1>
      </div>
      <form id="assign-asset" onSubmit={handleSubmit}>
        <div className="card">
          <h3 className="heading">Create Asset</h3>
          <div className="inputs">
            <div className="d-f f-w inputs gap-15">
              <div className="input-group">
                <label htmlFor="asset_list" className="has-asterisk">
                  Asset Type
                </label>
                <select
                  id="asset_list"
                  value={formData.selectedAssetId}
                  required
                  onChange={handleInputChange}
                  name="selectedAssetId"
                >
                  <option value="">Select an asset type</option>
                  {assetTypeList &&
                    assetTypeList.map((item) => (
                      <option value={item.assestId} key={item.assestId}>
                        {item.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="input-group">
                <label htmlFor="model" className="has-asterisk">
                  Model
                </label>
                <input
                  type="text"
                  name="model"
                  onKeyPress={(e) => {
                    if (e.charCode === 32 && e.target.selectionStart === 0) {
                      e.preventDefault(); // Prevent adding space at the beginning
                    }
                  }}
                  value={formData.model}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="input-group">
                <label htmlFor="owner" className="has-asterisk">
                  Owner
                </label>
                <select
                  id="owner"
                  value={formData.owner}
                  required
                  onChange={handleInputChange}
                  name="owner"
                >
                  <option value="">Select an owner</option>
                  <option value="Caelius">Caelius</option>
                  <option value="Salesforce">Salesforce</option>
                  <option value="Consultant">Consultant</option>
                  <option value="SQE">SQE Labs</option>
                </select>
              </div>
            </div>

            <div className="d-f f-w inputs gap-15">
              <div className="input-group">
                <label htmlFor="manufacture" className="has-asterisk">
                  Manufacture
                </label>
                <input
                  type="text"
                  name="manufacture"
                  value={formData.manufacture}
                  onChange={handleInputChange}
                  required
                  onKeyPress={(e) => {
                    if (e.charCode === 32 && e.target.selectionStart === 0) {
                      e.preventDefault(); // Prevent adding space at the beginning
                    }
                  }}
                />
              </div>
              <div className="input-group">
                <label htmlFor="serialNumber" className="has-asterisk">
                  Serial Number
                </label>
                <input
                  type="text"
                  name="serialNumber"
                  value={formData.serialNumber}
                  onChange={handleInputChange}
                  required
                  onKeyPress={(e) => {
                    if (e.charCode === 32 && e.target.selectionStart === 0) {
                      e.preventDefault(); // Prevent adding space at the beginning
                    }
                  }}
                />
              </div>
              <div className="input-group">
                <label htmlFor="warranty">Warranty</label>
                <input
                  type="text"
                  name="warrantyExpired"
                  value={formData.warrantyExpired}
                  onChange={handleInputChange}
                  onKeyPress={(e) => {
                    if (e.charCode === 32 && e.target.selectionStart === 0) {
                      e.preventDefault(); // Prevent adding space at the beginning
                    }
                  }}
                />
              </div>
            </div>

            {isLaptop && (
              <div className="d-f f-w inputs gap-15">
                <div className="input-group">
                  <label htmlFor="processor" className="has-asterisk">
                    Processor
                  </label>
                  <input
                    type="text"
                    name="processor"
                    value={formData.processor}
                    onChange={handleInputChange}
                    required
                    onKeyPress={(e) => {
                      if (e.charCode === 32 && e.target.selectionStart === 0) {
                        e.preventDefault(); // Prevent adding space at the beginning
                      }
                    }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="ram" className="has-asterisk">
                    RAM
                  </label>
                  <input
                    type="text"
                    name="ram"
                    value={formData.ram}
                    onChange={handleInputChange}
                    required
                    onKeyPress={(e) => {
                      if (e.charCode === 32 && e.target.selectionStart === 0) {
                        e.preventDefault(); // Prevent adding space at the beginning
                      }
                    }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="ssd" className="has-asterisk">
                    SSD
                  </label>
                  <input
                    type="text"
                    name="ssd"
                    value={formData.ssd}
                    onChange={handleInputChange}
                    required
                    onKeyPress={(e) => {
                      if (e.charCode === 32 && e.target.selectionStart === 0) {
                        e.preventDefault(); // Prevent adding space at the beginning
                      }
                    }}
                  />
                </div>
              </div>
            )}
            <div className="d-f f-w inputs gap-15">
              <div className="input-group">
                <label htmlFor="purchaseDate">Purchase Date</label>
                <input
                  type="date"
                  name="purchaseDate"
                  value={formData.purchaseDate}
                  placeholder="08/02/2019"
                  onChange={handleInputChange}
                  minLength={new Date()}
                  max={getCurrentDate()} // Set the max attribute to the current date
                />
              </div>
              <div className="input-group">
                <label htmlFor="comment">Comment</label>
                <textarea
                  id="comment"
                  name="comment"
                  value={formData.comment}
                  onChange={handleInputChange}
                  rows={5}
                  cols={30}
                ></textarea>
              </div>
            </div>
          </div>
          <br />

          <div className="action text-center">
            <button
              className={isLoading ? "theme-button disabled " : "theme-button "}
              type="submit"
            >
              {isLoading ? (
                <ThreeDots
                  height="25"
                  width="80"
                  radius="9"
                  color="white"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      </form>

      <Footer />
    </div>
  );
};

export default CreateAsset;
