import React, { useEffect, useRef, useState } from "react";
import {
  API_PERFORMANCE,
  API_PERFORMANCE_EMPLOYEE,
} from "../../config/Endpoints";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { getApi, putApi } from "../../utils/api";
import { TailSpin, ThreeDots } from "react-loader-spinner";
import { toggleMenu } from "../../utils/helpers";

const Performance = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [muleSoftCertification, setMuleSoftCertification] = useState({
    mccs: false,
    mcd1: false,
    mcd2: false,
    mcia: false,
    mcpa: false,
    msch: false,
    msia0: false,
    none: false,
  });
  const [salesforceCertification, setSalesforceCertification] = useState({
    sa: false,
    sca: false,
    spd1: false,
    spd2: false,
    none: false,
  });
  const [externalCertification, setExternalCertification] = useState();
  const [externalCertificationComment, setExternalCertificationComment] =
    useState("");
  const [muleSoftExercises, setMuleSoftExercises] = useState({
    dwl: false,
    mindTickle: false,
    scenarioBased: false,
    none: false,
  });
  const [mockNumber, setMockNumber] = useState();
  const [mockText, setMockText] = useState("");
  const [classroomSessionCount, setClassroom] = useState();
  const [classroomText, setClassroomText] = useState("");
  const [muleSoftQuizzes, setMuleSoftQuizzes] = useState();
  const [muleSoftQuizComments, setMuleSoftQuizComments] = useState("");
  const [muleSoftMeetups, setMuleSoftMeetups] = useState();
  const [muleSoftMeetupComments, setMuleSoftMeetupComments] = useState("");
  const [meetupSpeakerCount, setMeetupSpeaker] = useState();
  const [muleSoftLeader, setMuleSoftLeader] = useState();
  const [muleSoftMentor, setMuleSoftMentor] = useState();
  const [numberOfEmployeeAsMentor, setNumberOfEmployeeAsMentor] = useState();
  const [mockInterviews, setMockInterviews] = useState();
  const [numberOfMockInterviewsComments, setNumberOfMockInterviewsComments] =
    useState("");
  const [technicalMentor, setTechnicalMentor] = useState();
  const [technicalMentorComments, setTechnicalMentorComments] = useState("");
  const [projectGoLive, setProjectGoLive] = useState();
  const [projectGoLiveComments, setProjectGoLiveComments] = useState("");
  const [numberOfProject, setNumberOfProject] = useState();
  const [projectComments, setProjectComments] = useState("");
  const [projectAppreciations, setProjectAppreciations] = useState();
  const [projectEscalations, setProjectEscalations] = useState();

  const accessToken = Cookies.get("accessToken");
  const formRef = useRef(null);

  const [activeSection, setActiveSection] = useState("training");
  const [sectionInView, setSectionInView] = useState({
    training: false,
    mentorship: false,
    projects: false,
  });
  const maxLength = 255;
  // Manage side progress bar
  // const [activeSection, setActiveSection] = useState("training");
  // const [sectionInView, setSectionInView] = useState({
  //   training: false,
  //   mentorship: false,
  //   projects: false,
  // });

  const fetchPerformance = () => {
    setIsLoadingData(true);
    getApi(API_PERFORMANCE, accessToken)
      .then((res) => {
        if (res.status === 200) {
          let dataRes = res?.data?.data;
          if (dataRes) {
            const hasTrueValueMuleSoftCertification = Object.values(
              dataRes?.muleSoftCertification
            ).some((value) => value === true);
            const hasTrueValueSalesforceCertification = Object.values(
              dataRes?.muleSoftCertification
            ).some((value) => value === true);

            setMuleSoftCertification({
              mccs: dataRes?.muleSoftCertification?.mccs,
              mcd1: dataRes?.muleSoftCertification?.mcd1,
              mcd2: dataRes?.muleSoftCertification?.mcd2,
              mcia: dataRes?.muleSoftCertification?.mcia,
              mcpa: dataRes?.muleSoftCertification?.mcpa,
              msch: dataRes?.muleSoftCertification?.msch,
              msia0: dataRes?.muleSoftCertification?.msia0,
              none: !hasTrueValueMuleSoftCertification,
            });

            setSalesforceCertification({
              sa: dataRes?.salesforceCertification?.sa,
              sca: dataRes?.salesforceCertification?.sca,
              spd1: dataRes?.salesforceCertification?.spd1,
              spd2: dataRes?.salesforceCertification?.spd2,
              none: !hasTrueValueSalesforceCertification,
            });
            setExternalCertification(`${dataRes?.externalCertification}`);
            setExternalCertificationComment(
              dataRes?.externalCertificationComments
            );
            const hasTrueValueMuleSoftExercises = Object.values(
              dataRes?.muleSoftExercises
            ).some((value) => value === true);

            setMuleSoftExercises({
              dwl: dataRes?.muleSoftExercises?.dwl,
              mindTickle: dataRes?.muleSoftExercises?.mindTickle,
              scenarioBased: dataRes?.muleSoftExercises?.scenarioBased,
              none: !hasTrueValueMuleSoftExercises,
            });
            setMockNumber(`${dataRes?.mockInterview}`);
            setMockText(dataRes?.mockComments);
            setClassroom(`${dataRes?.classroomSessionCount}`);
            setClassroomText(dataRes?.classroomComments);
            setMuleSoftQuizzes(dataRes?.muleSoftQuizzes);
            setMuleSoftQuizComments(dataRes?.muleSoftQuizComments);
            setMuleSoftMeetups(dataRes?.muleSoftMeetups);
            setMuleSoftMeetupComments(dataRes?.muleSoftMeetupComments);
            setMeetupSpeaker(`${dataRes?.meetupSpeakerCount}`);
            setMuleSoftLeader(dataRes?.muleSoftLeader);
            setMuleSoftMentor(dataRes?.muleSoftMentor);
            setNumberOfEmployeeAsMentor(dataRes?.numberOfEmployeeAsMentor);
            setMockInterviews(dataRes?.mockInterviews);
            setNumberOfMockInterviewsComments(
              dataRes?.numberOfMockInterviewsComments
            );
            setTechnicalMentor(dataRes?.technicalMentor);
            setTechnicalMentorComments(dataRes?.technicalMentorComments);
            setProjectGoLive(dataRes?.projectGoLive);
            setProjectGoLiveComments(dataRes?.projectGoLiveComments);
            setNumberOfProject(`${dataRes?.numberOfProject}`);
            setProjectComments(dataRes?.projectComments);
            setProjectAppreciations(dataRes?.projectAppreciations);
            setProjectEscalations(dataRes?.projectEscalations);
          }
        }
        setIsLoadingData(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoadingData(false);
      });
  };

  useEffect(() => {
    fetchPerformance();
  }, []);

  const sectionRefs = {
    training: useRef(null),
    mentorship: useRef(null),
    projects: useRef(null),
  };

  const handleSave = async () => {
    const requestData = {
      classroomSessionCount: classroomSessionCount,
      classroomComments: classroomText,
      coffeeNLearn: 0,
      coffeeNLearnComments: "string",
      externalCertification: externalCertification,
      externalCertificationComments: externalCertificationComment,
      meetupSpeakerCount: meetupSpeakerCount,
      mockInterview: mockNumber,
      mockComments: mockText,
      mockInterviews: mockInterviews,
      muleSoftCertification: {
        mccs: muleSoftCertification.mccs,
        mcd1: muleSoftCertification.mcd1,
        mcd2: muleSoftCertification.mcd2,
        mcia: muleSoftCertification.mcia,
        mcpa: muleSoftCertification.mcpa,
        msch: muleSoftCertification.msch,
        msia0: muleSoftCertification.msia0,
      },
      muleSoftExercises: {
        dwl: muleSoftExercises.dwl,
        mindTickle: muleSoftExercises.mindTickle,
        scenarioBased: muleSoftExercises.scenarioBased,
      },
      muleSoftLeader: muleSoftLeader,
      muleSoftMeetupComments: muleSoftMeetupComments,
      muleSoftMeetups: muleSoftMeetups,
      muleSoftMentor: muleSoftMentor,
      muleSoftQuizComments: muleSoftQuizComments,
      muleSoftQuizzes: muleSoftQuizzes,
      numberOfEmployeeAsMentor: numberOfEmployeeAsMentor,
      numberOfMockInterviewsComments: numberOfMockInterviewsComments,
      numberOfProject: numberOfProject,
      projectAppreciations: projectAppreciations,
      projectComments: projectComments,
      projectEscalations: projectEscalations,
      projectGoLive: projectGoLive,
      projectGoLiveComments: projectGoLiveComments,
      salesforceCertification: {
        sa: salesforceCertification.sa,
        sca: salesforceCertification.sca,
        spd1: salesforceCertification.spd1,
        spd2: salesforceCertification.spd2,
      },
      technicalMentor: technicalMentor,
      technicalMentorComments: technicalMentorComments,
    };
    await putApi(API_PERFORMANCE_EMPLOYEE, requestData, accessToken)
      .then((res) => {
        toast.success("Form submitted successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsLoading(false);
        // handleReset();
      })
      .catch((e) => {
        toast.error("Something went wrong please try again !", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsLoading(false);
      });
  };
  const handleMulesoftCertifications = (event) => {
    const { name, checked } = event.target;
    setMuleSoftCertification((prevFormData) => ({
      ...prevFormData,
      [name]: checked,
      none: false,
    }));

    if (name === "none") {
      setMuleSoftCertification({
        mccs: false,
        mcd1: false,
        mcd2: false,
        mcia: false,
        mcpa: false,
        msch: false,
        msia0: false,
        none: !muleSoftCertification.none,
      });
    }
  };
  const handleSalesforceCertification = (event) => {
    const { name, checked } = event.target;
    setSalesforceCertification((prevFormData) => ({
      ...prevFormData,
      [name]: checked,
      none: false,
    }));
    if (name === "none") {
      setSalesforceCertification({
        sa: false,
        sca: false,
        spd1: false,
        spd2: false,
        none: !salesforceCertification.none,
      });
    }
  };
  const handleRadioChange = (event) => {
    setExternalCertification(event.target.value);
  };
  const handleMuleSoftExercises = (event) => {
    const { name, checked } = event.target;
    setMuleSoftExercises((prevFormData) => ({
      ...prevFormData,
      [name]: checked,
      none: false,
    }));

    if (name === "none") {
      setMuleSoftExercises({
        dwl: false,
        mindTickle: false,
        scenarioBased: false,
        none: !muleSoftExercises.none,
      });
    }
  };
  const handleManualSubmit = (e) => {
    e.preventDefault();
    const form = formRef.current;

    if (form.checkValidity()) {
      if (
        Object.values(muleSoftCertification).some((value) => value === true) &&
        Object.values(salesforceCertification).some(
          (value) => value === true
        ) &&
        Object.values(muleSoftExercises).some((value) => value === true)
      ) {
        setIsLoading(true);
        handleSave();
      } else {
        let errorMessage = "Please answer the questions";
        let missingCategories = [];

        if (
          !Object.values(muleSoftCertification).some((value) => value === true)
        ) {
          missingCategories.push("MuleSoft Certification");
        }
        if (
          !Object.values(salesforceCertification).some(
            (value) => value === true
          )
        ) {
          missingCategories.push("Salesforce Certification");
        }
        if (!Object.values(muleSoftExercises).some((value) => value === true)) {
          missingCategories.push("MuleSoft Exercises");
        }

        if (missingCategories.length === 3) {
          errorMessage =
            "Please answer questions for all categories: MuleSoft Certification, Salesforce Certification, and MuleSoft Exercises.";
        } else {
          errorMessage += " for " + missingCategories.join(" & ");
        }

        toast.error(errorMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      form.reportValidity();
    }
  };
  const handleReset = () => {
    setIsLoading(false);
    setMuleSoftCertification({
      mccs: false,
      mcd1: false,
      mcd2: false,
      mcia: false,
      mcpa: false,
      msch: false,
      msia0: false,
      none: false,
    });
    setSalesforceCertification({
      sa: false,
      sca: false,
      spd1: false,
      spd2: false,
      none: false,
    });
    setExternalCertification();
    setExternalCertificationComment();
    setMuleSoftExercises({
      dwl: false,
      mindTickle: false,
      scenarioBased: false,
      none: false,
    });
    setMockNumber();
    setMockText();
    setClassroom();
    setClassroomText();
    setMuleSoftQuizzes();
    setMuleSoftQuizComments("");
    setMuleSoftMeetups();
    setMuleSoftMeetupComments("");
    setMeetupSpeaker();
    setMuleSoftLeader();
    setMuleSoftMentor();
    setNumberOfEmployeeAsMentor();
    setMockInterviews();
    setNumberOfMockInterviewsComments("");
    setTechnicalMentor();
    setTechnicalMentorComments("");
    setProjectGoLive();
    setProjectGoLiveComments("");
    setNumberOfProject();
    setProjectComments("");
    setProjectAppreciations("");
    setProjectEscalations("");
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const sectionId = entry.target.id;
        if (entry.isIntersecting) {
          setActiveSection(sectionId);
        }
      });
    }, options);

    Object.keys(sectionRefs).forEach((section) => {
      observer.observe(sectionRefs[section].current);
    });

    return () => {
      // Clean up the observer when the component unmounts
      observer.disconnect();
    };
  }, []);

  return (
    <div className="wrapper-body">
      {isLoadingData && (
        <div className="overlay">
          <div className="mt-5">
            <ThreeDots
              height="100"
              width="100"
              radius="1"
              color="blue"
              ariaLabel="tail-spin-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}

      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <h1>Performance Evaluation Form</h1>
        <button
          type="submit"
          form="performaceEvaluationForm"
          className={
            isLoading
              ? "theme-button ms-auto disabled "
              : "theme-button ms-auto"
          }
        >
          {isLoading ? (
            <ThreeDots
              height="20"
              width="53"
              radius="9"
              color="white"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          ) : (
            "Save"
          )}
        </button>
      </div>
      <div className="row">
        <div className="col-md-3">
          <ul className="tab-nav">
            <li
              className={activeSection === "training" ? "active" : "complete"}
            >
              <a href="#training" role="tab">
                Training Review
              </a>
            </li>
            <li
              className={
                activeSection === "mentorship"
                  ? "active"
                  : activeSection === "projects"
                  ? "complete"
                  : ""
              }
            >
              <a href="#mentorship" role="tab">
                Mentorship
              </a>
            </li>
            <li className={activeSection === "projects" ? "active" : ""}>
              <a href="#projects" role="tab">
                Projects Review
              </a>
            </li>
          </ul>
        </div>
        <div className="col-md-9">
          <form
            id="performaceEvaluationForm"
            onSubmit={handleManualSubmit}
            ref={formRef}
          >
            {/* BEGIN: Training */}
            <fieldset id="training" ref={sectionRefs.training}>
              <div className="card">
                <h3 className="heading">Training Review</h3>
                <p>
                  In this section, you will be evaluated on the training &
                  development activities that you have participated in.
                </p>

                <div className="d-f f-w inputs gap-15">
                  <div className="input-group">
                    <label
                      htmlFor="mulesoft_certifications"
                      className="question"
                    >
                      1. Which MuleSoft certifications have you completed?
                      <small>*Multiselect</small>
                    </label>
                    <div
                      className="custom-checkbox"
                      id="mulesoft_certifications"
                    >
                      <div>
                        <input
                          type="checkbox"
                          id="check_MuleIntegration"
                          checked={muleSoftCertification?.msia0}
                          name="msia0"
                          onClick={handleMulesoftCertifications}
                        />
                        <label htmlFor="check_MuleIntegration">
                          MuleSoft Integration Associate (Level 0)
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="check_MCD1"
                          checked={muleSoftCertification?.mcd1}
                          name="mcd1"
                          onClick={handleMulesoftCertifications}
                        />
                        <label htmlFor="check_MCD1">MCD 1</label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="check_MCD2"
                          checked={muleSoftCertification?.mcd2}
                          name="mcd2"
                          onClick={handleMulesoftCertifications}
                        />
                        <label htmlFor="check_MCD2">MCD 2</label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="check_MuleSoftCertifiedHyperautomation"
                          checked={muleSoftCertification?.msch}
                          name="msch"
                          onClick={handleMulesoftCertifications}
                        />
                        <label htmlFor="check_MuleSoftCertifiedHyperautomation">
                          MuleSoft Certified Hyperautomation
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="check_MuleSoftCertifiedCatalystSpecialist"
                          checked={muleSoftCertification?.mccs}
                          name="mccs"
                          onClick={handleMulesoftCertifications}
                        />
                        <label htmlFor="check_MuleSoftCertifiedCatalystSpecialist">
                          MuleSoft Certified Catalyst Specialist
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="check_MuleSoftCertifiedIntegrationArchitect"
                          checked={muleSoftCertification?.mcia}
                          name="mcia"
                          onClick={handleMulesoftCertifications}
                        />
                        <label htmlFor="check_MuleSoftCertifiedIntegrationArchitect">
                          MuleSoft Certified Integration Architect
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="check_MuleSoftCertifiedNone"
                          checked={muleSoftCertification?.none}
                          name="none"
                          onClick={handleMulesoftCertifications}
                        />
                        <label htmlFor="check_MuleSoftCertifiedNone">
                          None
                        </label>
                      </div>
                    </div>
                    <div className="mt-3 d-f gap-2">
                      <p className="badge badge-success">
                        <strong>+100</strong> points
                      </p>
                    </div>
                  </div>
                </div>

                <div className="d-f f-w inputs gap-15 mt-4">
                  <div className="input-group">
                    <label
                      htmlFor="salesforce_certifications"
                      className="question"
                    >
                      2. Which Salesforce Certifications have you completed?{" "}
                      <small>*Multiselect</small>
                    </label>
                    <div
                      className="custom-checkbox"
                      id="salesforce_certifications"
                    >
                      <div>
                        <input
                          type="checkbox"
                          id="check_SalesforceAssociate"
                          checked={salesforceCertification?.sa}
                          name="sa"
                          onClick={handleSalesforceCertification}
                        />
                        <label htmlFor="check_SalesforceAssociate">
                          Salesforce Associate
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="check_SalesforceCertifiedAdministrator"
                          checked={salesforceCertification?.sca}
                          name="sca"
                          onClick={handleSalesforceCertification}
                        />
                        <label htmlFor="check_SalesforceCertifiedAdministrator">
                          Salesforce Certified Administrator
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="check_SalesforcePlatformDeveloper1"
                          checked={salesforceCertification?.spd1}
                          name="spd1"
                          onClick={handleSalesforceCertification}
                        />
                        <label htmlFor="check_SalesforcePlatformDeveloper1">
                          Salesforce Platform Developer 1
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="check_SalesforcePlatformDeveloper2"
                          checked={salesforceCertification?.spd2}
                          name="spd2"
                          onClick={handleSalesforceCertification}
                        />
                        <label htmlFor="check_SalesforcePlatformDeveloper2">
                          Salesforce Platform Developer 2
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="check_SalesforcePlatformDeveloper_none"
                          checked={salesforceCertification?.none}
                          name="none"
                          onClick={handleSalesforceCertification}
                        />
                        <label htmlFor="check_SalesforcePlatformDeveloper_none">
                          None
                        </label>
                      </div>
                    </div>
                    <div className="mt-3 d-f gap-2">
                      <p className="badge badge-success">
                        <strong>+100</strong> points
                      </p>
                    </div>
                  </div>
                </div>

                <div className="d-f f-w inputs gap-15 mt-4">
                  <div className="input-group">
                    <label htmlFor="extra_certifications" className="question">
                      3. Number of certifications completed other than the
                      certifications mentioned above?*
                    </label>
                    <div
                      className="custom-checkbox small"
                      id="extra_certifications"
                    >
                      <div>
                        <input
                          type="radio"
                          id="check_0"
                          name="extra_certifications"
                          value="0"
                          onChange={handleRadioChange}
                          checked={externalCertification === "0"}
                        />
                        <label htmlFor="check_0">0</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_1"
                          name="extra_certifications"
                          value="1"
                          onChange={handleRadioChange}
                          checked={externalCertification === "1"}
                        />
                        <label htmlFor="check_1">1</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_2"
                          name="extra_certifications"
                          value="2"
                          onChange={handleRadioChange}
                          checked={externalCertification === "2"}
                        />
                        <label htmlFor="check_2">2</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_3"
                          name="extra_certifications"
                          value="3"
                          onChange={handleRadioChange}
                          checked={externalCertification === "3"}
                        />
                        <label htmlFor="check_3">3</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_4"
                          name="extra_certifications"
                          value="4"
                          onChange={handleRadioChange}
                          checked={externalCertification === "4"}
                        />
                        <label htmlFor="check_4">4</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_5"
                          name="extra_certifications"
                          value="5"
                          onChange={handleRadioChange}
                          checked={externalCertification === "5"}
                        />
                        <label htmlFor="check_5">5</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_6"
                          name="extra_certifications"
                          value="6"
                          onChange={handleRadioChange}
                          checked={externalCertification === "6"}
                        />
                        <label htmlFor="check_6">6</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_7"
                          name="extra_certifications"
                          value="7"
                          onChange={handleRadioChange}
                          checked={externalCertification === "7"}
                          required
                        />
                        <label htmlFor="check_7">7</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_8"
                          name="extra_certifications"
                          value="8"
                          onChange={handleRadioChange}
                          checked={externalCertification === "8"}
                        />
                        <label htmlFor="check_8">8</label>
                      </div>
                    </div>
                    <div className="mt-3 d-f gap-2">
                      <p className="badge badge-success">
                        <strong>+100</strong> points
                      </p>
                    </div>
                  </div>
                </div>

                <div className="d-f f-w inputs gap-15 mt-4">
                  <div className="input-group">
                    <label
                      className="question"
                      htmlFor="externalCertificationComment"
                    >
                      4. Mention the certificates completed other than the
                      certificates mentioned above.*
                    </label>
                    <textarea
                      name="externalCertificationComment"
                      id="externalCertificationComment"
                      rows="3"
                      placeholder="- Type Here"
                      value={externalCertificationComment}
                      onChange={(e) =>
                        setExternalCertificationComment(e.target.value)
                      }
                      required
                      maxLength={maxLength}
                    ></textarea>

                    <div className="badge badge-danger">
                      Character Limit :&nbsp;
                      {maxLength -
                        Number(externalCertificationComment?.length) +
                        "/" +
                        maxLength}
                    </div>
                  </div>
                </div>

                <div className="d-f f-w inputs gap-15 mt-4">
                  <div className="input-group">
                    <label htmlFor="completedExcersise" className="question">
                      5. Select the exercises completed.{" "}
                      <small>*Multiselect</small>
                    </label>
                    <div className="custom-checkbox" id="completedExcersise">
                      <div>
                        <input
                          type="checkbox"
                          id="check_DWL"
                          onChange={handleMuleSoftExercises}
                          checked={muleSoftExercises.dwl}
                          name="dwl"
                        />
                        <label htmlFor="check_DWL">DWL</label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="check_MindTickle"
                          onChange={handleMuleSoftExercises}
                          checked={muleSoftExercises.mindTickle}
                          name="mindTickle"
                        />
                        <label htmlFor="check_MindTickle">MindTickle</label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="check_ScenarioBased"
                          onChange={handleMuleSoftExercises}
                          checked={muleSoftExercises.scenarioBased}
                          name="scenarioBased"
                        />
                        <label htmlFor="check_ScenarioBased">
                          Scenario Based
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="check_CompletedNone"
                          onChange={handleMuleSoftExercises}
                          checked={muleSoftExercises.none}
                          name="none"
                        />
                        <label htmlFor="check_CompletedNone">None</label>
                      </div>
                    </div>
                    <div className="mt-3 d-f gap-2">
                      <p className="badge badge-success">
                        <strong>+100</strong> points
                      </p>
                    </div>
                  </div>
                </div>

                <div className="d-f f-w inputs gap-15 mt-4">
                  <div className="input-group">
                    <label htmlFor="clientInterviewsCount" className="question">
                      6. How many Mock/Client Interviews have you given during
                      the last 12 months?*
                    </label>
                    <p className="label-brief">
                      <small> -300 Points for 0 interviews</small>
                      <small> +75 Points for each interview given</small>
                    </p>
                    <div
                      className="custom-checkbox small"
                      id="clientInterviewsCount"
                    >
                      <div>
                        <input
                          type="radio"
                          id="check_client0"
                          name="clientInterviewsCount"
                          value="0"
                          onChange={(e) => setMockNumber(e.target.value)}
                          checked={mockNumber === "0"}
                        />
                        <label htmlFor="check_client0">0</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_client1"
                          name="clientInterviewsCount"
                          value="1"
                          onChange={(e) => setMockNumber(e.target.value)}
                          checked={mockNumber === "1"}
                        />
                        <label htmlFor="check_client1">1</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_client2"
                          name="clientInterviewsCount"
                          value="2"
                          onChange={(e) => setMockNumber(e.target.value)}
                          checked={mockNumber === "2"}
                        />
                        <label htmlFor="check_client2">2</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_client3"
                          name="clientInterviewsCount"
                          value="3"
                          onChange={(e) => setMockNumber(e.target.value)}
                          checked={mockNumber === "3"}
                        />
                        <label htmlFor="check_client3">3</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_client4"
                          name="clientInterviewsCount"
                          value="4"
                          onChange={(e) => setMockNumber(e.target.value)}
                          checked={mockNumber === "4"}
                        />
                        <label htmlFor="check_client4">4</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_client5"
                          name="clientInterviewsCount"
                          value="5"
                          onChange={(e) => setMockNumber(e.target.value)}
                          checked={mockNumber === "5"}
                        />
                        <label htmlFor="check_client5">5</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_client6"
                          name="clientInterviewsCount"
                          value="6"
                          onChange={(e) => setMockNumber(e.target.value)}
                          checked={mockNumber === "6"}
                        />
                        <label htmlFor="check_client6">6</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_client7"
                          name="clientInterviewsCount"
                          value="7"
                          onChange={(e) => setMockNumber(e.target.value)}
                          checked={mockNumber === "7"}
                          required
                        />
                        <label htmlFor="check_client7">7</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_client8"
                          name="clientInterviewsCount"
                          value="8"
                          onChange={(e) => setMockNumber(e.target.value)}
                          checked={mockNumber === "8"}
                        />
                        <label htmlFor="check_client8">8</label>
                      </div>
                    </div>
                    <div className="mt-3 d-f gap-2">
                      <p className="badge badge-success">
                        <strong>+150</strong> points
                      </p>
                      <p className="badge badge-danger">
                        <strong>-300</strong> points
                      </p>
                    </div>
                  </div>
                </div>

                <div className="d-f f-w inputs gap-15 mt-4">
                  <div className="input-group">
                    <label className="question" htmlFor="mockText">
                      7. If you have given the mock interviews or client
                      interviews, write in detail about the interviews given. If
                      No, write N.A. (Not Applicable)*
                    </label>
                    <p className="label-brief">
                      <small>
                        HR will evaluate and assign a score out of 200 Points
                        based on the supervisor ratings*
                      </small>
                    </p>
                    <textarea
                      id="mockText"
                      name="mockText"
                      rows="3"
                      placeholder="- Type Here"
                      onChange={(e) => setMockText(e.target.value)}
                      value={mockText}
                      required
                      maxLength={maxLength}
                    ></textarea>
                    <div className="badge badge-danger">
                      Character Limit :&nbsp;
                      {maxLength - Number(mockText?.length) + "/" + maxLength}
                    </div>
                  </div>
                </div>

                <div className="d-f f-w inputs gap-15 mt-4">
                  <div className="input-group">
                    <label className="question" htmlFor="classroomSessionCount">
                      8. How many topics have you presented on in Classroom
                      Session during the last 12 months?*
                    </label>
                    <p className="label-brief">
                      <small> -300 Points for 0 interviews</small>
                      <small> +75 Points for each interview given</small>
                    </p>
                    <div
                      className="custom-checkbox small"
                      id="classroomSessionCount"
                    >
                      <div>
                        <input
                          type="radio"
                          id="check_classroom0"
                          name="classroomSessionCount"
                          value="0"
                          onChange={(e) => setClassroom(e.target.value)}
                          checked={classroomSessionCount === "0"}
                        />
                        <label htmlFor="check_classroom0">0</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_classroom1"
                          name="classroomSessionCount"
                          value="1"
                          onChange={(e) => setClassroom(e.target.value)}
                          checked={classroomSessionCount === "1"}
                        />
                        <label htmlFor="check_classroom1">1</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_classroom2"
                          name="classroomSessionCount"
                          value="2"
                          onChange={(e) => setClassroom(e.target.value)}
                          checked={classroomSessionCount === "2"}
                        />
                        <label htmlFor="check_classroom2">2</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_classroom3"
                          name="classroomSessionCount"
                          value="3"
                          onChange={(e) => setClassroom(e.target.value)}
                          checked={classroomSessionCount === "3"}
                        />
                        <label htmlFor="check_classroom3">3</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_classroom4"
                          name="classroomSessionCount"
                          value="4"
                          onChange={(e) => setClassroom(e.target.value)}
                          checked={classroomSessionCount === "4"}
                        />
                        <label htmlFor="check_classroom4">4</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_classroom5"
                          name="classroomSessionCount"
                          value="5"
                          onChange={(e) => setClassroom(e.target.value)}
                          checked={classroomSessionCount === "5"}
                        />
                        <label htmlFor="check_classroom5">5</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_classroom6"
                          name="classroomSessionCount"
                          value="6"
                          onChange={(e) => setClassroom(e.target.value)}
                          checked={classroomSessionCount === "6"}
                        />
                        <label htmlFor="check_classroom6">6</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_classroom7"
                          name="classroomSessionCount"
                          value="7"
                          onChange={(e) => setClassroom(e.target.value)}
                          checked={classroomSessionCount === "7"}
                          required
                        />
                        <label htmlFor="check_classroom7">7</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_classroom8"
                          name="classroomSessionCount"
                          value="8"
                          onChange={(e) => setClassroom(e.target.value)}
                          checked={classroomSessionCount === "8"}
                        />
                        <label htmlFor="check_classroom8">8</label>
                      </div>
                    </div>
                    <div className="mt-3 d-f gap-2">
                      <p className="badge badge-success">
                        <strong>+150</strong> points
                      </p>
                      <p className="badge badge-danger">
                        <strong>-300</strong> points
                      </p>
                    </div>
                  </div>
                </div>

                <div className="d-f f-w inputs gap-15 mt-4">
                  <div className="input-group">
                    <label className="question" htmlFor="classroomText">
                      9. If you have presented on any topic in CoffeeNLearn
                      session, mention the topics presented along with the dates
                      on which the topic was presented and explain in detail. If
                      No, write N.A. (Not Applicable)
                    </label>
                    <p className="label-brief">
                      <small>
                        HR will evaluate and assign a score out of 200 Points*
                      </small>
                    </p>
                    <textarea
                      id="classroomText"
                      name="classroomText"
                      rows="3"
                      placeholder="- Type Here"
                      value={classroomText}
                      onChange={(e) => setClassroomText(e.target.value)}
                      required
                      maxLength={maxLength}
                    ></textarea>
                    <div className="badge badge-danger">
                      Character Limit :&nbsp;
                      {maxLength -
                        Number(classroomText?.length) +
                        "/" +
                        maxLength}
                    </div>
                  </div>
                </div>

                {/* <div className="d-f f-w inputs gap-15 mt-4">
                  <div className="input-group">
                    <label
                      className="question"
                      htmlFor="coffeeNLearnSessionCount"
                    >
                      How many topics have you presented on in Classroom Session
                      during the last 12 months?
                    </label>
                    <p className="label-brief">
                      <small>100 Points for each topic</small>
                    </p>
                    <div
                      className="custom-checkbox small"
                      id="coffeeNLearnSessionCount"
                    >
                      <div>
                        <input
                          type="radio"
                          id="check_coffeeLearn0"
                          name="coffeeNLearnSessionCount"
                        />
                        <label htmlFor="check_coffeeLearn0">0</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_coffeeLearn1"
                          name="coffeeNLearnSessionCount"
                        />
                        <label htmlFor="check_coffeeLearn1">1</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_coffeeLearn2"
                          name="coffeeNLearnSessionCount"
                        />
                        <label htmlFor="check_coffeeLearn2">2</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_coffeeLearn3"
                          name="coffeeNLearnSessionCount"
                        />
                        <label htmlFor="check_coffeeLearn3">3</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_coffeeLearn4"
                          name="coffeeNLearnSessionCount"
                        />
                        <label htmlFor="check_coffeeLearn4">4</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_coffeeLearn5"
                          name="coffeeNLearnSessionCount"
                        />
                        <label htmlFor="check_coffeeLearn5">5</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_coffeeLearn6"
                          name="coffeeNLearnSessionCount"
                        />
                        <label htmlFor="check_coffeeLearn6">6</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_coffeeLearn7"
                          name="coffeeNLearnSessionCount"
                        />
                        <label htmlFor="check_coffeeLearn7">7</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_coffeeLearn8"
                          name="coffeeNLearnSessionCount"
                        />
                        <label htmlFor="check_coffeeLearn8">8</label>
                      </div>
                    </div>
                    <div className="mt-3 d-f gap-2">
                      <p className="badge badge-success">
                        <strong>+100</strong> points
                      </p>
                    </div>
                  </div>
                </div> */}

                {/* <div className="d-f f-w inputs gap-15 mt-4">
                  <div className="input-group">
                    <label className="question" htmlFor="typeCoffeeLearnTopics">
                      If you have presented on any topic in CoffeeNLearn
                      session, mention the topics presented along with the dates
                      on which the topic was presented and explain in detail. If
                      No, write N.A. (Not Applicable)
                    </label>
                    <p className="label-brief">
                      <small>
                        HR will evaluate and assign a score out of 300 Points
                      </small>
                    </p>
                    <textarea
                      id="typeCoffeeLearnTopics"
                      rows="3"
                      placeholder="- Type Here"
                    ></textarea>
                  </div>
                </div> */}

                <div className="d-f f-w inputs gap-15 mt-4">
                  <div className="input-group">
                    <label
                      htmlFor="mulesoftQuizParticipation"
                      className="question"
                    >
                      10. Have you participated in any of the MuleSoft Quizzes?*
                    </label>
                    <div
                      className="custom-checkbox small"
                      id="mulesoftQuizParticipation"
                    >
                      <div>
                        <input
                          type="radio"
                          id="check_muleQuizYes"
                          name="check_muleQuiz"
                          checked={muleSoftQuizzes === true}
                          onChange={() => setMuleSoftQuizzes(true)}
                        />
                        <label htmlFor="check_muleQuizYes">Yes</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_muleQuizNo"
                          name="check_muleQuiz"
                          checked={muleSoftQuizzes === false}
                          onChange={() => setMuleSoftQuizzes(false)}
                          required
                        />
                        <label htmlFor="check_muleQuizNo">No</label>
                      </div>
                    </div>
                    <div className="mt-3 d-f gap-2">
                      <p className="badge badge-danger">
                        <strong>-300</strong> points
                      </p>
                    </div>
                  </div>
                </div>

                <div className="d-f f-w inputs gap-15 mt-4">
                  <div className="input-group">
                    <label className="question" htmlFor="muleSoftQuizComments">
                      11. Write your Rank and Score for each quiz you have
                      participated in. If not participated in any quiz, write
                      N.A. (Not Applicable)*
                    </label>
                    <p className="label-brief">
                      <small>
                        HR will evaluate and assign a score out of 300 Points*
                      </small>
                    </p>
                    <textarea
                      id="muleSoftQuizComments"
                      name="muleSoftQuizComments"
                      rows="3"
                      placeholder="- Type Here"
                      value={muleSoftQuizComments}
                      onChange={(e) => setMuleSoftQuizComments(e.target.value)}
                      required
                      maxLength={maxLength}
                    ></textarea>
                    <div className="badge badge-danger">
                      Character Limit :&nbsp;
                      {maxLength -
                        Number(muleSoftQuizComments?.length) +
                        "/" +
                        maxLength}
                    </div>
                  </div>
                </div>

                <div className="d-f f-w inputs gap-15 mt-4">
                  <div className="input-group">
                    <label className="question" htmlFor="meetupSpeakerCount">
                      12. Have you appeared as a speaker in any of the MuleSoft
                      Meetups?*
                    </label>
                    <p className="label-brief">
                      <small>400 Points for "Yes"</small>
                    </p>
                    <div
                      className="custom-checkbox small"
                      id="meetupSpeakerCount"
                    >
                      <div>
                        <input
                          type="radio"
                          id="check_meetupSpeakerYes"
                          name="check_meetupSpeaker"
                          checked={muleSoftMeetups === true}
                          onChange={(e) => setMuleSoftMeetups(true)}
                          required
                        />
                        <label htmlFor="check_meetupSpeakerYes">Yes</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_meetupSpeakerNo"
                          name="check_meetupSpeaker"
                          checked={muleSoftMeetups === false}
                          onChange={(e) => setMuleSoftMeetups(false)}
                        />
                        <label htmlFor="check_meetupSpeakerNo">No</label>
                      </div>
                    </div>
                    <div className="mt-3 d-f gap-2">
                      <p className="badge badge-success">
                        <strong>+400</strong> points
                      </p>
                    </div>
                  </div>
                </div>

                <div className="d-f f-w inputs gap-15 mt-4">
                  <div className="input-group">
                    <label className="question" htmlFor="meetupSpeakerCount">
                      13. Have you appeared as a speaker in any of the MuleSoft
                      Meetups?*
                    </label>
                    <p className="label-brief">
                      <small>50 Points for each appearance</small>
                    </p>
                    <div
                      className="custom-checkbox small"
                      id="meetupSpeakerCount"
                    >
                      <div>
                        <input
                          type="radio"
                          id="check_meetupSpeaker1"
                          name="check_meetupSpeakerCount"
                          value="0"
                          onChange={(e) => setMeetupSpeaker(e.target.value)}
                          checked={meetupSpeakerCount === "0"}
                        />
                        <label htmlFor="check_meetupSpeaker1">0</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_meetupSpeaker1"
                          name="check_meetupSpeakerCount"
                          value="1"
                          onChange={(e) => setMeetupSpeaker(e.target.value)}
                          checked={meetupSpeakerCount === "1"}
                        />
                        <label htmlFor="check_meetupSpeaker1">1</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_meetupSpeaker2"
                          name="check_meetupSpeakerCount"
                          value="2"
                          onChange={(e) => setMeetupSpeaker(e.target.value)}
                          checked={meetupSpeakerCount === "2"}
                        />
                        <label htmlFor="check_meetupSpeaker2">2</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_meetupSpeaker3"
                          name="check_meetupSpeakerCount"
                          value="3"
                          onChange={(e) => setMeetupSpeaker(e.target.value)}
                          checked={meetupSpeakerCount === "3"}
                        />
                        <label htmlFor="check_meetupSpeaker3">3</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_meetupSpeaker4"
                          name="check_meetupSpeakerCount"
                          value="4"
                          onChange={(e) => setMeetupSpeaker(e.target.value)}
                          checked={meetupSpeakerCount === "4"}
                        />
                        <label htmlFor="check_meetupSpeaker4">4</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_meetupSpeaker5"
                          name="check_meetupSpeakerCount"
                          value="5"
                          onChange={(e) => setMeetupSpeaker(e.target.value)}
                          checked={meetupSpeakerCount === "5"}
                        />
                        <label htmlFor="check_meetupSpeaker5">5</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_meetupSpeaker6"
                          name="check_meetupSpeakerCount"
                          value="6"
                          onChange={(e) => setMeetupSpeaker(e.target.value)}
                          checked={meetupSpeakerCount === "6"}
                        />
                        <label htmlFor="check_meetupSpeaker6">6</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_meetupSpeaker7"
                          name="check_meetupSpeakerCount"
                          value="7"
                          onChange={(e) => setMeetupSpeaker(e.target.value)}
                          checked={meetupSpeakerCount === "7"}
                          required
                        />
                        <label htmlFor="check_meetupSpeaker7">7</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_meetupSpeaker8"
                          name="check_meetupSpeakerCount"
                          value="8"
                          onChange={(e) => setMeetupSpeaker(e.target.value)}
                          checked={meetupSpeakerCount === "8"}
                        />
                        <label htmlFor="check_meetupSpeaker8">8</label>
                      </div>
                    </div>
                    <div className="mt-3 d-f gap-2">
                      <p className="badge badge-success">
                        <strong>+50</strong> points
                      </p>
                    </div>
                  </div>
                </div>

                <div className="d-f f-w inputs gap-15 mt-4">
                  <div className="input-group">
                    <label
                      className="question"
                      htmlFor="muleSoftMeetupComments"
                    >
                      14. If you appeared as a speaker in any of the MuleSoft
                      Meetups, write in detail about all the Meetups where you
                      were a Speaker. Mention the Meetup locations, dates and
                      topics on which you presented. Else, Write N.A. (Not
                      Applicable)*
                    </label>
                    <p className="label-brief">
                      <small>
                        HR will evaluate and assign a score out of 300 Points*
                      </small>
                    </p>
                    <textarea
                      id="muleSoftMeetupComments"
                      name="muleSoftMeetupComments"
                      rows="3"
                      placeholder="- Type Here"
                      value={muleSoftMeetupComments}
                      onChange={(e) =>
                        setMuleSoftMeetupComments(e.target.value)
                      }
                      required
                      maxLength={maxLength}
                    ></textarea>
                    <div className="badge badge-danger">
                      Character Limit :&nbsp;
                      {maxLength -
                        Number(muleSoftMeetupComments?.length) +
                        "/" +
                        maxLength}
                    </div>
                  </div>
                </div>

                <div className="d-f f-w inputs gap-15 mt-4">
                  <div className="input-group">
                    <label className="question" htmlFor="mulesoftLeader">
                      15. Are you a MuleSoft Leader?*
                    </label>
                    <p className="label-brief">
                      <small>500 Points for "Yes"</small>
                    </p>
                    <div className="custom-checkbox small" id="mulesoftLeader">
                      <div>
                        <input
                          type="radio"
                          id="check_mulesoftLeaderYes"
                          name="check_mulesoftLeader"
                          checked={muleSoftLeader === true}
                          onChange={() => setMuleSoftLeader(true)}
                        />
                        <label htmlFor="check_mulesoftLeaderYes">Yes</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_mulesoftLeaderNo"
                          name="check_mulesoftLeader"
                          checked={muleSoftLeader === false}
                          onChange={() => setMuleSoftLeader(false)}
                          required
                        />
                        <label htmlFor="check_mulesoftLeaderNo">No</label>
                      </div>
                    </div>
                    <div className="mt-3 d-f gap-2">
                      <p className="badge badge-success">
                        <strong>+500</strong> points
                      </p>
                    </div>
                  </div>
                </div>

                <div className="d-f f-w inputs gap-15 mt-4">
                  <div className="input-group">
                    <label className="question" htmlFor="mulesoftMentor">
                      16. Are you a MuleSoft Mentor?*
                    </label>
                    <p className="label-brief">
                      <small>500 Points for "Yes"</small>
                    </p>
                    <div className="custom-checkbox small" id="mulesoftMentor">
                      <div>
                        <input
                          type="radio"
                          id="check_mulesoftMentorYes"
                          name="check_mulesoftMentor"
                          checked={muleSoftMentor === true}
                          onChange={() => setMuleSoftMentor(true)}
                        />
                        <label htmlFor="check_mulesoftMentorYes">Yes</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_mulesoftMentorNo"
                          name="check_mulesoftMentor"
                          checked={muleSoftMentor === false}
                          onChange={() => setMuleSoftMentor(false)}
                          required
                        />
                        <label htmlFor="check_mulesoftMentorNo">No</label>
                      </div>
                    </div>
                    <div className="mt-3 d-f gap-2">
                      <p className="badge badge-success">
                        <strong>+500</strong> points
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
            {/* END: Training */}

            {/* BEGIN: MENTORSHIP */}
            <fieldset id="mentorship" ref={sectionRefs.mentorship}>
              <div className="card">
                <h3 className="heading">Mentorship</h3>
                <p>
                  <small>
                    For this section, HR will evaluate and assign a score out of
                    300 Points based on the feedback
                  </small>
                  <span className="d-block">
                    In this section, you will be evaluated on your performance
                    as a mentor to other employees.
                  </span>
                </p>

                <div className="d-f f-w inputs gap-15">
                  <div className="input-group">
                    <label className="question" htmlFor="mentorEmployeesCount">
                      1. How many employees have you mentored during the Review
                      Period? (last 12 months)*
                    </label>
                    <input
                      type="text"
                      id="mentorEmployeesCount"
                      placeholder="1 - 100"
                      onChange={(e) =>
                        setNumberOfEmployeeAsMentor(e.target.value)
                      }
                      value={numberOfEmployeeAsMentor}
                      required
                      maxLength={3}
                      onKeyPress={(e) => {
                        const isDigit = /\d/.test(e.key);
                        if (!isDigit) {
                          e.preventDefault();
                        }
                      }}
                    ></input>
                  </div>
                </div>

                <div className="d-f f-w inputs gap-15 mt-4">
                  <div className="input-group">
                    <label className="question" htmlFor="employeeMockInterview">
                      2. Have you taken mock interviews of other employees as a
                      Mentor?*
                    </label>
                    <div
                      className="custom-checkbox small"
                      id="employeeMockInterview"
                    >
                      <div>
                        <input
                          type="radio"
                          id="check_employeeMockInterviewYes"
                          name="check_employeeMockInterview"
                          checked={mockInterviews === true}
                          onChange={() => setMockInterviews(true)}
                        />
                        <label htmlFor="check_employeeMockInterviewYes">
                          Yes
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_employeeMockInterviewNo"
                          name="check_employeeMockInterview"
                          checked={mockInterviews === false}
                          onChange={() => setMockInterviews(false)}
                          required
                        />
                        <label htmlFor="check_employeeMockInterviewNo">
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-f f-w inputs gap-15 mt-4">
                  <div className="input-group">
                    <label
                      className="question"
                      htmlFor="typeEmployeeMockInterview"
                    >
                      3. If Yes, write the number of interviews taken and give
                      details. If No, write N.A. (Not Applicable)*
                    </label>
                    <input
                      type="text"
                      id="typeEmployeeMockInterview"
                      placeholder="1 - 100"
                      maxLength={3}
                      value={numberOfMockInterviewsComments}
                      onChange={(e) =>
                        setNumberOfMockInterviewsComments(e.target.value)
                      }
                      required
                      onKeyPress={(e) => {
                        const isDigit = /\d/.test(e.key);
                        if (!isDigit) {
                          e.preventDefault();
                        }
                      }}
                    ></input>
                  </div>
                </div>

                <div className="d-f f-w inputs gap-15 mt-4">
                  <div className="input-group">
                    <label className="question" htmlFor="mentorTechForum">
                      4. Have you taken the role of a Mentor in Technical
                      Discussion Forum?*
                    </label>
                    <div className="custom-checkbox small" id="mentorTechForum">
                      <div>
                        <input
                          type="radio"
                          id="check_mentorTechForumYes"
                          name="check_mentorTechForum"
                          checked={technicalMentor === true}
                          onChange={() => setTechnicalMentor(true)}
                        />
                        <label htmlFor="check_mentorTechForumYes">Yes</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_mentorTechForumNo"
                          name="check_mentorTechForum"
                          checked={technicalMentor === false}
                          onChange={() => setTechnicalMentor(false)}
                          required
                        />
                        <label htmlFor="check_mentorTechForumNo">No</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-f f-w inputs gap-15 mt-4">
                  <div className="input-group">
                    <label
                      className="question"
                      htmlFor="technicalMentorComments"
                    >
                      5. If Yes, write the number of Technical Discussion Forums
                      you have participated in and write in detail about the
                      topics, your role, responsibilities, contribution and
                      feedback as a Mentor in the discussion along the dates on
                      which it was conducted. If No, write N.A. (Not
                      Applicable)*
                    </label>
                    <textarea
                      name="technicalMentorComments"
                      id="technicalMentorComments"
                      rows="3"
                      placeholder="- Type Here"
                      value={technicalMentorComments}
                      onChange={(e) =>
                        setTechnicalMentorComments(e.target.value)
                      }
                      required
                      maxLength={maxLength}
                    ></textarea>
                    <div className="badge badge-danger">
                      Character Limit :&nbsp;
                      {maxLength -
                        Number(technicalMentorComments?.length) +
                        "/" +
                        maxLength}
                    </div>
                  </div>
                </div>

                <div className="d-f f-w inputs gap-15 mt-4">
                  <div className="input-group">
                    <label className="question" htmlFor="mentorLiveProject">
                      6. Did you take part as a Mentor in "Project Go Live"
                      Sessions? (Wherein you played the role of a Client and
                      asked questions to the other employee playing the role of
                      a Developer)*
                    </label>
                    <div
                      className="custom-checkbox small"
                      id="mentorLiveProject"
                    >
                      <div>
                        <input
                          type="radio"
                          id="check_mentorLiveProjectYes"
                          name="check_mentorLiveProject"
                          checked={projectGoLive === true}
                          onChange={(e) => setProjectGoLive(true)}
                        />
                        <label htmlFor="check_mentorLiveProjectYes">Yes</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_mentorLiveProjectNo"
                          name="check_mentorLiveProject"
                          checked={projectGoLive === false}
                          onChange={() => setProjectGoLive(false)}
                          required
                        />
                        <label htmlFor="check_mentorLiveProjectNo">No</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-f f-w inputs gap-15 mt-4">
                  <div className="input-group">
                    <label className="question" htmlFor="projectGoLiveComments">
                      7. If Yes, first mention the number of "Project Go Live"
                      Sessions where you were the Mentor. Then, write in detail
                      about each session including the real-based scenario
                      created by you, the questions asked by you, your role and
                      responsibilities as a Mentor, your contribution and
                      feedback along with the dates on which it was conducted.*
                    </label>
                    <textarea
                      id="projectGoLiveComments"
                      rows="3"
                      placeholder="- Type Here"
                      name="projectGoLiveComments"
                      value={projectGoLiveComments}
                      maxLength={maxLength}
                      onChange={(e) => setProjectGoLiveComments(e.target.value)}
                      required
                    ></textarea>
                    <div className="badge badge-danger">
                      Character Limit :&nbsp;
                      {maxLength -
                        Number(projectGoLiveComments?.length) +
                        "/" +
                        maxLength}
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
            {/* END: MENTORSHIP */}

            {/* BEGIN: PROJECTS */}
            <fieldset id="projects" ref={sectionRefs.projects}>
              <div className="card">
                <h3 className="heading">Projects Related Review</h3>
                <p>
                  <small>
                    HR will evaluate and assign a score out of 300 Points based
                    on the feedback
                  </small>
                  <span className="d-block">
                    In this section, you are required to mention in detail about
                    all the projects you have worked on or are currently working
                    on.
                  </span>
                </p>
                <div className="d-f f-w inputs gap-15">
                  <div className="input-group">
                    <label className="question" htmlFor="projectsCount">
                      1. No. of Projects undertaken. (last 12 months)*
                    </label>
                    <p className="label-brief">
                      <small>500 Points for each project</small>
                    </p>
                    <div className="custom-checkbox small" id="projectsCount">
                      <div>
                        <input
                          type="radio"
                          id="check_projectsCount1"
                          name="check_projectsCount"
                          value="0"
                          onChange={(e) => setNumberOfProject(e.target.value)}
                          checked={numberOfProject === "0"}
                        />
                        <label htmlFor="check_projectsCount1">0</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_projectsCount1"
                          name="check_projectsCount"
                          value="1"
                          onChange={(e) => setNumberOfProject(e.target.value)}
                          checked={numberOfProject === "1"}
                        />
                        <label htmlFor="check_projectsCount1">1</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_projectsCount2"
                          name="check_projectsCount"
                          value="2"
                          onChange={(e) => setNumberOfProject(e.target.value)}
                          checked={numberOfProject === "2"}
                        />
                        <label htmlFor="check_projectsCount2">2</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_projectsCount3"
                          name="check_projectsCount"
                          value={"3"}
                          onChange={(e) => setNumberOfProject(e.target.value)}
                          checked={numberOfProject === "3"}
                        />
                        <label htmlFor="check_projectsCount3">3</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_projectsCount4"
                          name="check_projectsCount"
                          value={"4"}
                          onChange={(e) => setNumberOfProject(e.target.value)}
                          checked={numberOfProject === "4"}
                        />
                        <label htmlFor="check_projectsCount4">4</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_projectsCount5"
                          name="check_projectsCount"
                          value={"5"}
                          onChange={(e) => setNumberOfProject(e.target.value)}
                          checked={numberOfProject === "5"}
                        />
                        <label htmlFor="check_projectsCount5">5</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_projectsCount6"
                          name="check_projectsCount"
                          value={"6"}
                          onChange={(e) => setNumberOfProject(e.target.value)}
                          checked={numberOfProject === "6"}
                        />
                        <label htmlFor="check_projectsCount6">6</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_projectsCount7"
                          name="check_projectsCount"
                          value={"7"}
                          onChange={(e) => setNumberOfProject(e.target.value)}
                          checked={numberOfProject === "7"}
                        />
                        <label htmlFor="check_projectsCount7">7</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_projectsCount8"
                          name="check_projectsCount"
                          value={"8"}
                          onChange={(e) => setNumberOfProject(e.target.value)}
                          checked={numberOfProject === "8"}
                        />
                        <label htmlFor="check_projectsCount8">8</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_projectsCount9"
                          name="check_projectsCount"
                          value={"9"}
                          onChange={(e) => setNumberOfProject(e.target.value)}
                          checked={numberOfProject === "9"}
                        />
                        <label htmlFor="check_projectsCount9">9</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_projectsCount10"
                          name="check_projectsCount"
                          value={"10"}
                          onChange={(e) => setNumberOfProject(e.target.value)}
                          checked={numberOfProject === "10"}
                        />
                        <label htmlFor="check_projectsCount10">10</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_projectsCount11"
                          name="check_projectsCount"
                          value={"11"}
                          onChange={(e) => setNumberOfProject(e.target.value)}
                          checked={numberOfProject === "11"}
                        />
                        <label htmlFor="check_projectsCount11">11</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_projectsCount12"
                          name="check_projectsCount"
                          value={"12"}
                          onChange={(e) => setNumberOfProject(e.target.value)}
                          checked={numberOfProject === "12"}
                        />
                        <label htmlFor="check_projectsCount12">12</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_projectsCount13"
                          name="check_projectsCount"
                          value={"13"}
                          onChange={(e) => setNumberOfProject(e.target.value)}
                          checked={numberOfProject === "13"}
                        />
                        <label htmlFor="check_projectsCount13">13</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_projectsCount14"
                          name="check_projectsCount"
                          value={"14"}
                          onChange={(e) => setNumberOfProject(e.target.value)}
                          checked={numberOfProject === "14"}
                        />
                        <label htmlFor="check_projectsCount14">14</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_projectsCount15"
                          name="check_projectsCount"
                          value={"15"}
                          onChange={(e) => setNumberOfProject(e.target.value)}
                          checked={numberOfProject === "15"}
                        />
                        <label htmlFor="check_projectsCount15">15</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="check_projectsCount15plus"
                          name="check_projectsCount"
                          value={"16"}
                          onChange={(e) => setNumberOfProject(e.target.value)}
                          checked={numberOfProject === "16"}
                          required
                        />
                        <label htmlFor="check_projectsCount15plus">15 +</label>
                      </div>
                    </div>
                    <div className="mt-3 d-f gap-2">
                      <p className="badge badge-success">
                        <strong>+500</strong> points
                      </p>
                    </div>
                  </div>
                </div>
                <section></section>

                <div className="d-f f-w inputs gap-15 mt-4">
                  <div className="input-group">
                    <label className="question" htmlFor="projectComments">
                      2. Project Details (Please mention "Name of the Project,
                      Client, Description of the Project, Role, Technology,
                      Duration, Responsibilities" in detail for every project)*
                    </label>
                    <textarea
                      name="projectComments"
                      id="projectComments"
                      rows="3"
                      placeholder="- Type Here"
                      value={projectComments}
                      onChange={(e) => setProjectComments(e.target.value)}
                      required
                      maxLength={maxLength}
                    ></textarea>
                    <div className="badge badge-danger">
                      Character Limit :&nbsp;
                      {maxLength -
                        Number(projectComments?.length) +
                        "/" +
                        maxLength}
                    </div>
                  </div>
                </div>

                <div className="d-f f-w inputs gap-15 mt-4">
                  <div className="input-group">
                    <label className="question" htmlFor="achievements">
                      3. Achievements/Appreciations (if any)*
                    </label>
                    <input
                      type="text"
                      id="achievements"
                      onChange={(e) => setProjectAppreciations(e.target.value)}
                      value={projectAppreciations}
                      required
                      onKeyPress={(e) => {
                        if (
                          e.charCode === 32 &&
                          e.target.selectionStart === 0
                        ) {
                          e.preventDefault(); // Prevent adding space at the beginning
                        }
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <label className="question" htmlFor="escalations">
                      Escalations (if any)*
                    </label>
                    <input
                      type="text"
                      id="escalations"
                      onChange={(e) => setProjectEscalations(e.target.value)}
                      value={projectEscalations}
                      required
                      onKeyPress={(e) => {
                        if (
                          e.charCode === 32 &&
                          e.target.selectionStart === 0
                        ) {
                          e.preventDefault(); // Prevent adding space at the beginning
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </fieldset>
            {/* END: PROJECTS */}

            <button
              type="submit"
              form="performaceEvaluationForm"
              className={isLoading ? "theme-button disabled " : "theme-button"}
            >
              {isLoading ? (
                <ThreeDots
                  height="20"
                  width="53"
                  radius="9"
                  color="white"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              ) : (
                "Save"
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Performance;
