import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { getApi, postApi } from "../../utils/api";
import { UPLOAD_USER_PHOTO, USER_PROFILE } from "../../config/Endpoints";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { TailSpin, ThreeDots } from "react-loader-spinner";
import Salary from "./Salary";
import Form16 from "./Form16";
import WorkExperience from "./WorkExperience";
import EmployeeAsset from "./EmployeeAsset";
import OrgChartExample from "../OrgChartExample";
import IndexChart from "../org-diagram";
import Education from "./Education";
import Dependents from "./Dependent";
import { logoutUser } from "../../utils/Storage";
import ChangePassword from "./ChangePassword";
import { toggleMenu } from "../../utils/helpers";
import DocumentList from "./DocumentList";
import AppraisalList from "./AppraisalList";
import Footer from "../../components/Footer";

const AdminProfile = () => {
  const [userData, setUserData] = useState({});
  const accessToken = Cookies.get("accessToken");

  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(false);

  const handleImageClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/jpeg, image/jpg, image/png"; // Accepts only JPEG, JPG, and PNG files
    const acceptedFileTypes = ["image/jpeg", "image/png", "image/jpeg"];

    input.onchange = (event) => {
      const selectedFile = event.target.files[0];
      const maxFileSize = 8 * 1024 * 1024; // 8 MB in bytes

      if (!acceptedFileTypes.includes(selectedFile.type)) {
        // File type is not supported
        toast.error(
          "Unsupported file type. Please select a valid image (PNG, JPEG and JPG) file.",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        event.target.value = null; // Clear the file input
      } else if (selectedFile.size > maxFileSize) {
        // File size exceeds the maximum allowed size
        toast.error("File size exceeds the maximum limit of 8MB.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        event.target.value = null; // Clear the file input
      } else {
        setSelectedImageFile(selectedFile);
        setSelectedImage(URL.createObjectURL(selectedFile));
      }
    };
    input.click();
  };

  const handleUploadClick = async (e) => {
    e.preventDefault();
    if (selectedImage) {
      setIsImageLoading(true);
      const formData = new FormData();
      formData.append("photo", selectedImageFile);
      await postApi(UPLOAD_USER_PHOTO, formData, accessToken)
        .then((res) => {
          if (res.status === 200) {
            setIsImageLoading(false);
            toast.success("Profile Upload Successfully!", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setSelectedImageFile(null);
          } else {
            setIsImageLoading(false);
          }
        })
        .catch((e) => {
          console.error("Upload error:", e);
          setIsImageLoading(false);
        });
    } else {
      toast.error("No image selected.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const fetchUser = () => {
    setIsLoading(true);
    getApi(USER_PROFILE, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setUserData(res.data.data);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };
  return (
    <div className="wrapper-body">
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      {isLoading && (
        <div className="centered-loader">
          <ThreeDots
            height="100"
            width="100"
            radius="9"
            color="blue"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}
      {!isLoading && (
        // <form id="assign-asset">
        <div className="card">
          <div className="row">
            <div className="col-md-2 border-right">
              <div className="d-flex flex-column align-items-center text-center p-3 py-2">
                {isImageLoading && (
                  <div className="mt-5">
                    <TailSpin
                      height="25"
                      width="80"
                      radius="9"
                      color="blue"
                      ariaLabel="tail-spin-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div>
                )}

                {!isImageLoading && (
                  <img
                    className="employee-profile"
                    width="150px"
                    src={`${
                      selectedImage
                        ? selectedImage
                        : !userData?.personalDetail?.photoPath.includes(
                            "photoDir"
                          )
                        ? "../assets/profile.png"
                        : process.env.REACT_APP_IMAGE_URL +
                          userData?.personalDetail?.photoPath
                    }`}
                    onClick={handleImageClick}
                  />
                )}
              </div>
            </div>
            <div className="col-md-8">
              <div className="d-flex flex-column  p-1 py-3">
                <h4>
                  <b>
                    {userData?.personalDetail?.firstName +
                      " " +
                      userData?.personalDetail?.lastName}
                  </b>
                </h4>
                <span className="text-black font-small">
                  - {userData?.personalDetail?.designation}
                </span>
                {userData?.personalDetail?.ccEmail && (
                  <span className="text-black font-small">
                    <a href={`mailto:${userData?.personalDetail?.ccEmail}`}>
                      <i
                        className="fa fa-envelope-o"
                        aria-hidden="true"
                        style={{ fontSize: "12px" }}
                      ></i>
                      <span className="ms-1">
                        {userData?.personalDetail?.ccEmail}
                      </span>
                    </a>
                  </span>
                )}

                {userData?.personalDetail?.phoneNumber && (
                  <span className="text-black font-small">
                    <a href={`tel:${userData?.personalDetail?.phoneNumber}`}>
                      <i
                        className="fa fa-mobile"
                        aria-hidden="true"
                        style={{ fontSize: "16px" }}
                      ></i>
                      <span className="ms-1">
                        {userData?.personalDetail?.phoneNumber}
                      </span>
                    </a>
                  </span>
                )}

                {selectedImage && selectedImageFile && (
                  <button
                    className="btn btn-primary btn-sm w-50"
                    onClick={handleUploadClick}
                  >
                    Upload Image
                  </button>
                )}
              </div>
            </div>
            <div className="col-md-2">
              <a
                className="dropdown-item btn-primary"
                style={{
                  cursor: "pointer",
                }}
                data-bs-toggle="modal"
                data-bs-target="#staticBackdropChangePassword"
              >
                Change Password
              </a>
              {/* <Link className="dropdown-item" onClick={logoutUser}>
                Logout
              </Link> */}
            </div>
          </div>
          <div className="row mt-5">
            <div className="container">
              <ul className="nav nav-tabs" id="myTabs" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="tab1-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#tab1"
                    type="button"
                    role="tab"
                    aria-controls="tab1"
                    aria-selected="true"
                  >
                    General Info
                  </button>
                </li>
                {/* <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="tab2-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#tab2"
                      type="button"
                      role="tab"
                      aria-controls="tab2"
                      aria-selected="false"
                    >
                      Job
                    </button>
                  </li> */}
                {/* <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="tab3-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#tab3"
                      type="button"
                      role="tab"
                      aria-controls="tab3"
                      aria-selected="false"
                    >
                      Leave
                    </button>
                  </li> */}
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="tab4-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#tab4"
                    type="button"
                    role="tab"
                    aria-controls="tab4"
                    aria-selected="false"
                  >
                    Asset
                  </button>
                </li>
                {/* <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="tab5-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#tab5"
                      type="button"
                      role="tab"
                      aria-controls="tab5"
                      aria-selected="false"
                    >
                      Performance
                    </button>
                  </li> */}
                {/* <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="tab6-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#tab6"
                      type="button"
                      role="tab"
                      aria-controls="tab6"
                      aria-selected="false"
                    >
                      Permission
                    </button>
                  </li> */}
                {/* <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="tab7-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#tab7"
                      type="button"
                      role="tab"
                      aria-controls="tab7"
                      aria-selected="false"
                    >
                      Documents
                    </button>
                  </li> */}
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="tab8-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#tab8"
                    type="button"
                    role="tab"
                    aria-controls="tab8"
                    aria-selected="false"
                  >
                    Salary
                  </button>
                </li>
                {/* <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="tab9-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#tab9"
                      type="button"
                      role="tab"
                      aria-controls="tab9"
                      aria-selected="false"
                    >
                      Organization Chart
                    </button>
                  </li> */}
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="tab10-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#tab10"
                    type="button"
                    role="tab"
                    aria-controls="tab10"
                    aria-selected="false"
                  >
                    Document
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="tab11-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#tab11"
                    type="button"
                    role="tab"
                    aria-controls="tab11"
                    aria-selected="false"
                  >
                    Appraisal
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="tab12-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#tab12"
                    type="button"
                    role="tab"
                    aria-controls="tab12"
                    aria-selected="false"
                  >
                    Form 16
                  </button>
                </li>
              </ul>

              <div className="tab-content" id="myTabsContent">
                <div
                  className="tab-pane fade show active mt-3"
                  id="tab1"
                  role="tabpanel"
                  aria-labelledby="tab1-tab"
                >
                  <div className="accordion" id="accordion1">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="heading1">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse1"
                          aria-expanded="false"
                          aria-controls="collapse1"
                        >
                          Basic info
                        </button>
                      </h2>
                      <div
                        id="collapse1"
                        className="accordion-collapse collapse"
                        aria-labelledby="heading1"
                        data-bs-parent="#accordion1"
                      >
                        <div className="accordion-body">
                          <div className="row">
                            <div className="col-md-3">
                              <div className="d-flex flex-column  text-black-50">
                                <p>First Name</p>
                                <p>Last Name</p>
                                <p>Employee Id</p>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="d-flex flex-column">
                                <p>{userData?.personalDetail?.firstName}</p>
                                <p>{userData?.personalDetail?.lastName}</p>
                                <p>{userData?.personalDetail?.employeeId}</p>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="d-flex flex-column  text-black-50">
                                <p>Middle Name</p>
                                <p>Email</p>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="d-flex flex-column">
                                <p>
                                  {userData?.personalDetail?.middleName
                                    ? userData?.personalDetail?.middleName
                                    : "-"}
                                </p>
                                <p>{userData?.personalDetail?.ccEmail}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="heading2">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse2"
                          aria-expanded="false"
                          aria-controls="collapse2"
                        >
                          Work
                        </button>
                      </h2>
                      <div
                        id="collapse2"
                        className="accordion-collapse collapse"
                        aria-labelledby="heading2"
                        data-bs-parent="#accordion1"
                      >
                        <div className="accordion-body">
                          <div className="row">
                            <div className="col-md-3">
                              <div className="d-flex flex-column  text-black-50">
                                <p>Department</p>
                                <p>Designation</p>
                                <p>Reporting To</p>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="d-flex flex-column">
                                <p>
                                  {userData?.personalDetail?.department
                                    ? userData?.personalDetail?.department
                                    : "-"}
                                </p>
                                <p>
                                  {userData?.personalDetail?.designation
                                    ? userData?.personalDetail?.designation
                                    : "-"}
                                </p>
                                <p>
                                  {userData?.personalDetail?.managerName
                                    ? userData?.personalDetail?.managerName
                                    : "-"}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="d-flex flex-column  text-black-50">
                                <p>Date of Joining</p>
                                <p>Employee Status</p>
                                <p>Employee Type</p>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="d-flex flex-column">
                                <p>
                                  {userData?.personalDetail?.doj
                                    ? userData?.personalDetail?.doj
                                    : "-"}
                                </p>
                                <p>
                                  {userData?.personalDetail?.status
                                    ? userData?.personalDetail?.status
                                    : "-"}
                                </p>
                                <p>
                                  {" "}
                                  {userData?.personalDetail?.employeeSubType
                                    ? userData?.personalDetail?.employeeSubType
                                    : "-"}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="heading3">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse3"
                          aria-expanded="false"
                          aria-controls="collapse3"
                        >
                          Personal Details
                        </button>
                      </h2>
                      <div
                        id="collapse3"
                        className="accordion-collapse collapse"
                        aria-labelledby="heading3"
                        data-bs-parent="#accordion1"
                      >
                        <div className="accordion-body">
                          <div className="row">
                            <div className="col-md-3">
                              <div className="d-flex flex-column  text-black-50">
                                <p>Date of Birth</p>
                                <p>Aadhar Card Number</p>
                                <p>Passport Number</p>
                                <p>PAN Number</p>
                                <p>Present Address</p>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="d-flex flex-column">
                                <p>
                                  {userData?.personalDetail?.dob
                                    ? userData?.personalDetail?.dob
                                    : "-"}
                                </p>
                                <p>
                                  {userData?.personalDetail?.aadharNum
                                    ? userData?.personalDetail?.aadharNum
                                    : "-"}
                                </p>
                                <p>
                                  {userData?.personalDetail?.passportNum
                                    ? userData?.personalDetail?.passportNum
                                    : "-"}
                                </p>
                                <p>
                                  {userData?.personalDetail?.panNum
                                    ? userData?.personalDetail?.panNum
                                    : "-"}
                                </p>
                                <p>
                                  {userData?.personalDetail?.presentAddress
                                    ? userData?.personalDetail?.presentAddress
                                    : "-"}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="d-flex flex-column  text-black-50">
                                <p>Blood Group</p>
                                <p>Gender</p>
                                <p>Marital Status</p>
                                <p>Alternate Number</p>
                                <p>Permanent Address</p>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="d-flex flex-column">
                                <p>
                                  {userData?.personalDetail?.bloodGroup
                                    ? userData?.personalDetail?.bloodGroup
                                    : "-"}
                                </p>
                                <p>
                                  {userData?.personalDetail?.gender
                                    ? userData?.personalDetail?.gender
                                    : "-"}
                                </p>
                                <p>
                                  {userData?.personalDetail?.maritalStatus
                                    ? userData?.personalDetail?.maritalStatus
                                    : "-"}
                                </p>
                                <p>
                                  {userData?.personalDetail?.alternateNumber
                                    ? userData?.personalDetail?.alternateNumber
                                    : "-"}
                                </p>
                                <p>
                                  {userData?.personalDetail?.permanentAddress
                                    ? userData?.personalDetail?.permanentAddress
                                    : "-"}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="heading4">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse4"
                          aria-expanded="false"
                          aria-controls="collapse4"
                        >
                          Work Experience
                        </button>
                      </h2>
                      <div
                        id="collapse4"
                        className="accordion-collapse collapse"
                        aria-labelledby="heading4"
                        data-bs-parent="#accordion1"
                      >
                        <div className="accordion-body">
                          <div className="row">
                            <div className="col-md-12">
                              <WorkExperience workData={userData?.experience} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="heading5">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse5"
                          aria-expanded="false"
                          aria-controls="collapse5"
                        >
                          Education
                        </button>
                      </h2>
                      <div
                        id="collapse5"
                        className="accordion-collapse collapse"
                        aria-labelledby="heading5"
                        data-bs-parent="#accordion1"
                      >
                        <div className="accordion-body">
                          <div className="row">
                            <div className="col-md-12">
                              <Education educationData={userData?.education} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="heading6">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse6"
                          aria-expanded="false"
                          aria-controls="collapse6"
                        >
                          Dependents
                        </button>
                      </h2>
                      <div
                        id="collapse6"
                        className="accordion-collapse collapse"
                        aria-labelledby="heading6"
                        data-bs-parent="#accordion1"
                      >
                        <div className="accordion-body">
                          <div className="row">
                            <div className="col-md-12">
                              <Dependents
                                dependentData={userData?.dependents}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="tab2"
                  role="tabpanel"
                  aria-labelledby="tab2-tab"
                ></div>

                <div
                  className="tab-pane fade"
                  id="tab3"
                  role="tabpanel"
                  aria-labelledby="tab3-tab"
                ></div>

                <div
                  className="tab-pane fade"
                  id="tab4"
                  role="tabpanel"
                  aria-labelledby="tab4-tab"
                >
                  <EmployeeAsset assestData={userData?.assestDetail} />
                </div>

                <div
                  className="tab-pane fade"
                  id="tab5"
                  role="tabpanel"
                  aria-labelledby="tab5-tab"
                >
                  <OrgChartExample />
                </div>

                <div
                  className="tab-pane fade"
                  id="tab6"
                  role="tabpanel"
                  aria-labelledby="tab6-tab"
                ></div>

                <div
                  className="tab-pane fade"
                  id="tab7"
                  role="tabpanel"
                  aria-labelledby="tab7-tab"
                ></div>

                <div
                  className="tab-pane fade"
                  id="tab8"
                  role="tabpanel"
                  aria-labelledby="tab8-tab"
                >
                  <Salary />
                </div>
                <div
                  className="tab-pane fade"
                  id="tab9"
                  role="tabpanel"
                  aria-labelledby="tab9-tab"
                >
                  <IndexChart />
                </div>
                <div
                  className="tab-pane fade"
                  id="tab10"
                  role="tabpanel"
                  aria-labelledby="tab10-tab"
                >
                  <DocumentList />
                </div>
                <div
                  className="tab-pane fade"
                  id="tab11"
                  role="tabpanel"
                  aria-labelledby="tab11-tab"
                >
                  <AppraisalList />
                </div>

                <div
                  className="tab-pane fade"
                  id="tab12"
                  role="tabpanel"
                  aria-labelledby="tab12-tab"
                >
                  <Form16 />
                </div>
              </div>
            </div>
          </div>
        </div>
        // </form>
      )}
      <div
        className="modal fade"
        id="staticBackdropChangePassword"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <ChangePassword />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AdminProfile;
