import React, { useState } from "react";
import { GET_EMPLOYEE_LIST } from "../../config/Endpoints";
import { getApi, putApi } from "../../utils/api";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";
import Select, { components } from "react-select";

const AssignManagerEmployee = ({ employeeList }) => {
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [selectedEmployeeId, setSelectedEmployeeId] = useState("");
  const [previousEmployeeData, setPreviousEmployeeData] = useState({});
  const accessToken = Cookies.get("accessToken");
  const [managerId, setManagerId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [manager, setManager] = useState(false);

  const handleChangeEmployee = async (e) => {
    let employeeId = e.value;
    setIsLoading(true);
    setSelectedEmployee(e);
    setSelectedEmployeeId(e.value);
    setPreviousEmployeeData({});
    await getApi(
      GET_EMPLOYEE_LIST + `/${employeeId}/manager-hierarchy`,
      accessToken
    )
      .then((res) => {
        setPreviousEmployeeData(res.data.data);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!managerId) {
        toast.error("Please select manager.", {
          position: toast.POSITION.TOP_RIGHT,
        });

        return;
      }
      setIsLoading(true);
      if (managerId !== "") {
        await putApi(
          GET_EMPLOYEE_LIST +
            "/" +
            selectedEmployeeId +
            `/manager?newManagerId=${managerId}`,
          {},
          accessToken
        )
          .then((res) => {
            if (res.status === 200) {
              setIsLoading(false);
              setPreviousEmployeeData(res?.data?.data);
              const modalClose = document.getElementsByClassName("cancel")[0];
              modalClose.click();
              toast.success("Successfully Assigned!", {
                position: toast.POSITION.TOP_RIGHT,
              });
              setManager("");
            } else {
            }
          })
          .catch((e) => {
            setIsLoading(false);
            toast.error(e?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            console.log(e);
          });
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleReset = () => {
    setSelectedEmployee("");
    setPreviousEmployeeData("");
    setManagerId("");
    setManager("");
  };

  const optionList = employeeList.map((item) => ({
    value: item.id,
    label: item.empName,
    type: item.employeeType,
  }));

  const Option = (props) => {
    const { data } = props;
    const { label, type } = data;

    return (
      <components.Option {...props}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>{label}</span>
          <span style={{ fontSize: "12px", color: "gray" }}>({type})</span>
        </div>
      </components.Option>
    );
  };

  const handleManager = (e) => {
    setManager(e);
    setManagerId(e.value);
  };

  return (
    <>
      <div className="card">
        <form id="assign-asset">
          <h3 className="heading">Assign Manager</h3>
          <div className="inputs">
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-6">
                <div className="dropdown-container">
                  <Select
                    options={optionList}
                    components={{ Option }}
                    onChange={handleChangeEmployee}
                    value={selectedEmployee}
                    placeholder="Select Employee"
                    isSearchable={true}
                    isDisabled={employeeList?.length === 0}
                    isRequired={true}
                  />
                </div>
              </div>
              <div className="col-md-2"></div>
            </div>
          </div>
        </form>
      </div>
      {Object.keys(previousEmployeeData).length === 0 && isLoading && (
        <div className="centered-loader">
          <ThreeDots
            height="100"
            width="100"
            radius="9"
            color="blue"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}
      {Object.keys(previousEmployeeData).length > 0 && (
        <div className="table-responsive">
          <table className="resume custom">
            <thead>
              <tr>
                <th>Id</th>
                <th>Name</th>
                <th>Assigned Manager</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{previousEmployeeData?.id}</td>
                <td>{previousEmployeeData?.name}</td>
                <td>
                  {previousEmployeeData?.manager?.name
                    ? previousEmployeeData?.manager?.name
                    : "Not Assigned"}
                </td>
                <td>
                  <button
                    className="btn btn-secondary"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdropReassignManager"
                    onClick={() =>
                      handleManager({
                        label: previousEmployeeData?.manager?.name,
                        value: previousEmployeeData?.manager?.id,
                      })
                    }
                  >
                    {previousEmployeeData?.manager?.name
                      ? "Re-assign"
                      : "Assign"}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      <div
        className="modal fade"
        id="staticBackdropReassignManager"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <form onSubmit={handleSubmit}>
              <div className="modal-header">
                <h5
                  className="modal-title text-center flex"
                  id="staticBackdropLabel"
                >
                  Assign Manager
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setManagerId("");
                    setManager("");
                  }}
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-2"></div>
                  <div className="col-md-4">
                    <h6>Employee Name</h6>
                  </div>

                  <div className="col-md-4">
                    <h6>{previousEmployeeData?.name}</h6>
                  </div>
                  <div className="col-md-2"></div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-2"></div>
                  <div className="col-md-4">
                    <label htmlFor="manager">Assign Manager</label>
                  </div>
                  <div className="col-md-4">
                    <div className="dropdown-container">
                      <Select
                        options={optionList}
                        components={{ Option }}
                        onChange={handleManager}
                        value={manager}
                        placeholder="Select employee"
                        isSearchable={true}
                        isDisabled={employeeList?.length === 0}
                        isRequired={true}
                        required
                      />
                    </div>
                    {/* setManagerId */}
                    {/* <select
                      id="manager"
                      className="border"
                      required
                      name="manager"
                      value={managerId}
                      onChange={(e) => setManagerId(e.target.value)}
                    >
                      <option value="">Select Manager</option>
                      {employeeList &&
                        employeeList.map((item) => (
                          <option value={item.id} key={item.id}>
                            {item.firstName}
                          </option>
                        ))}
                    </select> */}
                  </div>
                  <div className="col-md-2"></div>
                </div>
              </div>

              <div className="justify-content-center modal-footer">
                <button
                  type="submit"
                  className={
                    isLoading ? "theme-button disabled" : "theme-button"
                  }
                >
                  {isLoading ? (
                    <ThreeDots
                      height="20"
                      width="53"
                      radius="9"
                      color="white"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    "Submit"
                  )}
                </button>
                <button
                  type="button"
                  className="theme-button bg-grey mx-3 w-35 cancel"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    setManagerId("");
                    setManager("");
                  }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssignManagerEmployee;
