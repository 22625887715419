import { useEffect, useState } from "react";
import {
  API_CREATE_DEPARTMENT,
  API_UPDATE_DEPARTMENT,
  GET_DEPARTMENT_LIST,
} from "../../config/Endpoints";
import Cookies from "js-cookie";
import { getApi, postApi, putApi } from "../../utils/api";
import { toggleMenu } from "../../utils/helpers";
import { Link } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import moment from "moment";
import Pagination from "../../components/Pagination";
import Footer from "../../components/Footer";
import { toast } from "react-toastify";

const DepartmentList = () => {
  const [departmentList, setDepartmentList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const accessToken = Cookies.get("accessToken");
  const [filteredItems, setFilteredItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [selectedDepartment, setSelectedDepartment] = useState({});

  const [formData, setFormData] = useState({
    name: "",
  });

  useEffect(() => {
    fetchDepartmentList();
  }, []);

  const fetchDepartmentList = () => {
    setIsLoading(true);
    getApi(GET_DEPARTMENT_LIST, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setDepartmentList(res.data.data);
          setFilteredItems(res.data.data);
          setTotalPages(res.data.data.totalPages);
          setTotalRecord(res.data.data.totalElements);
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);
    const filtered = departmentList.filter((item) =>
      item.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredItems(filtered);
  };

  //   const handlePageChange = (newPage) => {
  //     setCurrentPage(newPage);
  //   };
  //   const handleLimitChange = (e) => {
  //     setLimit(e.target.value);
  //     setCurrentPage(1);
  //   };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleReset = () => {
    setFormData({
      name: "",
    });
    setSelectedDepartment({});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoadingSubmit(true);
      const requestData = {
        name: formData.name,
        status: formData.status,
      };

      await putApi(API_CREATE_DEPARTMENT, requestData, accessToken)
        .then(async (res) => {
          if (res?.data?.data) {
            await fetchDepartmentList();
            setIsLoadingSubmit(false);
            const modalClose =
              document.getElementsByClassName("cancel-modal")[0];
            modalClose.click();
            toast.success("Department created successfully!", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((e) => {
          toast.error(e?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsLoadingSubmit(false);
        });
    } catch (error) {
      console.log(error);
      setIsLoadingSubmit(false);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const requestData = {
        name: formData.name,
        status: formData.status,
        id: selectedDepartment?.id,
      };
      setIsLoadingSubmit(true);

      await postApi(API_UPDATE_DEPARTMENT, requestData, accessToken)
        .then(async (res) => {
          if (res?.data?.data) {
            await fetchDepartmentList();
            setIsLoadingSubmit(false);
            const modalClose =
              document.getElementsByClassName("cancel-modal")[0];
            modalClose.click();
            toast.success("Department successfully updated!", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((e) => {
          toast.error(e?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsLoadingSubmit(false);
        });
    } catch (error) {
      console.log(error);
      setIsLoadingSubmit(false);
    }
  };

  const handleView = (item) => {
    setSelectedDepartment(item);
    setFormData({
      name: item?.name,
    });
  };

  return (
    <div className="wrapper-body">
      {/* Page Heading */}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <h1>Departments</h1>
        <div className="total">
          Total Department : <span>{departmentList?.length}</span>
        </div>
      </div>

      {/* Filters */}
      <div className="filters">
        {/* <form action="index.html" id="form_filters" className="d-f"> */}
        {/* Search By Company */}
        <div className="search">
          <input
            type="text"
            name="search"
            placeholder="Search By Name."
            value={searchTerm}
            onChange={handleSearch}
          />
          <img src="/assets/images/search-ico.svg" width="14" alt="Search" />
        </div>
        {/* <button type="submit" form="form_filters">
                Search
              </button> */}
        {/* </form> */}

        {/* Actions */}
        <div className="actions">
          <Link
            className="export theme-button"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdropAddDepartment"
          >
            + Add Department
          </Link>
        </div>
      </div>

      {/* Data Table */}
      {isLoading && (
        <div className="centered-loader">
          <ThreeDots
            height="100"
            width="100"
            radius="9"
            color="blue"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}
      {!isLoading && filteredItems?.length === 0 && (
        <div className="fs-4 text-secondary text-center">
          No Record Available
        </div>
      )}
      {!isLoading && filteredItems?.length > 0 && (
        <>
          <div className="table-responsive">
            <table className="resume custom">
              {/* Table Headings */}
              <thead>
                <tr>
                  <th>Serial No.</th>
                  <th>Name</th>
                  <th>Creation Date</th>
                  {/* <th>Last Modified Date</th> */}
                  <th>Status</th>
                  <th className="text-center">Actions</th>
                </tr>
              </thead>

              {/* Table Body */}
              <tbody>
                {filteredItems &&
                  filteredItems.map((item, index) => (
                    <tr key={index}>
                      <td data-title="Serial_No">{index + 1}</td>
                      <td data-title="Name">{item.name}</td>
                      <td data-title="creationDate">
                        {moment(item.creationDate).format("DD-MM-yyyy")}
                      </td>
                      {/* <td data-title="lastModifiedDate">
                        {moment(item.lastModifiedDate).format("DD-MM-yyyy")}
                      </td> */}
                      <td data-title="Phone">{item.status}</td>

                      <td className="text-center">
                        <a
                          href="javascript:void(0)"
                          onClick={() => handleView(item)}
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdropAddDepartment"
                          className="ms-2"
                        >
                          <i class="fa fa-edit"></i>
                        </a>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          {/* <div className="row">
            <div className="col-md-3">
              <div>
                Dropdown for selecting items per page
                <label htmlFor="itemsPerPage">Items per page </label>
                <select
                  id="itemsPerPage"
                  onChange={handleLimitChange}
                  value={limit}
                  className="border ms-3 w-25  p-2"
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={30}>30</option>
                  <option value={40}>40</option>
                </select>
              </div>
            </div>
            <div className="col-md-9">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </div> */}
        </>
      )}
      <div
        className="modal fade"
        id="staticBackdropAddDepartment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropAddDepartment"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <>
              <div className="modal-header">
                <h5
                  className="modal-title text-center flex"
                  id="staticBackdropLabel"
                >
                  {selectedDepartment?.id ? "Update " : "Add "} Department
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleReset}
                ></button>
              </div>
              <form
                onSubmit={selectedDepartment?.id ? handleUpdate : handleSubmit}
              >
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-4">
                      <label className="has-asterisk">Name</label>
                    </div>

                    <div className="col-md-5">
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        placeholder="Enter Department Name"
                        required
                        className="border"
                        maxLength={150}
                        onKeyPress={(e) => {
                          if (
                            e.charCode === 32 &&
                            e.target.selectionStart === 0
                          ) {
                            e.preventDefault(); // Prevent adding space at the beginning
                          }
                        }}
                      />
                    </div>
                    <div className="col-md-1"></div>
                  </div>
                  {/* <div className="row mt-3">
                    <div className="col-md-2"></div>
                    <div className="col-md-4">
                      <label htmlFor="repairCost" className="has-asterisk">
                        Status
                      </label>
                    </div>
                    <div className="col-md-5 ">
                      <div className="input-group">
                        <select
                          id="status"
                          value={formData.status}
                          required
                          onChange={handleInputChange}
                          name="status"
                          className="border"
                        >
                          <option value="">Select Status</option>
                          <option value="VERIFIED">Verified</option>
                          <option value="PENDING">Pending</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-1"></div>
                  </div> */}
                </div>
                <div className="justify-content-center modal-footer">
                  <button
                    type="submit"
                    className={
                      isLoadingSubmit
                        ? "theme-button disabled "
                        : "theme-button "
                    }
                  >
                    {isLoadingSubmit ? (
                      <ThreeDots
                        height="20"
                        width="53"
                        radius="9"
                        color="white"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    ) : selectedDepartment?.id ? (
                      "Update "
                    ) : (
                      "Submit"
                    )}
                  </button>
                  <button
                    type="button"
                    className="theme-button bg-grey mx-3 w-35 cancel-modal"
                    data-bs-dismiss="modal"
                    onClick={handleReset}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </>
          </div>
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default DepartmentList;
