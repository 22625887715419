import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { getApi, putApi } from "../../utils/api";
import {
  API_PROMOTE_EMPLOYEE,
  GET_DEPARTMENT_LIST,
  GET_DESIGNATION_LIST,
  GET_EMPLOYEE_LIST,
} from "../../config/Endpoints";
import { TailSpin, ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import Select, { components } from "react-select";
import { toggleMenu } from "../../utils/helpers";
import Calendar from "./Calendar";
import Footer from "../../components/Footer";
import MyAttendanceCalendar from "./MyAttendanceCalendar";

const PunchDetail = () => {
  const [employeeList, setEmployeeList] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState({});
  const [selectedEmployeeSelect, setSelectedEmployeeSelect] = useState("");
  const [isListLoading, setIsListLoading] = useState(false);
  const accessToken = Cookies.get("accessToken");

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = () => {
    setIsListLoading(true);
    getApi(GET_EMPLOYEE_LIST, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setEmployeeList(res.data.data);
          setIsListLoading(false);
        }
      })
      .catch((e) => {
        setIsListLoading(false);
      });
  };

  const handleChangeEmployee = async (e) => {
    setSelectedEmployee({});
    let employeeId = e.value;
    setSelectedEmployeeSelect(e);
    let data = employeeList.filter((item) => item.id === Number(employeeId));
    setSelectedEmployee(data?.[0]);
  };

  const optionList = employeeList?.map((item) => ({
    value: item.id,
    label: item.empName,
    type: item.employeeType,
  }));

  const Option = (props) => {
    const { data } = props;
    const { label, type } = data;

    return (
      <components.Option {...props}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>{label}</span>
          <span style={{ fontSize: "12px", color: "gray" }}>({type})</span>
        </div>
      </components.Option>
    );
  };

  return (
    <div className="wrapper-body">
      {isListLoading && (
        <div className="overlay">
          <div className="mt-5">
            <TailSpin
              height="100"
              width="100"
              radius="1"
              color="blue"
              ariaLabel="tail-spin-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}
      {/* Page Heading */}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <h1>Punch Details</h1>
      </div>
      <div className="card">
        <form id="assign-asset">
          <h3 className="heading">Employee</h3>
          <div className="inputs">
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-6">
                <div className="dropdown-container">
                  <Select
                    options={optionList}
                    components={{ Option }}
                    onChange={handleChangeEmployee}
                    value={selectedEmployeeSelect}
                    placeholder="Select Employee"
                    isSearchable={true}
                    isDisabled={employeeList?.length === 0}
                    isRequired={true}
                  />
                </div>
              </div>
              <div className="col-md-2"></div>
            </div>
          </div>
        </form>
      </div>

      {Object.keys(selectedEmployee).length > 0 && (
        <MyAttendanceCalendar selectedEmployee={selectedEmployee} />
      )}

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default PunchDetail;
