import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { putApi } from "../../utils/api";
import { generatePassword } from "../../utils/helpers";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";
import { API_V2_UPDATE_CC_EMAIL } from "../../config/Endpoints";

const UpdateOnboardingView = ({
  selectedEmployee,
  fetchApprovedUser,
  handleReset,
}) => {
  const [CCEmail, setCCEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const accessToken = Cookies.get("accessToken");
  useEffect(() => {}, [selectedEmployee]);

  const handleCancel = () => {
    setCCEmail("");
    handleReset();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      // Check if email contains '@companyname'
      if (!CCEmail.endsWith("@caeliusconsulting.com")) {
        setIsLoading(false);
        toast.error(
          "Please use a company email address ending with @caeliusconsulting.com",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        return;
      }

      await putApi(
        API_V2_UPDATE_CC_EMAIL +
          `?email=${CCEmail}&employeeId=${selectedEmployee.id}`,
        {},
        accessToken
      )
        .then((res) => {
          if (res.status === 200) {
            setIsLoading(false);
            setCCEmail("");
            fetchApprovedUser();
            const modalClose = document.getElementsByClassName(
              "cancel-update-onboards"
            )[0];
            modalClose.click();
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              // closeOnClick: true,
              // autoClose: false,
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((e) => {
          setIsLoading(false);

          toast.error(e.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="modal-body mt-3">
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <label>First Name</label>
          </div>

          <div className="col-md-4">
            <h6 className="truncate-text">{selectedEmployee?.firstName}</h6>
          </div>
          <div className="col-md-2"></div>
        </div>
        <div className="row mt-3">
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <label>Last Name</label>
          </div>
          <div className="col-md-4">
            <h6 className="truncate-text">{selectedEmployee?.lastName}</h6>
          </div>
          <div className="col-md-2"></div>
        </div>
        <div className="row mt-3">
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <label htmlFor="manager">Suggested Password (Caelius Email) </label>
          </div>
          <div className="col-md-4">
            <h6>
              {selectedEmployee?.firstName &&
                generatePassword(
                  selectedEmployee?.firstName,
                  selectedEmployee?.dob
                )}
            </h6>
          </div>
          <div className="col-md-2"></div>
        </div>

        <div className="row mt-3">
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <label htmlFor="manager" className="has-asterisk">
              Caelius Email
            </label>
          </div>
          <div className="col-md-4">
            <input
              type="email"
              name="email"
              value={CCEmail}
              onChange={(e) => setCCEmail(e.target.value)}
              placeholder="Enter employee email"
              maxLength={100}
              required
              className="border"
            />
          </div>
        </div>
      </div>

      <div className="justify-content-center modal-footer">
        <button
          type="submit"
          className={isLoading ? "theme-button disabled " : "theme-button "}
        >
          {isLoading ? (
            <ThreeDots
              height="20"
              width="53"
              radius="9"
              color="white"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          ) : (
            "Submit"
          )}
        </button>
        <button
          type="button"
          className="theme-button bg-grey mx-3 w-35 cancel-update-onboards"
          data-bs-dismiss="modal"
          onClick={handleCancel}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default UpdateOnboardingView;
