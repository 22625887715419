import React from "react";
// import { Chart } from "react-google-charts";

const OrgChartExample = () => {
  //   const chartConfig = {
  //     nodes: [
  //       { id: 1, name: "John Doe", title: "CEO" },
  //       { id: 2, pid: 1, name: "Jane Smith", title: "COO" },
  //     ],
  //   };
  //   return (
  //     <div>
  //       <OrgChart datasource={chartConfig} />
  //     </div>
  //   );

  return (
    <div>hello</div>
    // <Chart
    //   chartType="ScatterChart"
    //   data={[
    //     ["Age", "Weight"],
    //     [4, 5.5],
    //     [8, 12],
    //   ]}
    //   width="100%"
    //   height="400px"
    //   legendToggle
    // />
  );
};

export default OrgChartExample;
