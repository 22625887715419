import { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import { toggleMenu } from "../../utils/helpers";
import AssignLeaveManager from "./AssignLeaveManager";
import AssignManagerEmployee from "./AssignManagerEmployee";
import { TailSpin } from "react-loader-spinner";
import { GET_EMPLOYEE_LIST } from "../../config/Endpoints";
import Cookies from "js-cookie";
import { getApi } from "../../utils/api";

const AssignMain = () => {
  const [employeeList, setEmployeeList] = useState([]);
  const [isListLoading, setIsListLoading] = useState(false);
  const accessToken = Cookies.get("accessToken");

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = () => {
    setIsListLoading(true);
    getApi(GET_EMPLOYEE_LIST, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setEmployeeList(res.data.data);
          setIsListLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsListLoading(false);
      });
  };

  return (
    <div className="wrapper-body">
      {/* Page Heading */}
      {isListLoading && (
        <div className="overlay">
          <div className="mt-5">
            <TailSpin
              height="100"
              width="100"
              radius="1"
              color="blue"
              ariaLabel="tail-spin-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <h1> Assign Manager</h1>
      </div>

      <ul className="nav nav-tabs" id="myassign" role="tablist">
        <li className="nav-item" role="presentation">
          <button
            className="nav-link active"
            id="tab1-tab"
            data-bs-toggle="tab"
            data-bs-target="#tab1"
            type="button"
            role="tab"
            aria-controls="tab1"
            aria-selected="true"
          >
            Manager
          </button>
        </li>
        <li className="nav-item ms-2" role="presentation">
          <button
            className="nav-link"
            id="tab2-tab"
            data-bs-toggle="tab"
            data-bs-target="#tab2"
            type="button"
            role="tab"
            aria-controls="tab2"
            aria-selected="false"
          >
            Leave Manager
          </button>
        </li>
      </ul>

      <div className="tab-content" id="myassignContent">
        <div
          className="tab-pane fade show active "
          id="tab1"
          role="tabpanel"
          aria-labelledby="tab1-tab"
        >
          <AssignManagerEmployee employeeList={employeeList} />
        </div>

        <div
          className="tab-pane fade"
          id="tab2"
          role="tabpanel"
          aria-labelledby="tab2-tab"
        >
          <AssignLeaveManager employeeList={employeeList} />
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default AssignMain;
