import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  GET_INVITATION_ONBOARDING_EMPLOYEE_LIST,
  GET_ONBOARDING_EMPLOYEE_LIST,
  GET_ONBOARDING_EMPLOYEE_LIST_PAGINATION,
} from "../../config/Endpoints";
import Cookies from "js-cookie";
import { getApi } from "../../utils/api";
import AssignManager from "../AssignManager/AssignManager";
import SendEmployeeOnboarding from "./SendEmployeeOnboarding";
import { ThreeDots } from "react-loader-spinner";
import { toggleMenu } from "../../utils/helpers";
import Pagination from "../../components/Pagination";
import moment from "moment";
import Footer from "../../components/Footer";

const InvitationList = () => {
  const [employeeList, setEmployeeList] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState({});
  const [isLoading, setisLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredItems, setFilteredItems] = useState();

  const accessToken = Cookies.get("accessToken");
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "",
  });
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    fetchList();
  }, [currentPage, limit]);

  const fetchList = () => {
    setisLoading(true);
    getApi(
      GET_INVITATION_ONBOARDING_EMPLOYEE_LIST +
        `?status=pending&pageSize=${limit}&page=${currentPage}`,
      accessToken
    )
      .then((res) => {
        if (res.status === 200) {
          setEmployeeList(res.data.data.data);
          setFilteredItems(res.data.data.data);
          setTotalPages(res.data.data.totalPages);
          setTotalRecord(res.data.data.totalElements);
          setisLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setisLoading(false);
      });
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);
    const filtered = employeeList.filter((item) =>
      item.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredItems(filtered);
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
    const sortedData = [...filteredItems].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setFilteredItems(sortedData);
  };

  return (
    <div className="wrapper-body">
      {/* Page Heading */}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <h1>Invitations</h1>
        <div className="total">
          Total Employees : <span>{totalRecord}</span>
        </div>
      </div>

      {/* Filters */}
      <div className="filters">
        {/* <form action="index.html" id="form_filters" className="d-f"> */}
        {/* Search By Company */}
        <div className="search">
          <input
            type="text"
            name="search"
            placeholder="Search By Name."
            value={searchTerm}
            onChange={handleSearch}
          />
          <img src="/assets/images/search-ico.svg" width="14" alt="Search" />
        </div>
        {/* <button type="submit" form="form_filters">
              Search
            </button> */}
        {/* </form> */}

        {/* Actions */}
        <div className="actions">
          <Link
            className="export theme-button"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdropSend"
          >
            Invite Employee
          </Link>
        </div>
      </div>

      {/* Data Table */}
      {isLoading && (
        <div className="centered-loader">
          <ThreeDots
            height="100"
            width="100"
            radius="9"
            color="blue"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}
      {!isLoading && filteredItems?.length === 0 && (
        <div className="fs-4 text-secondary text-center">
          No Record Available
        </div>
      )}
      {!isLoading && filteredItems?.length > 0 && (
        <>
          <div className="table-responsive">
            <table className="resume custom">
              {/* Table Headings */}
              <thead>
                <tr>
                  <th>Serial No.</th>
                  <th>
                    Name{" "}
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                      onClick={() => handleSort("name")}
                    />
                  </th>
                  <th>
                    Email{" "}
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                      onClick={() => handleSort("email")}
                    />
                  </th>
                  <th>
                    Invitation sent on Date{" "}
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                      onClick={() => handleSort("createdOn")}
                    />
                  </th>
                  <th>
                    Status{" "}
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                      onClick={() => handleSort("status")}
                    />
                  </th>
                  {/* <th className="text-center">Actions</th> */}
                </tr>
              </thead>

              {/* Table Body */}
              <tbody>
                {filteredItems &&
                  filteredItems.map((item, index) => (
                    <tr key={index}>
                      <td data-title="Serial_No">{index + 1}</td>
                      <td data-title="Name">{item.name}</td>
                      <td data-title="Email">{item.email}</td>
                      <td data-title="ID">
                        {moment(item.createdOn).format("DD-MM-yyyy")}
                      </td>
                      <td data-title="Phone">{item.status}</td>

                      {/* <td className="text-center">
                        <a
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdrop"
                          onClick={() => setSelectedEmployee(item)}
                        >
                          View
                        </a>
                      </td> */}
                      <td className="text-center"></td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div>
                {/* Dropdown for selecting items per page */}
                <label htmlFor="itemsPerPage">Items per page </label>
                <select
                  id="itemsPerPage"
                  onChange={handleLimitChange}
                  value={limit}
                  className="border ms-3 w-25  p-2"
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={30}>30</option>
                  <option value={40}>40</option>
                </select>
              </div>
            </div>
            <div className="col-md-9">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </>
      )}
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <AssignManager
              selectedEmployee={selectedEmployee}
              fetchList={fetchList}
              titleHeading="Employee Information"
              isEditable={false}
            />
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="staticBackdropSend"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <SendEmployeeOnboarding />
          </div>
        </div>
      </div>
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default InvitationList;
