import { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import { API_ASSET_EXPORT, ASSET_TYPE_LIST } from "../../config/Endpoints";
import { getApi } from "../../utils/api";
import Cookies from "js-cookie";
import { toggleMenu } from "../../utils/helpers";
import { TailSpin } from "react-loader-spinner";
import { toast } from "react-toastify";

const AssetReport = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [assetTypeList, setAssetTypeList] = useState([]);
  const accessToken = Cookies.get("accessToken");
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [formData, setFormData] = useState({
    filterOwner: "",
    filterAssetType: "",
  });
  useEffect(() => {
    fetchAssetTypeList();
  }, []);

  const fetchAssetTypeList = async () => {
    setIsLoading(true);
    await getApi(ASSET_TYPE_LIST, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setAssetTypeList(res.data.data);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  const handleExport = async (e) => {
    e.preventDefault();
    setIsExportLoading(true);
    await fetch(
      `${API_ASSET_EXPORT}?owner=${formData.filterOwner}&assestTypeId=${formData.filterAssetType}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/vnd.ms-excel",
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          toast.error(" No Record Available!", {
            position: toast.POSITION.TOP_RIGHT,
          });
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        // Create a URL object from the blob
        const url = URL.createObjectURL(blob);

        // Create a temporary link element to trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.download = `asset_list.xlsx`;
        link.click();

        // Clean up the URL object
        URL.revokeObjectURL(url);
        setIsExportLoading(false);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
        setIsExportLoading(false);
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleReset = () => {
    setFormData({
      filterOwner: "",
      filterAssetType: "",
    });
  };

  return (
    <div className="wrapper-body">
      {isLoading && (
        <div className="overlay">
          <div className="loader-container">
            <TailSpin
              height="100"
              width="100"
              radius="1"
              color="blue"
              ariaLabel="tail-spin-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <h1></h1>
        {/* <div className="total"> */}
        {/* <Link to="/dashboard/reimbursement" className="brand-color">
        Back to Reimbursement Request List
      </Link> */}
        {/* </div> */}
      </div>
      <form id="assign-asset" onSubmit={handleExport}>
        <div className="card">
          <h3 className="heading">Download Asset Report</h3>
          <div className="inputs">
            <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-2">
                <label htmlFor="reimbursementType" className="has-asterisk">
                  Asset Type
                </label>
              </div>
              <div className="col-md-5">
                <div className="input-group">
                  <select
                    id="filterAssetType"
                    value={formData.filterAssetType}
                    required
                    onChange={handleInputChange}
                    name="filterAssetType"
                    disabled={isLoading}
                  >
                    <option value="">Select an asset type</option>
                    <option value={0}>All</option>
                    {assetTypeList &&
                      assetTypeList.map((item) => (
                        <option value={item.assestId} key={item.assestId}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="col-md-3"></div>
            </div>

            <div className="row mt-1">
              <div className="col-md-2"></div>
              <div className="col-md-2 ">
                <label htmlFor="year" className="has-asterisk">
                  Owner
                </label>
              </div>
              <div className="col-md-5">
                <div className="input-group">
                  <select
                    id="filterOwner"
                    value={formData.filterOwner}
                    required
                    onChange={handleInputChange}
                    name="filterOwner"
                  >
                    <option value="">Select an owner</option>
                    <option value="ALL">All</option>
                    <option value="Caelius">Caelius</option>
                    <option value="Salesforce">Salesforce</option>
                    <option value="Consultant">Consultant</option>
                    <option value="SQE">SQE Labs</option>
                  </select>
                </div>
              </div>
              <div className="col-md-3"></div>
            </div>
          </div>
          <br />

          <div className="action text-center">
            <button className="btn btn-secondary" onClick={handleReset}>
              Reset
            </button>
            <button
              className={
                isLoading ? "theme-button disabled ms-2 " : "theme-button ms-2 "
              }
              type="submit"
            >
              Download
            </button>
          </div>
        </div>
      </form>

      <Footer />
    </div>
  );
};

export default AssetReport;
