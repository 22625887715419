import Cookies from "js-cookie";
import React, { useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { API_REIMBURSEMENT } from "../../config/Endpoints";
import { postApi } from "../../utils/api";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { toggleMenu } from "../../utils/helpers";
import Footer from "../../components/Footer";

const Reimbursement = () => {
  const accessToken = Cookies.get("accessToken");
  const [formData, setFormData] = useState({
    date: "",
    distance: "",
    receiptFile: null,
    fromPlace: "",
    reimbursementAmount: "",
    reimbursementType: "",
    toPlace: "",
    vehicleType: "",
    comment: "",
    invoiceNo: "",
    toDate: "",
    fromDate: "",
    invoiceNo: "",
    fromPeriod: "",
    toPeriod: "",
  });
  const [isLoading, setIsLoading] = useState();
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  // Get today's date in the format yyyy-mm-dd
  const today = new Date().toISOString().split("T")[0];

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await ReimbursementSchema.validate(formData, { abortEarly: false });
      setIsLoading(true);
      const formdata = new FormData();
      formdata.append("reimbursementType", formData.reimbursementType);
      formdata.append("reimbursementAmount", formData.reimbursementAmount);
      formdata.append("date", formData.date);
      formdata.append("invoiceNo", formData.invoiceNo);
      formdata.append("fromPeriod", formData.fromDate);
      formdata.append("toPeriod", formData.toDate);
      formdata.append("vehicleType", formData.vehicleType);
      formdata.append("distance", formData.distance);
      formdata.append("fromPlace", formData.fromPlace);
      formdata.append("toPlace", formData.toPlace);
      formdata.append("receipt", formData.receiptFile);
      formdata.append("comments", formData.comment);

      await postApi(API_REIMBURSEMENT, formdata, accessToken)
        .then((res) => {
          toast.success("Successfully Sent!", {
            position: toast.POSITION.TOP_RIGHT,
          });
          handleReset();
          setIsLoading(false);
          navigate("/dashboard/reimbursement");
          // if (res.status === 200) {
          //   setIsLoading(false);

          //   toast.success("Successfully deallocated!", {
          //     position: toast.POSITION.TOP_RIGHT,
          //   });
          // } else {
          //   setIsLoading(false);
          // }
        })
        .catch((e) => {
          toast.error(e?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsLoading(false);
        });
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });
      setErrors(validationErrors);
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    setFormData({
      date: "",
      distance: "",
      receiptFile: null,
      fromPlace: "",
      reimbursementAmount: "",
      reimbursementType: "",
      toPlace: "",
      vehicleType: "",
      comment: "",
      invoiceNo: "",
      toDate: "",
      fromDate: "",
      invoiceNo: "",
      fromPeriod: "",
      toPeriod: "",
    });
    setErrors({});
    document.getElementById("file-input").value = "";
  };

  const handleInputChange = (event) => {
    const { name, value, type } = event.target;
    if (type === "file") {
      const file = event.target.files[0];
      // Define an array of accepted file types
      // const acceptedFileTypes = ["application/pdf", "application/msword"];
      const acceptedFileTypes = [
        "application/pdf",
        "application/msword",
        "image/jpeg",
        "image/png",
        "image/jpeg",
      ];

      if (acceptedFileTypes.includes(file.type)) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: file,
        }));
      } else {
        // File type is not supported, show an alert
        toast.error(
          "Unsupported file type. Please upload a valid document, PDF, JPG, PNG and JPEG.",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );

        event.target.value = null; // Clear the file input
      }
    }
    //  else if (type === "date") {
    // if (name === "date") {
    //   const year = value.split("-")[0];
    //   if (year.length <= 4) {
    //     const currentDate = new Date().toISOString().split("T")[0];
    //     // If the entered date is greater than the current date, revert to the current date
    //     if (value > currentDate) {
    //       toast.error(
    //         "Future dates are not allowed. Input reverted to current date.",
    //         {
    //           position: toast.POSITION.TOP_RIGHT,
    //         }
    //       );
    //       setFormData((prevFormData) => ({
    //         ...prevFormData,
    //         [name]: currentDate,
    //       }));
    //     } else {
    //       setFormData((prevFormData) => ({
    //         ...prevFormData,
    //         [name]: value,
    //       }));
    //     }
    //   }
    // }

    // if (name !== "date") {
    // const year = value.split("-")[0];
    // if (year.length <= 4) {
    //   setFormData((prevFormData) => ({
    //     ...prevFormData,
    //     [name]: value,
    //   }));
    // }
    // }
    // }
    else if (type === "date") {
      const year = value.split("-")[0];
      if (year.length <= 4) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  return (
    <div className="wrapper-body">
      {isLoading && (
        <div className="overlay">
          <div className="loader-container">
            <TailSpin
              height="100"
              width="100"
              radius="1"
              color="blue"
              ariaLabel="tail-spin-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <h1></h1>
        <div className="total">
          <Link to="/dashboard/reimbursement" className="brand-color">
            Back to Reimbursement
          </Link>
        </div>
      </div>
      <form id="assign-asset" onSubmit={handleSubmit}>
        <div className="card">
          <h3 className="heading">Reimbursement</h3>
          <div className="inputs">
            <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-2">
                <label htmlFor="reimbursementType" className="has-asterisk">
                  Reimbursement Type
                </label>
              </div>
              <div className="col-md-5">
                <div className="input-group">
                  <select
                    id="reimbursementType"
                    required
                    name="reimbursementType"
                    value={formData.reimbursementType}
                    onChange={handleInputChange}
                  >
                    <option value="">Select type</option>
                    <option>Internet Claim</option>
                    <option>Travel Expense</option>
                    <option>Miscellaneous</option>
                  </select>
                </div>
              </div>
              <div className="col-md-3"></div>
            </div>

            <div className="row mt-1">
              <div className="col-md-2"></div>
              <div className="col-md-2 ">
                <label htmlFor="date" className="has-asterisk">
                  Invoice Date
                </label>
              </div>
              <div className="col-md-5">
                <div className="input-group">
                  <input
                    type="date"
                    name="date"
                    required
                    onChange={handleInputChange}
                    value={formData.date}
                    max={today}
                  />
                </div>
                {errors.date && (
                  <small className="text-danger">{errors.date}</small>
                )}
              </div>
              <div className="col-md-3"></div>
            </div>
            <div className="row mt-1">
              <div className="col-md-2"></div>
              <div className="col-md-2 ">
                <label htmlFor="invoiceNo" className="has-asterisk">
                  Invoice No.
                </label>
              </div>
              <div className="col-md-5">
                <div className="input-group">
                  <input
                    type="text"
                    name="invoiceNo"
                    onChange={handleInputChange}
                    value={formData.invoiceNo}
                    required
                    maxLength={30}
                    onKeyPress={(e) => {
                      if (e.charCode === 32 && e.target.selectionStart === 0) {
                        e.preventDefault(); // Prevent adding space at the beginning
                      }
                    }}
                  />
                </div>
              </div>
              <div className="col-md-3"></div>
            </div>
            <div className="row mt-1">
              <div className="col-md-2"></div>
              <div className="col-md-2 ">
                <label htmlFor="fromDate" className="has-asterisk">
                  From Date
                </label>
              </div>
              <div className="col-md-5">
                <div className="input-group">
                  <input
                    type="date"
                    name="fromDate"
                    onChange={handleInputChange}
                    value={formData.fromDate}
                    required
                    max={today}
                  />
                </div>
                {errors.fromDate && (
                  <small className="text-danger">{errors.fromDate}</small>
                )}
              </div>
              <div className="col-md-3"></div>
            </div>
            <div className="row mt-1">
              <div className="col-md-2"></div>
              <div className="col-md-2 ">
                <label htmlFor="toDate" className="has-asterisk">
                  To Date
                </label>
              </div>
              <div className="col-md-5">
                <div className="input-group">
                  <input
                    type="date"
                    name="toDate"
                    onChange={handleInputChange}
                    value={formData.toDate}
                    required
                    max={today}
                  />
                </div>
                {errors.toDate && (
                  <small className="text-danger">{errors.toDate}</small>
                )}
              </div>
              <div className="col-md-3"></div>
            </div>
            <div className="row mt-1">
              <div className="col-md-2"></div>

              <div className="col-md-2 ">
                <label htmlFor="receiptFile" className="has-asterisk">
                  Receipt
                </label>
              </div>
              <div className="col-md-5">
                <div className="input-group">
                  <div className="input-group">
                    <input
                      type="file"
                      name="receiptFile"
                      id="file-input"
                      onChange={handleInputChange}
                      // value={formData.receiptFile}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-3"></div>
              </div>
            </div>
            {formData.reimbursementType === "Travel Expense" && (
              <>
                <div className="row mt-1">
                  <div className="col-md-2"></div>

                  <div className="col-md-2 ">
                    <label htmlFor="fromPlace" className="has-asterisk">
                      From
                    </label>
                  </div>
                  <div className="col-md-5">
                    <div className="input-group">
                      <input
                        type="text"
                        name="fromPlace"
                        onChange={handleInputChange}
                        value={formData.fromPlace}
                        required
                        onKeyPress={(e) => {
                          if (
                            e.charCode === 32 &&
                            e.target.selectionStart === 0
                          ) {
                            e.preventDefault(); // Prevent adding space at the beginning
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-3"></div>
                </div>
                <div className="row mt-1">
                  <div className="col-md-2"></div>

                  <div className="col-md-2 ">
                    <label htmlFor="toPlace" className="has-asterisk">
                      To
                    </label>
                  </div>
                  <div className="col-md-5">
                    <div className="input-group">
                      <div className="input-group">
                        <input
                          type="text"
                          name="toPlace"
                          onChange={handleInputChange}
                          value={formData.toPlace}
                          required
                          onKeyPress={(e) => {
                            if (
                              e.charCode === 32 &&
                              e.target.selectionStart === 0
                            ) {
                              e.preventDefault(); // Prevent adding space at the beginning
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-3"></div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-md-2"></div>

                  <div className="col-md-2 ">
                    <label htmlFor="toPlace" className="has-asterisk">
                      Vehicle Type
                    </label>
                  </div>
                  <div className="col-md-5">
                    <div className="input-group">
                      <select
                        id="vehicleType"
                        required
                        name="vehicleType"
                        value={formData.vehicleType}
                        onChange={handleInputChange}
                      >
                        <option value="">Select vehicle type</option>
                        <option>Two Wheeler</option>
                        <option>Four Wheeler</option>
                      </select>
                    </div>
                    <div className="col-md-3"></div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-md-2"></div>

                  <div className="col-md-2">
                    <label htmlFor="distance" className="has-asterisk">
                      Distance (in KM)
                    </label>
                  </div>
                  <div className="col-md-5">
                    <div className="input-group">
                      <input
                        type="tel"
                        name="distance"
                        onChange={handleInputChange}
                        value={formData.distance}
                        required
                        onKeyPress={(e) => {
                          const isDigit = /\d/.test(e.key);
                          if (!isDigit) {
                            e.preventDefault();
                          }
                        }}
                        onPaste={(e) => {
                          e.preventDefault();
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-3"></div>
                </div>
              </>
            )}

            <div className="row mt-1">
              <div className="col-md-2"></div>

              <div className="col-md-2">
                <label htmlFor="reimbursementAmount" className="has-asterisk">
                  Amount
                </label>
              </div>
              <div className="col-md-5">
                <div className="input-group">
                  <input
                    type="tel"
                    name="reimbursementAmount"
                    onChange={handleInputChange}
                    value={formData.reimbursementAmount}
                    required
                    onKeyPress={(e) => {
                      const isDigit = /\d/.test(e.key);
                      if (
                        !isDigit ||
                        parseInt(e.target.value + e.key) > 200000
                      ) {
                        e.preventDefault();
                      }
                    }}
                    onPaste={(e) => {
                      e.preventDefault();
                    }}
                  />
                </div>
              </div>
              <div className="col-md-3"></div>
            </div>
            <div className="row mt-1">
              <div className="col-md-2"></div>

              <div className="col-md-2">
                <label htmlFor="comment" className="has-asterisk">
                  Comment
                </label>
              </div>
              <div className="col-md-5">
                <div className="input-group">
                  <textarea
                    value={formData.comment}
                    name="comment"
                    rows={3}
                    cols={7}
                    onChange={handleInputChange}
                    required
                  ></textarea>
                </div>
              </div>
              <div className="col-md-3"></div>
            </div>
          </div>
          <br />

          <div className="action text-center">
            <button className="btn btn-secondary" onClick={handleReset}>
              Reset
            </button>
            <button
              className={
                isLoading ? "theme-button disabled ms-2 " : "theme-button ms-2 "
              }
              type="submit"
            >
              Submit
            </button>
          </div>
        </div>
      </form>

      <Footer />
    </div>
  );
};

export default Reimbursement;

const ReimbursementSchema = Yup.object().shape({
  date: Yup.date().required("Invoice Date is required"),
  fromDate: Yup.date().required("From Date is required"),
  toDate: Yup.date()
    .required("To Date is required")
    .when("fromDate", (fromDate, schema) => {
      return schema.test({
        test: (toDate) => {
          return new Date(toDate) >= new Date(fromDate);
        },
        message: "'To Date' must be later than or equal to 'From Date'",
      });
    }),
});
