import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { deleteApi, getApi } from "../../utils/api";
import { API_EXPERIENCE } from "../../config/Endpoints";
import { toast } from "react-toastify";
import AddWorkExperience from "./AddWorkExperience";
import { ThreeDots } from "react-loader-spinner";
import moment from "moment";

const WorkExperience = ({ workData }) => {
  const [experienceList, setExperienceList] = useState([]);
  const accessToken = Cookies.get("accessToken");
  const [selectedWork, setSelectedWork] = useState({});
  const [selectedWorkId, setSelectedWorkId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setExperienceList(workData);
  }, [workData]);

  const handleDelete = () => {
    setIsLoading(true);
    deleteApi(API_EXPERIENCE + `/${selectedWorkId}`, accessToken)
      .then((res) => {
        if (res.status === 200) {
          let newArray = experienceList.filter(
            (item) => item.experienceId !== selectedWorkId
          );
          setExperienceList(newArray);
          setIsLoading(false);
          const modalClose = document.getElementsByClassName(
            "cancel-modal-experience-delete"
          )[0];
          modalClose.click();
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  const handleView = (item) => {
    setSelectedWork(item);
  };
  const handleDeleteAlert = (id) => {
    setSelectedWorkId(id);
  };

  return (
    <>
      <div className="table-responsive">
        {experienceList?.length === 0 && experienceList && (
          <div className="fs-4 text-secondary text-center">
            No records available
          </div>
        )}
        {experienceList?.length !== 0 && (
          <table className="resume custom">
            {/* Table Headings */}
            <thead>
              <tr>
                <th>Job Title</th>
                <th>Previous Company</th>
                <th>From</th>
                <th>To</th>
                <th>Description</th>
                <th>Actions</th>
              </tr>
            </thead>

            {/* Table Body */}
            <tbody>
              {experienceList &&
                experienceList?.map((item, index) => (
                  <tr key={index}>
                    <td>{item.jobTitle}</td>
                    <td>{item.previousCompany}</td>
                    <td>{moment(item.fromDate).format("DD-MM-yyyy")}</td>
                    <td>{moment(item.toDate).format("DD-MM-yyyy")}</td>
                    <td>
                      {item.jobDescription && item.jobDescription.trim() !== ""
                        ? item.jobDescription
                        : "-"}
                    </td>
                    <td>
                      <a
                        href="javascript:void(0)"
                        onClick={() => handleDeleteAlert(item.experienceId)}
                        className="text-danger"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdropExperienceDelete"
                      >
                        Delete
                      </a>
                      <a
                        href="javascript:void(0)"
                        onClick={() => handleView(item)}
                        className="ms-2"
                      >
                        Edit
                      </a>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>
      <button
        className="btn btn-primary mt-3 add-experience"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdropExperience"
      >
        + Add Experience
      </button>

      <div
        className="modal fade"
        id="staticBackdropExperience"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <AddWorkExperience
              setExperienceList={setExperienceList}
              selectedWork={selectedWork}
              setSelectedWork={setSelectedWork}
            />
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="staticBackdropExperienceDelete"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-body">
              <h5 className="text-center">
                Are you sure you want to delete this experience?
              </h5>
            </div>
            <div className="justify-content-center modal-footer">
              <button
                type="button"
                className={
                  isLoading ? "theme-button disabled " : "theme-button "
                }
                onClick={handleDelete}
              >
                {isLoading ? (
                  <ThreeDots
                    height="20"
                    width="53"
                    radius="9"
                    color="white"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                ) : (
                  "Yes"
                )}
              </button>
              <button
                type="button"
                className="theme-button bg-grey mx-3 w-35 cancel-modal-experience-delete"
                data-bs-dismiss="modal"
                onClick={() => setSelectedWorkId("")}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkExperience;
