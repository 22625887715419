import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { deleteApi, getApi, postApi, putApi } from "../../../utils/api";
import Pagination from "../../../components/Pagination";
import {
  API_BIOMETRIC,
  API_BIOMETRIC_CREATE,
  API_BIOMETRIC_UPDATE,
} from "../../../config/Endpoints";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import moment from "moment";

const BiometricList = ({ employeeId }) => {
  const [biometricList, setBiometricList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const accessToken = Cookies.get("accessToken");
  const [selectedBiometric, setSelectedBiometric] = useState({});
  const [formData, setFormData] = useState({
    biometricId: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = () => {
    setIsLoading(true);
    setBiometricList([]);
    getApi(API_BIOMETRIC + employeeId, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setBiometricList(res.data.data);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const requestData = {
        biometricid: formData.biometricId,
        employeeId: employeeId,
      };

      await putApi(API_BIOMETRIC_CREATE, requestData, accessToken)
        .then(async (res) => {
          if (res?.data?.data) {
            setIsLoading(false);
            await fetchList();
            const modalClose = document.getElementsByClassName(
              "cancel-modal-biometric"
            )[0];
            modalClose.click();
            toast.success(res?.data?.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((e) => {
          toast.error(e?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsLoading(false);
        });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    setFormData({
      biometricId: "",
    });
    setSelectedBiometric({});
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const requestData = {
        biometricid: formData.biometricId,
        employeeId: employeeId,
        id: selectedBiometric?.id,
      };

      await postApi(API_BIOMETRIC_UPDATE, requestData, accessToken)
        .then(async (res) => {
          if (res?.data?.data) {
            setIsLoading(false);
            await fetchList();
            const modalClose = document.getElementsByClassName(
              "cancel-modal-biometric"
            )[0];
            modalClose.click();
            toast.success(res?.data?.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((e) => {
          toast.error(e?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsLoading(false);
        });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleView = (data) => {
    setSelectedBiometric(data);
    setFormData({
      biometricId: data?.biometricid,
    });
  };

  const handleDelete = () => {
    setIsLoading(true);
    deleteApi(API_BIOMETRIC + biometricList?.id, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setIsLoading(false);
          fetchList();
          const modalClose = document.getElementsByClassName(
            "cancel-modal-dependent-delete"
          )[0];
          modalClose.click();
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  return (
    <>
      {Object.keys(biometricList)?.length === 0 && (
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
          <button
            className="btn btn-primary mt-3 add-dependent text-right"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdropBiometric"
          >
            + Add
          </button>
        </div>
      )}
      <div className="table-responsive mt-4">
        {Object.keys(biometricList)?.length === 0 && (
          <div className="fs-4 text-secondary text-center">
            No records available
          </div>
        )}
        {Object.keys(biometricList)?.length > 0 && (
          <>
            <table className="resume custom">
              {/* Table Headings */}
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Biometric Id</th>
                  {/* <th>Employee Id</th> */}
                  {/* <th>Created By</th> */}
                  <th>Creation Date</th>
                  <th>status</th>
                  <th>Action</th>
                </tr>
              </thead>

              {/* Table Body */}
              <tbody>
                <tr>
                  <td>{biometricList?.id}</td>
                  <td>{biometricList?.biometricid}</td>
                  {/* <td>{biometricList?.employeeId}</td> */}
                  {/* <td>{biometricList?.createdBy}</td> */}
                  <td>
                    {biometricList?.creationDate
                      ? moment(biometricList?.creationDate).format("DD-MM-yyyy")
                      : "-"}
                  </td>
                  <td>{biometricList?.status}</td>
                  <td>
                    <a
                      href="javascript:void(0)"
                      onClick={() => handleView(biometricList)}
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdropBiometric"
                    >
                      Edit
                    </a>
                    <a
                      href="javascript:void(0)"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdropBiometricDelete"
                      className="text-danger ms-2"
                    >
                      Delete
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            {/* <div className="row">
              <div className="col-md-3">
                <div>
                  Dropdown for selecting items per page
                  <label htmlFor="itemsPerPage">Items per page </label>
                  <select
                    id="itemsPerPage"
                    onChange={handleLimitChange}
                    value={limit}
                    className="border ms-3 w-25 p-2"
                  >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                    <option value={40}>40</option>
                  </select>
                </div>
              </div>
              <div className="col-md-9">
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </div>
            </div> */}
          </>
        )}
      </div>

      <div
        className="modal fade"
        id="staticBackdropBiometric"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropBiometric"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title text-center flex"
                id="staticBackdropLabel"
              >
                {selectedBiometric?.id ? "Update " : "Add "}Biometric
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleReset}
              ></button>
            </div>
            <form
              onSubmit={selectedBiometric?.id ? handleUpdate : handleSubmit}
            >
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-2"></div>
                  <div className="col-md-4">
                    <label htmlFor="biometricId" className="has-asterisk">
                      Biometric ID
                    </label>
                  </div>

                  <div className="col-md-5">
                    <input
                      type="text"
                      name="biometricId"
                      value={formData.biometricId}
                      onChange={handleInputChange}
                      required
                      maxLength={10}
                      className="border"
                      onKeyPress={(e) => {
                        const char = String.fromCharCode(e.charCode);
                        if (e.charCode === 32 || !char.match(/^[0-9a-zA-Z]$/)) {
                          e.preventDefault(); // Prevent adding space or special characters
                        }
                      }}
                    />
                  </div>
                  <div className="col-md-1"></div>
                </div>

                <div className="col-md-1"></div>
              </div>

              <div className="justify-content-center modal-footer">
                <button
                  type="submit"
                  className={
                    isLoading ? "theme-button disabled " : "theme-button "
                  }
                >
                  {isLoading ? (
                    <ThreeDots
                      height="20"
                      width="53"
                      radius="9"
                      color="white"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : selectedBiometric?.id ? (
                    "Update "
                  ) : (
                    "Submit"
                  )}
                </button>
                <button
                  type="button"
                  className="theme-button bg-grey mx-3 w-35 cancel-modal-biometric"
                  data-bs-dismiss="modal"
                  onClick={handleReset}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="staticBackdropBiometricDelete"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-body">
              <h5 className="text-center">
                Are you sure you want to delete this biometric?
              </h5>
            </div>
            <div className="justify-content-center modal-footer">
              <button
                type="button"
                className={
                  isLoading ? "theme-button disabled " : "theme-button "
                }
                onClick={handleDelete}
              >
                {isLoading ? (
                  <ThreeDots
                    height="20"
                    width="53"
                    radius="9"
                    color="white"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                ) : (
                  "Yes"
                )}
              </button>
              <button
                type="button"
                className="theme-button bg-grey mx-3 w-35 cancel-modal-dependent-delete"
                data-bs-dismiss="modal"
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BiometricList;
