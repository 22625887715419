import { TailSpin, ThreeDots } from "react-loader-spinner";
import {
  API_TOTAL_ASSET,
  API_V1_TOTAL_ASSET,
  ASSET_TYPE_LIST,
} from "../../config/Endpoints";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { getApi } from "../../utils/api";
import { toast } from "react-toastify";
import { toggleMenu } from "../../utils/helpers";
import AssetReturnRequestModal from "./AssetReturnRequestModal";

const AssetReturnATO = () => {
  const [assetList, setAssetList] = useState([]);
  const [isListLoading, setIsListLoading] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [assetTypeList, setAssetTypeList] = useState([]);

  const [filteredItems, setFilteredItems] = useState();
  const [status, setStatus] = useState("");
  const accessToken = Cookies.get("accessToken");
  const [formData, setFormData] = useState({
    filterOwner: "",
    filterAssetType: "",
    // status: "ALL",
  });

  useEffect(() => {
    fetchAssetTypeList();
    handleFilter();
  }, []);

  const fetchAssetTypeList = async () => {
    setIsListLoading(true);
    await getApi(ASSET_TYPE_LIST, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setAssetTypeList(res.data.data);
          setIsListLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsListLoading(false);
      });
  };

  const handleFilter = async () => {
    const filterOwnerDefault = formData.filterOwner
      ? formData.filterOwner
      : "ALL";
    const filterAssetTypeDefault = formData.filterAssetType
      ? formData.filterAssetType
      : "0";
    if (filterOwnerDefault && filterAssetTypeDefault) {
      setIsLoading(true);
      await getApi(
        API_V1_TOTAL_ASSET +
          `?owner=${filterOwnerDefault}&assestTypeId=${filterAssetTypeDefault}`,
        accessToken
      )
        .then((res) => {
          if (res.status === 200) {
            setAssetList(res.data.data);
            setFilteredItems(res.data.data);
            setIsLoading(false);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      toast.error(
        "Please choose an asset type and owner to filter the assets.",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleStatus = (e) => {
    const { value } = e.target;
    setStatus(value);
    if (value !== "ALL" && value !== "") {
      const listData = assetList?.filter(
        (item) => item.assignedStatus === value
      );
      setFilteredItems(listData);
    } else {
      setFilteredItems(assetList);
    }
  };
  return (
    <div className="wrapper-body">
      {isLoading && (
        <div className="overlay">
          <div className="mt-5">
            <TailSpin
              height="100"
              width="100"
              radius="1"
              color="blue"
              ariaLabel="tail-spin-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <h1>Asset RTO</h1>
        {filteredItems?.length > 0 && (
          <div className="total">Total :{filteredItems?.length}</div>
        )}
      </div>
      <div className="filters">
        {/* <form action="index.html" id="form_filters"> */}
        {/* Search By Company */}
        <div className="search">
          {/* <label htmlFor="assetType"> Asset type</label> */}
          <select
            id="filterAssetType"
            value={formData.filterAssetType}
            required
            onChange={handleInputChange}
            name="filterAssetType"
            disabled={isListLoading}
          >
            <option value="">Select an asset type</option>
            <option value={0}>All</option>
            {assetTypeList &&
              assetTypeList.map((item) => (
                <option value={item.assestId} key={item.assestId}>
                  {item.name}
                </option>
              ))}
          </select>
        </div>
        <div className="search ">
          {/* <label htmlFor="filterOwner"> Owner</label> */}
          <select
            id="filterOwner"
            value={formData.filterOwner}
            required
            onChange={handleInputChange}
            name="filterOwner"
          >
            <option value="">Select an owner</option>
            <option value="ALL">All</option>
            <option value="Caelius">Caelius</option>
            <option value="Salesforce">Salesforce</option>
            <option value="Consultant">Consultant</option>
            <option value="SQE">SQE Labs</option>
          </select>
        </div>
        <div className="search ">
          <select
            id="status"
            value={status}
            required
            onChange={handleStatus}
            name="status"
          >
            <option value="">Select an status</option>
            <option value="ALL">All</option>
            <option value="Assigned">Assigned</option>
            <option value="Not Assigned"> Not Assigned</option>
          </select>
        </div>
        <div className="actions">
          <button
            className="export theme-button "
            type="button"
            onClick={handleFilter}
          >
            Filter
          </button>
        </div>
        <div class="actions">
          <a class="export theme-button" href="/dashboard/returned-asset">
            Returned Asset
          </a>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          {!isLoading && filteredItems?.length > 0 && (
            <div className="table-responsive">
              <table className="resume custom">
                {/* Table Headings */}
                <thead>
                  <tr>
                    <th>Serial No.</th>
                    <th>Asset Type</th>
                    <th>Owner</th>
                    <th>Serial Number</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>

                {/* Table Body */}
                <tbody>
                  {filteredItems &&
                    filteredItems?.map((item, index) => (
                      <tr key={index}>
                        <td data-title="Serial_No">{index + 1}</td>
                        <td>{item.assetName}</td>
                        <td>{item.owner}</td>
                        <td>{item.serialNumber}</td>
                        <td>{item.assignedStatus}</td>
                        <td>
                          {item.assignedStatus === "Not Assigned" ? (
                            <a
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdropAssetRTO"
                              onClick={() => {
                                setSelectedAsset(item);
                              }}
                            >
                              View
                            </a>
                          ) : (
                            <p className="ms-2">-</p>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>

      <div
        className="modal fade"
        id="staticBackdropAssetRTO"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <AssetReturnRequestModal
              selectedAsset={selectedAsset}
              fetchList={handleFilter}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssetReturnATO;
