import React, { useState } from "react";
import {
  getCurrentWeekDates,
  getNextWeekDates,
  getPreviousWeekDates,
  toggleMenu,
} from "../../utils/helpers";
import Footer from "../../components/Footer";

const TimeSheetView = () => {
  const currentWeek = getCurrentWeekDates();
  return (
    <>
      <div className="wrapper-body">
        {/* Page Heading */}
        <div className="page-heading">
          <div
            id="showMenuBtn"
            className="collapse-button"
            onClick={toggleMenu}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>

          <h1>Timesheet</h1>

          <div className="w-100 d-flex justify-content-end">
            <button className="btn btn-primary">&lt; Previous</button>
            &nbsp;
            <button className="btn btn-primary">Next &gt; </button>
          </div>
        </div>
        {/* Data Table */}
        <div className="table-responsive">
          <table className="resume custom">
            {/* Table Headings */}
            <thead>
              <tr>
                <th>Projects</th>
                {currentWeek?.map((item) => (
                  <th>{item}</th>
                ))}
                <th>Total</th>
              </tr>
            </thead>
            {/* Table Body */}
            <tbody>
              <tr>
                <td>ABC</td>
                {currentWeek?.map((item) => (
                  <td>
                    <input type="time" className="border w-100" />
                  </td>
                ))}

                <td>
                  <input type="text" value="00:00" />
                </td>
              </tr>
              {/* Add Row Section*/}
              <tr className="bg-light ">
                <td style={{ width: "15%", paddingLeft: "2%" }}>
                  <select className="border">
                    <option value="">Select a project</option>
                    <option value="HRMIS">HRMIS</option>
                    <option value="T2M">T2M</option>
                    {/* {projectList.map((project) => (
                      <option key={project.id} value={project.name}>
                        {project.name}
                      </option>
                    ))} */}
                  </select>
                </td>

                {currentWeek?.map((item) => (
                  <td>
                    <input type="time" className="border w-100" />
                  </td>
                ))}
                <td>
                  <input type="text" value="00:00" />
                </td>
              </tr>
              {/* Total Row */}
              <tr className="bg-muted border">
                <td>Total</td>
                {currentWeek?.map((item) => (
                  <td>
                    <input type="time" className="border w-100" />
                  </td>
                ))}
                <td>
                  <input type="text" value="00:00" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* footer */}
        <Footer />
      </div>
    </>
  );
};

export default TimeSheetView;
