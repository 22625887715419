import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { getApi } from "../../utils/api";
import Cookies from "js-cookie";
import { USER_PROFILE } from "../../config/Endpoints";
import { toggleMenu } from "../../utils/helpers";
import Footer from "../../components/Footer";

const Profile = () => {
  const { state } = useLocation();
  const { data } = state || {};
  const accessToken = Cookies.get("accessToken");

  const fetchUser = () => {
    getApi(USER_PROFILE, accessToken)
      .then((res) => {
        if (res.status === 200) {
          // console.log(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <div className="wrapper-body">
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <form id="assign-asset">
        <div className="card">
          <div className="row">
            <div className="col-md-2 border-right">
              <div className="d-flex flex-column align-items-center text-center p-3 py-2">
                <img
                  className="rounded-circle"
                  width="150px"
                  src="https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg"
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="d-flex flex-column  p-1 py-5">
                <h5 className="font-weight-bold text-black ">
                  {data?.firstName
                    ? data?.firstName + " " + data?.lastName
                    : "Manjeet Saini"}
                </h5>
                <span className="text-black">Solution Architect</span>
                <small
                  className="text-black font-small"
                  style={{ fontSize: "11px" }}
                >
                  {data?.ccEmail
                    ? data?.ccEmail
                    : "manjeet.saini@caeliusconsulting.com"}
                </small>
              </div>
            </div>
            <div className="col-md-2">
              <div className="d-flex flex-column  text-black-50 p-1 py-5">
                <p>Employee Id</p>
                <p>Department</p>
                <p>Manager</p>
                <p>Location</p>
              </div>
            </div>
            <div className="col-md-2">
              <div className="d-flex flex-column  p-1 py-5">
                <p>CCIT/192/2023</p>
                <p>MuleSoft</p>
                <p>Avneesh Sharma</p>
                <p>Mohali,Pb.</p>
              </div>
            </div>
            <div className="col-md-2">
              <div className="d-flex flex-column  text-black-50 p-1 py-5">
                <p>D.O.J</p>
                <p>Work Shift</p>
                <p>Status</p>
              </div>
            </div>
            <div className="col-md-2">
              <div className="d-flex flex-column  p-1 py-5">
                <p>Dec 03,2022</p>
                <p>02:00 pm - 11:00 pm</p>
                <p>{data?.status ? data?.status : "Active"}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <h3 className="heading">PERSONAL INFORMATION</h3>
            <div className="col-md-3">
              <div className="d-flex flex-column">
                <span className="text-black-50">Father's Name</span>
                <p>Mr. Parkash Saini</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="d-flex flex-column">
                <span className="text-black-50">Mother's Name</span>
                <p>Mrs. Veena Rani</p>
              </div>
            </div>
            <div className="col-md-2">
              <div className="d-flex flex-column">
                <span className="text-black-50">Contact</span>
                <p>+91 98765-43210</p>
              </div>
            </div>
            <div className="col-md-2">
              <div className="d-flex flex-column">
                <span className="text-black-50">Alternate No.</span>
                <p>-</p>
              </div>
            </div>
            <div className="col-md-2">
              <div className="d-flex flex-column">
                <span className="text-black-50">Blood Group</span>
                <p>B+ve</p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="d-flex flex-column">
                <span className="text-black-50">Address</span>
                <p>#63,Floor no. Street name , Moonak,Sangrur,Punjab. 140301</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="d-flex flex-column">
                <span className="text-black-50">Email</span>
                <p>manjeet.saini85@gmail.com</p>
              </div>
            </div>
            <div className="col-md-2">
              <div className="d-flex flex-column">
                <span className="text-black-50">College/University</span>
                <p>Thapar University,Patiala</p>
              </div>
            </div>
            <div className="col-md-2">
              <div className="d-flex flex-column">
                <span className="text-black-50">Experience</span>
                <p>7.8 years</p>
              </div>
            </div>
          </div>
          <div className="row py-4">
            <h3 className="heading">CERTIFICATIONS</h3>
            <div className="col-md-3">
              <div className="d-flex flex-column">
                <span className="text-black-50">Father's Name</span>
                <p>Mr. Parkash Saini</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="d-flex flex-column">
                <span className="text-black-50">Mother's Name</span>
                <p>Mrs. Veena Rani</p>
              </div>
            </div>
            <div className="col-md-2">
              <div className="d-flex flex-column">
                <span className="text-black-50">Contact</span>
                <p>+91 98765-43210</p>
              </div>
            </div>
            <div className="col-md-2">
              <div className="d-flex flex-column">
                <span className="text-black-50">Alternate No.</span>
                <p>-</p>
              </div>
            </div>
            <div className="col-md-2">
              <div className="d-flex flex-column">
                <span className="text-black-50">Blood Group</span>
                <p>B+ve</p>
              </div>
            </div>
          </div>
          <div className="row py-4">
            <h3 className="heading">TIME OFF</h3>
            <div className="col-md-3">
              <div className="d-flex flex-column">
                <span className="text-black-50">Father's Name</span>
                <p>Mr. Parkash Saini</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="d-flex flex-column">
                <span className="text-black-50">Mother's Name</span>
                <p>Mrs. Veena Rani</p>
              </div>
            </div>
            <div className="col-md-2">
              <div className="d-flex flex-column">
                <span className="text-black-50">Contact</span>
                <p>+91 98765-43210</p>
              </div>
            </div>
            <div className="col-md-2">
              <div className="d-flex flex-column">
                <span className="text-black-50">Alternate No.</span>
                <p>-</p>
              </div>
            </div>
            <div className="col-md-2">
              <div className="d-flex flex-column">
                <span className="text-black-50">Blood Group</span>
                <p>B+ve</p>
              </div>
            </div>
          </div>
        </div>
      </form>
      <Footer />
    </div>
  );
};

export default Profile;
