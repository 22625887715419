import { useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { toast } from "react-toastify";
import { toggleMenu } from "../../utils/helpers";
import Cookies from "js-cookie";
import { postApi } from "../../utils/api";
import {
  API_EMPLOYEE_DOCUMENT,
  API_REIMBURSEMENT_BULK_UPLOAD,
} from "../../config/Endpoints";
import Footer from "../../components/Footer";

const BulkPaid = () => {
  const [bulkUploadFile, setBulkUploadFile] = useState(null);
  const [isLoading, setIsLoading] = useState();
  const accessToken = Cookies.get("accessToken");

  const handleInputChange = (event) => {
    const { type } = event.target;
    if (type === "file") {
      const file = event.target.files[0];
      // Define an array of accepted file types
      const acceptedFileTypes = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // Excel file
      ];
      const maxFileSize = 8 * 1024 * 1024; // 8 MB in bytes

      if (file.size > maxFileSize) {
        // File size exceeds the maximum allowed size
        toast.error("File size exceeds the maximum limit of 8MB.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        event.target.value = null; // Clear the file input
      } else if (acceptedFileTypes.includes(file.type)) {
        setBulkUploadFile(file);
      } else {
        // File type is not supported, show an alert
        toast.error(
          "Unsupported file type. Please select a valid Excel file.",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );

        event.target.value = null; // Clear the file input
      }
    }
  };
  const handleReset = () => {
    setBulkUploadFile(null);
    document.getElementById("file-input").value = "";
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const formdata = new FormData();
      formdata.append("file", bulkUploadFile);

      await postApi(API_REIMBURSEMENT_BULK_UPLOAD, formdata, accessToken)
        .then((res) => {
          setIsLoading(false);
          handleReset();
          toast.success("Document Successfully Uploaded.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
        .catch((e) => {
          setIsLoading(false);
          toast.error(e.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong. Please try again!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  return (
    <div className="wrapper-body">
      {isLoading && (
        <div className="overlay">
          <div className="loader-container">
            <TailSpin
              height="100"
              width="100"
              radius="1"
              color="blue"
              ariaLabel="tail-spin-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <h1></h1>
      </div>
      <form id="assign-asset" onSubmit={handleSubmit}>
        <div className="card">
          <h3 className="heading">Bulk Paid</h3>
          <div className="inputs">
            <div className="row mt-1">
              <div className="col-md-2"></div>

              <div className="col-md-2 ">
                <label htmlFor="bulkUploadFile" className="has-asterisk">
                  Reimbursement File
                </label>
              </div>
              <div className="col-md-5">
                <div className="input-group">
                  <input
                    type="file"
                    name="bulkUploadFile"
                    id="file-input"
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <small className="text-danger pt-0">
                  Note: Please upload valid <strong>.xlsx</strong> file only . (
                  <strong>8MB</strong> max.)
                </small>
              </div>
              <div className="col-md-3"></div>
            </div>
          </div>
          <br />

          <div className="action text-center">
            <button className="btn btn-secondary" onClick={handleReset}>
              Reset
            </button>
            <button
              className={
                isLoading ? "theme-button disabled ms-2 " : "theme-button ms-2 "
              }
              type="submit"
            >
              Submit
            </button>
          </div>
        </div>
      </form>

      <Footer />
    </div>
  );
};

export default BulkPaid;
