import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { getApi, putApi } from "../../utils/api";
import {
  API_DOCUMENT_APPROVER,
  API_DOCUMENT_LIST_APPROVER,
  API_PROMOTE_EMPLOYEE,
  GET_DEPARTMENT_LIST,
  GET_DESIGNATION_LIST,
  GET_EMPLOYEE_LIST,
} from "../../config/Endpoints";
import { toast } from "react-toastify";
import { TailSpin, ThreeDots } from "react-loader-spinner";
import { toggleMenu } from "../../utils/helpers";
import Select, { components } from "react-select";
import Footer from "../../components/Footer";

const ApproveDocument = () => {
  const [employeeList, setEmployeeList] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState({});
  const [selectedEmployeeId, setSelectedEmployeeId] = useState("");
  const [selectedEmployeeSelect, setSelectedEmployeeSelect] = useState("");
  const [selectedDocument, setSelectedDocument] = useState({});
  const [documentAction, setDocumentAction] = useState("");
  const [reason, setReason] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isListLoading, setIsListLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);

  const accessToken = Cookies.get("accessToken");

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = () => {
    setIsListLoading(true);
    getApi(API_DOCUMENT_LIST_APPROVER, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setEmployeeList(res.data.data);
          setIsListLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsListLoading(false);
      });
  };

  const handleChangeEmployee = (e) => {
    let employeeId = e.value;
    setSelectedEmployeeId(employeeId);
    setSelectedEmployeeSelect(e);
    let data = employeeList.filter(
      (item) => item.employeeId === Number(employeeId)
    );
    setSelectedEmployee(data?.[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);

      const APILINK =
        documentAction === "rejected"
          ? `?documentId=${selectedDocument?.documentId}&reason=${reason}&status=${documentAction}`
          : `?documentId=${selectedDocument?.documentId}&status=${documentAction}`;

      await putApi(API_DOCUMENT_APPROVER + APILINK, {}, accessToken)
        .then((res) => {
          setIsLoading(false);

          const modalClose =
            document.getElementsByClassName("cancel-approve")[0];
          modalClose.click();
          if (res?.data?.data) {
            setSelectedEmployee(res?.data?.data?.[0]);
          }

          toast.success(
            res?.data?.message ? res?.data?.message : "Successfully Approved!",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        })
        .catch((e) => {
          setIsLoading(false);
          toast.error(e?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          console.log(e);
        });
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleReset = () => {
    setSelectedEmployeeId("");
    // setSelectedEmployee({});
  };
  const handleClose = () => {
    setReason("");
    setDocumentAction("");
  };
  const optionList = employeeList?.map((item) => ({
    value: item.employeeId,
    label: item.employeeName,
    type: item.employeeType,
  }));

  const Option = (props) => {
    const { data } = props;
    const { label, type } = data;

    return (
      <components.Option {...props}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>{label}</span>
          <span style={{ fontSize: "12px", color: "gray" }}>({type})</span>
        </div>
      </components.Option>
    );
  };

  const handleChangeDocument = (documentData) => {
    setSelectedDocument(documentData);
    const modalClose = document.getElementsByClassName("open-modal-approve")[0];
    modalClose.click();
  };
  return (
    <div className="wrapper-body">
      {isListLoading && (
        <div className="overlay">
          <div className="mt-5">
            <TailSpin
              height="100"
              width="100"
              radius="1"
              color="blue"
              ariaLabel="tail-spin-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}
      {/* Page Heading */}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <h1> Approve Document</h1>
      </div>
      {employeeList?.length > 0 && (
        <>
          <div className="card">
            <form id="assign-asset">
              <h3 className="heading">Employee</h3>
              <div className="inputs">
                <div className="row">
                  <div className="col-md-3"></div>
                  <div className="col-md-6">
                    <div className="dropdown-container">
                      <Select
                        options={optionList}
                        components={{ Option }}
                        onChange={handleChangeEmployee}
                        value={selectedEmployeeSelect}
                        placeholder="Select employee"
                        isSearchable={true}
                        isDisabled={employeeList?.length === 0}
                        isRequired={true}
                      />
                    </div>
                  </div>
                  <div className="col-md-2"></div>
                </div>
              </div>
            </form>
          </div>

          {Object.keys(selectedEmployee).length > 0 && (
            <div className="table-responsive">
              <table className="resume custom">
                <thead>
                  <tr>
                    <th>Document Id</th>
                    <th>Document Type</th>
                    <th>Status</th>
                    <th className="text-center"></th>
                  </tr>
                </thead>
                <tbody>
                  {selectedEmployee?.documentResponseList?.map(
                    (item, index) => (
                      <tr key={index}>
                        <td>{item?.documentViewId}</td>
                        <td>{item?.documentType}</td>
                        <td className="text-danger fw-bolder">
                          {item?.approvalStatus === "0" ? "Pending" : ""}
                        </td>
                        <td className="text-center">
                          <button
                            className="btn btn-secondary"
                            type="button"
                            onClick={() => handleChangeDocument(item)}
                          >
                            Action
                          </button>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          )}
          <a
            data-bs-toggle="modal"
            data-bs-target="#staticBackdropApprove"
            className="open-modal-approve visually-hidden"
          >
            click
          </a>
          <div
            className="modal fade"
            id="staticBackdropApprove"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <form onSubmit={handleSubmit}>
                  <div className="modal-header">
                    <h5
                      className="modal-title text-center flex"
                      id="staticBackdropLabel"
                    >
                      Verify Document
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={handleClose}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="table-responsive">
                      <table className="resume custom">
                        <thead>
                          <tr>
                            <th>Document Id</th>
                            <th>Document Type</th>
                            <th>Status</th>
                            <th className="text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{selectedDocument?.documentViewId}</td>
                            <td>{selectedDocument?.documentType}</td>
                            <td className="text-danger fw-bolder">
                              {selectedDocument?.approvalStatus === "0"
                                ? "Pending"
                                : ""}
                            </td>
                            <td className="text-center">
                              <a
                                href={
                                  process.env.REACT_APP_IMAGE_URL +
                                  selectedDocument?.documentPath
                                }
                                download
                                target="_blank"
                              >
                                View
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="row mt-3">
                      <div className="col-md-2"></div>
                      <div className="col-md-4">
                        <label htmlFor="department" className="has-asterisk">
                          Action
                        </label>
                      </div>
                      <div className="col-md-4">
                        <select
                          id="documentAction"
                          className="border"
                          required
                          name="documentAction"
                          value={documentAction}
                          onChange={(e) => setDocumentAction(e.target.value)}
                        >
                          <option value="">Select Action</option>
                          <option value="approved">Approve</option>
                          <option value="rejected">Reject</option>
                        </select>
                      </div>

                      <div className="col-md-2"></div>
                    </div>
                    {documentAction === "rejected" && (
                      <div className="row mt-3">
                        <div className="col-md-2"></div>
                        <div className="col-md-4">
                          <label htmlFor="reason" className="has-asterisk">
                            Reason
                          </label>
                        </div>
                        <div className="col-md-4">
                          <textarea
                            value={reason}
                            rows={3}
                            cols={5}
                            className="border"
                            required
                            onChange={(e) => setReason(e.target.value)}
                            maxLength={100}
                            minLength={5}
                            onKeyPress={(e) => {
                              if (
                                e.charCode === 32 &&
                                e.target.selectionStart === 0
                              ) {
                                e.preventDefault(); // Prevent adding space at the beginning
                              }
                            }}
                          ></textarea>
                        </div>
                        <div className="col-md-2"></div>
                      </div>
                    )}

                    <div className="row mt-3">
                      <div className="col-md-4"></div>
                      <div className="col-md-4">
                        <center>
                          {selectedDocument?.length === 0 && isDataLoading && (
                            <ThreeDots
                              height="20"
                              width="50"
                              radius="2"
                              color="blue"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              wrapperClassName=""
                              visible={true}
                            />
                          )}
                        </center>
                      </div>
                      <div className="col-md-4"></div>
                    </div>
                  </div>
                  <div className="justify-content-center modal-footer">
                    <button
                      type="submit"
                      className={
                        isLoading ? "theme-button disabled " : "theme-button "
                      }
                    >
                      {isLoading ? (
                        <ThreeDots
                          height="20"
                          width="53"
                          radius="9"
                          color="white"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      ) : (
                        "Submit"
                      )}
                    </button>
                    <button
                      type="button"
                      className="theme-button bg-grey mx-3 w-35 cancel-approve"
                      data-bs-dismiss="modal"
                      onClick={handleClose}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}

      {employeeList?.length === 0 && (
        <div className="fs-4 text-secondary text-center mt-5">
          <h4>Approval for Pending Documents is not available.</h4>
        </div>
      )}
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default ApproveDocument;
