import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { logoutUser } from "../utils/Storage";
import { toggleMenu } from "../utils/helpers";

export default function SideNavbar({ menu }) {
  const [menuList] = useState(menu);
  const { pathname } = useLocation();
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [subMenu, setSubMenu] = useState(null);

  useEffect(() => {}, [menu]);

  const handleSubMenuClick = (index) => {
    if (activeSubMenu === index) {
      setActiveSubMenu(null);
      setSubMenu(null);
    } else {
      setActiveSubMenu(index);
      setSubMenu(null); // Reset subMenu when clicking on a menu item
    }
  };
  const handleSubMenuActiveClick = (index) => {
    if (subMenu === index) {
      setSubMenu(null);
    } else {
      setSubMenu(index);
    }
  };

  return (
    <nav>
      <div id="hideMenuBtn" className="close-button" onClick={toggleMenu}>
        <span></span>
        <span></span>
      </div>
      <a href="/dashboard/" className="text-center">
        <img
          src="https://www.caeliusconsulting.com/image/logo.svg"
          alt="Caelius Consulting Logo"
          width="135"
          height="32"
        />
      </a>
      <ul className="mt-5">
        {menuList?.map((item, index) => {
          const mainMenuPath = item.subMenu
            ? null
            : `/dashboard${item.achorValue}`;
          const isActiveMainMenu = pathname === mainMenuPath;
          const isSubMenuOpen =
            item.subMenu && pathname.includes(item.subMenu[0].achorValue); // Check if submenu name is in pathname

          return (
            <li
              key={index}
              className={`${activeSubMenu === index ? "active" : ""}`}
            >
              <Link
                to={mainMenuPath}
                onClick={() => handleSubMenuClick(index)}
                aria-expanded={activeSubMenu === index}
              >
                {item.name}
                {item.subMenu && (
                  <span className="ms-auto">
                    <i
                      className={`fa ${
                        activeSubMenu === index
                          ? "fa-angle-down"
                          : "fa-angle-right"
                      }`}
                    />
                  </span>
                )}
              </Link>
              {item?.subMenu?.length > 0 && (
                <ul
                  className={`submenu ${activeSubMenu === index ? "show" : ""}`}
                >
                  {item.subMenu.map((menu, subIndex) => {
                    const subMenuPath = `/dashboard${menu.achorValue}`;
                    const isActiveSubMenu = pathname === subMenuPath;

                    return (
                      <li
                        key={subIndex}
                        className={`submenu ms-2 ${
                          isActiveSubMenu ? "active" : ""
                        }`}
                      >
                        <Link
                          to={subMenuPath}
                          onClick={() => handleSubMenuActiveClick(subIndex)}
                        >
                          {menu.name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              )}
            </li>
          );
        })}
      </ul>
      {/* Log Out */}
      <div className="log-out">
        <a onClick={logoutUser} className="text-center">
          <img src="/assets/images/logout.svg" alt="Logout" />
          Log Out
        </a>
      </div>
    </nav>
  );
}
