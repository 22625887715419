import { useEffect, useState } from "react";
import { toggleMenu } from "../../utils/helpers";
import Cookies from "js-cookie";
import { getApi, putApi } from "../../utils/api";
import {
  GET_EMPLOYEE_LIST,
  GET_DESIGNATION_LIST,
  LEAVE_BALANCE,
} from "../../config/Endpoints";
import { TailSpin, ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import Select, { components } from "react-select";
import Footer from "../../components/Footer";

const LeaveUpdate = () => {
  const [employeeList, setEmployeeList] = useState([]);
  const [designationList, setDesignationList] = useState([]);
  const [selectedEmployeeLeaveData, setSelectedEmployeeLeaveData] = useState(
    {}
  );
  const [selectedEmployeeId, setSelectedEmployeeId] = useState("");
  const [selectedEmployeeSelect, setSelectedEmployeeSelect] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isListLoading, setIsListLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);

  const accessToken = Cookies.get("accessToken");
  const [formData, setFormData] = useState({
    privilegeLeave: 0,
    maternityLeave: 0,
    paternityLeave: 0,
  });

  useEffect(() => {
    fetchList();
    // fetchDepartmentList();
  }, []);

  const fetchList = () => {
    setIsListLoading(true);
    getApi(GET_EMPLOYEE_LIST, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setEmployeeList(res.data.data);
          setIsListLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsListLoading(false);
      });
  };

  const handleChangeEmployee = (e) => {
    let employeeId = e.value;
    setSelectedEmployeeLeaveData({});
    setSelectedEmployeeId(employeeId);
    setSelectedEmployeeSelect(e);
    getApi(LEAVE_BALANCE + `/${employeeId}`, accessToken)
      .then((res) => {
        if (res.status === 200) {
          let resData = res.data?.data;
          setSelectedEmployeeLeaveData(resData);
          setFormData({
            workFromHome: resData.workFromHome,
            quartelyLeave: resData.quartelyLeave,
            privilegeLeave: resData.privilegeLeave,
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);

      let obj = {
        employeeId: selectedEmployeeId,
        privilegeLeave: formData.privilegeLeave,
        maternityLeave: formData.maternityLeave,
        paternityLeave: formData.paternityLeave,
      };
      await putApi(LEAVE_BALANCE, obj, accessToken)
        .then((res) => {
          setIsLoading(false);
          let dataRes = res?.data?.data;
          setSelectedEmployeeLeaveData(dataRes);
          const modalClose =
            document.getElementsByClassName("cancel-promote")[0];
          modalClose.click();
          toast.success("Successfully Updated.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          fetchList();

          setFormData({
            workFromHome: dataRes.workFromHome,
            quartelyLeave: dataRes.quartelyLeave,
            privilegeLeave: dataRes.privilegeLeave,
          });
        })
        .catch((e) => {
          setIsLoading(false);
          toast.error(e?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          console.log(e);
        });
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleClose = () => {
    setFormData({
      workFromHome: 0,
      quartelyLeave: 0,
      privilegeLeave: 0,
    });
  };
  const optionList = employeeList?.map((item) => ({
    value: item.id,
    label: item.empName,
    type: item.employeeType,
  }));

  const Option = (props) => {
    const { data } = props;
    const { label, type } = data;

    return (
      <components.Option {...props}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>{label}</span>
          <span style={{ fontSize: "12px", color: "gray" }}>({type})</span>
        </div>
      </components.Option>
    );
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleKeyUp = (e) => {
    if (e.key === "-" || e.key < "0" || e.key > "9") {
      e.preventDefault();
    }
  };
  return (
    <div className="wrapper-body">
      {isListLoading && (
        <div className="overlay">
          <div className="mt-5">
            <TailSpin
              height="100"
              width="100"
              radius="1"
              color="blue"
              ariaLabel="tail-spin-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}
      {/* Page Heading */}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <h1> Leave Update</h1>
      </div>
      <div className="card">
        <form id="assign-asset">
          <h3 className="heading">Employee</h3>
          <div className="inputs">
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-6">
                <div className="dropdown-container">
                  <Select
                    options={optionList}
                    components={{ Option }}
                    onChange={handleChangeEmployee}
                    value={selectedEmployeeSelect}
                    placeholder="Select Employee"
                    isSearchable={true}
                    isDisabled={employeeList?.length === 0}
                    isRequired={true}
                  />
                </div>
              </div>
              <div className="col-md-2"></div>
            </div>
          </div>
        </form>
      </div>

      {selectedEmployeeLeaveData !== null &&
        Object.keys(selectedEmployeeLeaveData).length > 0 && (
          <div className="table-responsive">
            <table className="resume custom">
              <thead>
                <tr>
                  <th>Employee Id</th>
                  <th>Privilege</th>
                  <th>Maternity</th>
                  <th>Paternity</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {selectedEmployeeLeaveData?.employeeId
                      ? selectedEmployeeLeaveData?.employeeId
                      : selectedEmployeeId}
                  </td>

                  <td>
                    {selectedEmployeeLeaveData?.privilegeLeave
                      ? selectedEmployeeLeaveData?.privilegeLeave
                      : "-"}
                  </td>
                  <td>
                    {selectedEmployeeLeaveData?.maternityLeave
                      ? selectedEmployeeLeaveData?.maternityLeave
                      : "-"}
                  </td>
                  <td>
                    {selectedEmployeeLeaveData?.paternityLeave
                      ? selectedEmployeeLeaveData?.paternityLeave
                      : "-"}
                  </td>
                  <td>
                    <button
                      className="btn btn-secondary"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdropLeaveUpdate"
                    >
                      Update
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}

      <div
        className="modal fade"
        id="staticBackdropLeaveUpdate"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <form onSubmit={handleSubmit}>
              <div className="modal-header">
                <h5
                  className="modal-title text-center flex"
                  id="staticBackdropLabel"
                >
                  Leave Update
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleClose}
                ></button>
              </div>
              <div className="modal-body">
                {/* <div className="row mt-3">
                  <div className="col-md-2"></div>
                  <div className="col-md-4">
                    <label htmlFor="workFromHome">Work From Home*</label>
                  </div>
                  <div className="col-md-4">
                    <input
                      type="tel"
                      name="workFromHome"
                      value={formData.workFromHome}
                      onChange={handleInputChange}
                      required
                      className="border"
                      onKeyUp={handleKeyUp}
                      pattern="[0-9]*"
                      onKeyPress={(e) => {
                        const isDigit = /\d/.test(e.key);
                        if (!isDigit) {
                          e.preventDefault();
                        }
                      }}
                      onPaste={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </div>

                  <div className="col-md-2"></div>
                </div> */}
                <div className="row mt-3">
                  <div className="col-md-2"></div>
                  <div className="col-md-4">
                    <label htmlFor="privilegeLeave" className="has-asterisk">
                      Privilege Leave
                    </label>
                  </div>
                  <div className="col-md-4">
                    <input
                      type="tel"
                      name="privilegeLeave"
                      value={formData.privilegeLeave}
                      onChange={handleInputChange}
                      required
                      className="border"
                      onKeyUp={handleKeyUp}
                      pattern="[0-9]*"
                      onKeyPress={(e) => {
                        const isDigit = /\d/.test(e.key);
                        if (!isDigit || parseInt(e.target.value + e.key) > 25) {
                          e.preventDefault();
                        }
                      }}
                      onPaste={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </div>
                  <div className="col-md-2"></div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-2"></div>
                  <div className="col-md-4">
                    <label htmlFor="maternityLeave" className="has-asterisk">
                      Maternity Leave
                    </label>
                  </div>
                  <div className="col-md-4">
                    <input
                      type="tel"
                      name="maternityLeave"
                      value={formData.maternityLeave}
                      onChange={handleInputChange}
                      required
                      className="border"
                      onKeyUp={handleKeyUp}
                      pattern="[0-9]*"
                      onKeyPress={(e) => {
                        const isDigit = /\d/.test(e.key);
                        if (!isDigit || parseInt(e.target.value + e.key) > 25) {
                          e.preventDefault();
                        }
                      }}
                      onPaste={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </div>
                  <div className="col-md-2"></div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-2"></div>
                  <div className="col-md-4">
                    <label htmlFor="paternityLeave" className="has-asterisk">
                      Paternity Leave
                    </label>
                  </div>
                  <div className="col-md-4">
                    <input
                      type="tel"
                      name="paternityLeave"
                      value={formData.paternityLeave}
                      onChange={handleInputChange}
                      required
                      className="border"
                      onKeyUp={handleKeyUp}
                      onKeyPress={(e) => {
                        const isDigit = /\d/.test(e.key);
                        if (!isDigit || parseInt(e.target.value + e.key) > 25) {
                          e.preventDefault();
                        }
                      }}
                      pattern="[0-9]*"
                      // onKeyPress={(e) => {
                      //   const isDigit = /\d/.test(e.key);
                      //   if (!isDigit) {
                      //     e.preventDefault();
                      //   }
                      // }}
                      onPaste={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </div>
                  <div className="col-md-2"></div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-4"></div>
                  <div className="col-md-4">
                    <center>
                      {designationList.length === 0 && isDataLoading && (
                        <ThreeDots
                          height="20"
                          width="50"
                          radius="2"
                          color="blue"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      )}
                    </center>
                  </div>
                  <div className="col-md-4"></div>
                </div>
              </div>

              <div className="justify-content-center modal-footer">
                <button
                  type="submit"
                  className={
                    isLoading ? "theme-button disabled " : "theme-button "
                  }
                >
                  {isLoading ? (
                    <ThreeDots
                      height="20"
                      width="53"
                      radius="9"
                      color="white"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    "Submit"
                  )}
                </button>
                <button
                  type="button"
                  className="theme-button bg-grey mx-3 w-35 cancel-promote"
                  data-bs-dismiss="modal"
                  onClick={handleClose}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default LeaveUpdate;
