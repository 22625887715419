import React, { useEffect, useRef, useState } from "react";
import Cookies from "js-cookie";
import {
  API_DOCUMENT,
  API_DOCUMENT_STATUS,
  API_DOCUMENT_VERSION,
} from "../../config/Endpoints";
import { getApi, postApi } from "../../utils/api";
import { toast } from "react-toastify";
import { TailSpin, ThreeDots } from "react-loader-spinner";
import { getUser } from "../../utils/Storage";

const DocumentUpload = ({ documentList, fetchDocumentStatus, fetchData }) => {
  const [isLoading, setIsLoading] = useState(null);
  const [uploadFile, setUploadFile] = useState(null);
  const [documentType, setDocumentType] = useState({});
  const userData = JSON.parse(getUser("userData"));
  const [documentRecord, setDocumentRecord] = useState([]);

  const fileInputRef = useRef({});

  const accessToken = Cookies.get("accessToken");

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const formdata = new FormData();
      formdata.append("docFile", uploadFile);
      formdata.append("employeeId", userData?.id);
      formdata.append("docType", documentType?.documentType);
      await postApi(API_DOCUMENT_VERSION, formdata, accessToken)
        .then(async (res) => {
          setIsLoading(false);
          let dummyDocument = documentRecord?.filter(
            (item) => item.documentType != documentType?.documentType
          );
          const hasPendingFiles = dummyDocument?.filter(
            (doc) => doc.isMandatory === true && !doc.isUploaded
          );

          if (hasPendingFiles?.length > 0) {
            setDocumentRecord(dummyDocument);
          } else {
            fetchDocumentStatus();
          }
          await fetchData();
          toast.success(
            documentType?.documentName + " Uploaded Successfully.",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );

          await handleReset();
        })
        .catch((e) => {
          setIsLoading(false);
          toast.error(e.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } catch (error) {
      setIsLoading(false);
      const errorMessage =
        error.response?.data?.message || "An unexpected error occurred";
      toast.error(errorMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    if (documentList.length > 0) {
      setDocumentRecord(documentList);
    }
  }, [documentList]);

  const handleReset = () => {
    setUploadFile(null);
    setDocumentType({});

    if (fileInputRef?.current) {
      Object.keys(fileInputRef?.current).forEach((docType) => {
        if (fileInputRef?.current?.[docType]) {
          fileInputRef.current[docType].value = null;
        }
      });
    }
  };

  const handleFileChange = (event, data) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      // Define an array of accepted file types
      const acceptedFileTypes = [
        "application/pdf",
        "image/jpeg",
        "image/png",
        "image/jpeg",
      ];
      const maxFileSize = 8 * 1024 * 1024; // 8 MB in bytes
      if (selectedFile) {
        if (selectedFile.size > maxFileSize) {
          // File size exceeds the maximum allowed size
          toast.error("File size exceeds the maximum limit of 8MB.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          event.target.value = null; // Clear the file input
        } else if (acceptedFileTypes.includes(selectedFile.type)) {
          setDocumentType(data);
          setUploadFile(selectedFile);
        } else {
          // File type is not supported, show an alert
          toast.error(
            "Unsupported file type. Please upload a valid PDF, JPG, PNG, or JPEG.",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          event.target.value = "";
        }
      }
    }
  };

  function getFileName(filePath) {
    if (filePath && filePath.includes("/")) {
      // Extract the file name with extension from the given file path
      const fileName = filePath.split("/").pop();
      return fileName;
    }
    return "";
  }

  const handleClose = () => {
    if (documentRecord.length > 1) {
      const hasPendingFiles = documentRecord.filter(
        (doc) => doc.isMandatory === true && !doc.isUploaded
      );
      if (hasPendingFiles?.length > 0) {
        localStorage.setItem("documentModal", "Closed");
      }
    }
  };

  return (
    <>
      {isLoading && (
        <div className="overlay">
          <div className="loader-container">
            <TailSpin
              height="100"
              width="100"
              radius="1"
              color="blue"
              ariaLabel="tail-spin-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}
      <div className="modal-header">
        <h5 className="modal-title text-center flex" id="staticBackdropLabel">
          Pending Document to Upload
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          // style={{ display: "none" }}
          onClick={handleClose}
        ></button>
      </div>
      <div className="modal-body">
        <div className="table-responsive">
          <table className="resume custom">
            {/* Table Headings */}
            <thead
              style={{
                zIndex: 1,
              }}
            >
              <tr>
                <th>Serial No.</th>
                <th>Document Type</th>
                <th>Mandatory</th>
                <th>Document</th>
                <th>Action</th>
              </tr>
            </thead>

            {/* Table Body */}
            <tbody>
              {documentRecord &&
                documentRecord
                  ?.filter((data) => !data.isUploaded)
                  .map((item, index) => (
                    <tr key={index}>
                      <td data-title="Serial_No">{index + 1}</td>
                      <td data-title="documentType">{item.documentName}</td>
                      <td data-title="isUploaded">
                        {item.isMandatory ? (
                          <span className="badge bg-danger">Yes</span>
                        ) : (
                          <span className="badge bg-success"> No</span>
                        )}
                      </td>
                      <td data-title="documentPath">
                        {item?.documentPath ? (
                          getFileName(item?.documentPath)
                        ) : (
                          <>
                            <input
                              type="file"
                              id="file-input"
                              className="border w-75"
                              ref={(el) =>
                                (fileInputRef.current[item.documentType] = el)
                              }
                              onBlur={() => {
                                if (
                                  fileInputRef.current[item.documentType]
                                    ?.value === ""
                                ) {
                                  setDocumentType({});
                                  setUploadFile(null);
                                }
                              }}
                              onChange={(e) => handleFileChange(e, item)}
                            />
                          </>
                        )}
                      </td>
                      <td data-title="documentPath">
                        <button
                          type="button"
                          className={
                            item.isUploaded ||
                            isLoading ||
                            item.documentType !== documentType.documentType
                              ? "theme-button disabled "
                              : "theme-button "
                          }
                          onClick={handleSubmit}
                        >
                          Upload
                        </button>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="justify-content-center modal-footer">
        <h6 className="text-danger pt-0">
          Note: Please upload valid <strong>PDF, JPG, PNG, or JPEG</strong> file
          only. (<strong>8MB</strong> max.)
        </h6>
      </div>
    </>
  );
};

export default DocumentUpload;
