import React from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { postApi } from "../../utils/api";
import { API_RESET_PASSWORD } from "../../config/Endpoints";
import { TailSpin } from "react-loader-spinner";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";

const ResetPassword = () => {
  const [formData, setFormData] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [isLoading, setIsLoading] = useState();
  const { state } = useLocation();
  const { email, token } = state || {};
  const navigate = useNavigate();
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      if (formData.newPassword === formData.confirmPassword) {
        await postApi(
          `${API_RESET_PASSWORD}?email=${email}&password=${encodeURIComponent(
            formData.newPassword
          )}&token=${token}`,
          {}
        )
          .then((res) => {
            if (res.status === 200) {
              setIsLoading(false);
              toast.success(res?.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              navigate("/", {
                state: {
                  message: "Welcome back, Please login !",
                },
              });
            } else {
              setIsLoading(false);
            }
          })
          .catch((e) => {
            setIsLoading(false);
            toast.error(e.response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
      } else {
        toast.error("New password and confirm password does not match.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <div className="wrapper login_bg">
      {isLoading && (
        <div className="overlay">
          <div className="mt-5">
            <TailSpin
              height="100"
              width="100"
              radius="1"
              color="blue"
              ariaLabel="tail-spin-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}
      <div className="wrapper-body">
        <img
          src="https://www.caeliusconsulting.com/image/logo.svg"
          alt="Caelius Consulting Logo"
          width="150"
          height="35"
          style={{ margin: "0 auto" }}
        />
        <div className="login">
          <form id="loginForm" onSubmit={handleSubmit}>
            <input
              type="password"
              name="newPassword"
              value={formData.newPassword}
              onChange={handleInputChange}
              placeholder="Enter New Password*"
              required
            />
            <input
              type="password"
              name="confirmPassword"
              placeholder="Enter Confirm Password*"
              required
              value={formData.confirmPassword}
              onChange={handleInputChange}
            ></input>
            <button
              type="submit"
              form="loginForm"
              className="theme-button"
              // onClick={handleSubmit}
              id="submitButton"
              disabled={isLoading}
            >
              Submit
            </button>
            <div className="text-center">
              {/* {isError && <div className="error mt-3 ">{error}</div>} */}
            </div>
            <a href="/" className="forgot-password">
              Back to Login
            </a>
          </form>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default ResetPassword;
