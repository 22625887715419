import Cookies from "js-cookie";
import React, { useState } from "react";
import { GET_ONBOARDING_EMPLOYEE_LIST } from "../../config/Endpoints";
import { postApi } from "../../utils/api";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import * as Yup from "yup";

const SendEmployeeOnboarding = ({ selectedEmployee }) => {
  const [formData, setFormData] = useState({
    email: "",
    employeeName: "",
    offerLetter: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const accessToken = Cookies.get("accessToken");
  const [errors, setErrors] = useState("");

  const handleInputChange = (event) => {
    const { name, type } = event.target;
    setErrors("");
    if (type === "file") {
      const file = event.target.files[0];
      // Define an array of accepted file types
      const acceptedFileTypes = [
        "application/pdf",
        // "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        // "application/msword",
        // "application/vnd.oasis.opendocument.text",
        // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // Excel file
      ];
      const maxFileSize = 2 * 1024 * 1024; // 2 MB in bytes

      if (file) {
        if (file.size > maxFileSize) {
          // File size exceeds the maximum allowed size
          toast.error("File size exceeds the maximum limit of 2MB.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          event.target.value = null; // Clear the file input
        } else if (!acceptedFileTypes.includes(file.type)) {
          // File type is not supported
          toast.error(
            "Unsupported file type. Please select a valid PDF file.",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          event.target.value = null; // Clear the file input
        } else {
          // File type and size are supported, set the form data
          setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: file,
          }));
        }
      }
    } else {
      const value = event.target.value;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleReset = () => {
    setFormData({
      email: "",
      employeeName: "",
      offerLetter: null,
    });
    document.getElementById("file-input").value = "";
    setErrors("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      let { email } = formData;
      await validationSchema.validate(
        { email },
        {
          abortEarly: false,
        }
      );
      setErrors("");
      const formdata = new FormData();
      formdata.append("emailId", formData.email);
      formdata.append("employeeName", formData.employeeName);
      formdata.append("offerLetter", formData.offerLetter);
      await postApi(GET_ONBOARDING_EMPLOYEE_LIST, formdata, accessToken)
        .then((res) => {
          setIsLoading(false);
          // toast.success(res.data.message, {
          //   position: toast.POSITION.TOP_RIGHT,
          //   autoClose:{5000},
          // closeOnClick: true,
          // autoClose: false,
          // });
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          handleReset();
          const modalClose = document.getElementsByClassName("cancel-modal")[0];
          modalClose.click();
        })
        .catch((e) => {
          setIsLoading(false);
          toast.error(
            e?.response?.data?.message
              ? e.response.data.message
              : "Something went wrong please try again!",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        });
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });
      setIsLoading(false);

      setErrors(validationErrors);
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="modal-header">
        <h5 className="modal-title text-center flex" id="staticBackdropLabel">
          Invitation Action
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleReset}
        ></button>
      </div>
      <div className="modal-body">
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <label className="has-asterisk">Email ID</label>
          </div>

          <div className="col-md-4">
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Enter employee email"
              required
              maxLength={100}
              className="border"
            />
            {errors.email && (
              <div className="error text-danger">{errors.email}</div>
            )}
          </div>
          <div className="col-md-2"></div>
        </div>
        <div className="row mt-3">
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <label htmlFor="manager" className="has-asterisk">
              Employee Name
            </label>
          </div>
          <div className="col-md-4 ">
            <input
              type="text"
              name="employeeName"
              value={formData.employeeName}
              onChange={handleInputChange}
              placeholder="Enter employee name"
              className="border"
              required
              onKeyPress={(e) => {
                const inputValue = String.fromCharCode(e.charCode);
                if (
                  (e.charCode === 32 && e.target.selectionStart === 0) ||
                  (inputValue.match(/^\d+$/) && e.target.selectionStart === 0)
                ) {
                  e.preventDefault(); // Prevent adding space at the beginning  of the string or numbers only
                }
              }}
            />
          </div>
          <div className="col-md-2"></div>
        </div>
        <div className="row mt-3">
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <label htmlFor="manager" className="has-asterisk">
              Offer Letter
            </label>
          </div>
          <div className="col-md-4 ">
            <input
              type="file"
              name="offerLetter"
              id="file-input"
              onChange={handleInputChange}
              accept=".pdf,application/pdf"
              required
              className="border"
            />{" "}
            <small className="text-danger pt-0">
              {/* Note: Please upload <strong>.pdf</strong> file only */}
              Note: Please upload <strong>.pdf</strong> file only. (
              <strong>2MB</strong> max.)
            </small>
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>

      <div className="justify-content-center modal-footer">
        <button
          type="submit"
          className={isLoading ? "theme-button disabled " : "theme-button "}
        >
          {isLoading ? (
            <ThreeDots
              height="20"
              width="53"
              radius="9"
              color="white"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          ) : (
            "Submit"
          )}
        </button>
        <button
          type="button"
          className="theme-button bg-grey mx-3 w-35 cancel-modal"
          data-bs-dismiss="modal"
          onClick={handleReset}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default SendEmployeeOnboarding;

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required("Email is required")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Invalid email address"
    ),
});
