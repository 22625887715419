import { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import Select, { components } from "react-select";
import {
  API_UPDATE_EMPLOYEE_TYPE,
  GET_DEPARTMENT_LIST,
  GET_DESIGNATION_LIST,
  GET_EMPLOYEE_LIST,
} from "../../config/Endpoints";
import { getApi, putApi } from "../../utils/api";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const EmployeeType = ({ userData }) => {
  const [isLoading, setIsLoading] = useState();
  const [employeeList, setEmployeeList] = useState([]);
  const [isListLoading, setIsListLoading] = useState(false);
  const accessToken = Cookies.get("accessToken");
  const [manager, setManager] = useState(false);
  const [managerId, setManagerId] = useState("");
  const [departmentList, setDepartmentList] = useState([]);
  const [designationList, setDesignationList] = useState([]);
  const [departmentId, setDepartmentId] = useState("");
  const [designationId, setDesignationId] = useState("");
  const [doj, setDoj] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    fetchList();
    fetchDepartmentList();
  }, []);

  const fetchList = () => {
    setIsListLoading(true);
    getApi(GET_EMPLOYEE_LIST, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setEmployeeList(res.data.data);
          if (userData?.managerName) {
            let dummydata = res.data.data;
            dummydata
              ?.filter((it) => it.empName === userData?.managerName)
              .map((item) => {
                let obj = {
                  value: item.id,
                  label: item.empName,
                  type: item.employeeType,
                };
                setManager(obj);
                setManagerId(item.id);
              });
          }

          setIsListLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsListLoading(false);
      });
  };
  const fetchDepartmentList = async () => {
    await getApi(GET_DEPARTMENT_LIST, accessToken)
      .then(async (res) => {
        if (res.status === 200) {
          setDepartmentList(res.data.data);
          if (userData?.department) {
            let dummyData = res.data.data;
            let filteredData = dummyData?.filter(
              (it) => it.name === userData?.department
            );

            setDepartmentId({
              value: filteredData[0].id,
              label: filteredData[0].name,
            });
            await handleAPIDefaultDepartment(filteredData[0].id);
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const handleManager = (e) => {
    setManager(e);
    setManagerId(e.value);
  };
  const handleDepartment = async (e) => {
    setDepartmentId(e);
    setDesignationId("");
    setDesignationList([]);
    await handleAPIDepartment(e.value);
  };

  const handleAPIDefaultDepartment = (id) => {
    getApi(GET_DESIGNATION_LIST + `?departmentId=${id}`, accessToken).then(
      (res) => {
        if (res.status === 200) {
          setDesignationList(res.data.data);
          if (userData?.designation && res.data.data?.length > 0) {
            let dummyData = res.data.data?.filter(
              (it) => (it.name = userData?.designation)
            );
            setDesignationId({
              value: dummyData[0]?.id,
              label: dummyData[0]?.name,
            });
          }
        }
      }
    );
  };
  const handleAPIDepartment = (id) => {
    getApi(GET_DESIGNATION_LIST + `?departmentId=${id}`, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setDesignationList(res.data.data);
          // if (userData?.designation) {
          //   let dummyData = res.data.data?.filter(
          //     (it) => (it.name = userData?.designation)
          //   );
          //   setDesignationId(dummyData[0]?.id);
          // }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // Get today's date in the format yyyy-mm-dd
  const today = new Date().toISOString().split("T")[0];
  const handleInputChange = (event) => {
    const { value, type } = event.target;

    if (type === "date") {
      const selectedDate = new Date(value);
      const today = new Date();

      // Calculate two months ago from today
      const twoMonthsAgo = new Date(
        today.getFullYear(),
        today.getMonth() - 2,
        today.getDate()
      );

      // Check if selectedDate is within the last two months
      if (selectedDate >= twoMonthsAgo && selectedDate <= today) {
        setDoj(value);
      } else {
        setDoj(today);
        // toast.error("Please select a date within the last two months.", {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
      }
    } else {
      setDoj(value);
    }
  };

  function getMinDate() {
    const today = new Date();
    // Calculate two months ago
    const minDate = new Date(
      today.getFullYear(),
      today.getMonth() - 2,
      today.getDate()
    );
    return minDate.toISOString().split("T")[0];
  }

  const optionList = employeeList
    ?.filter(
      (it) =>
        Number(it?.id) !== Number(userData?.id) && it.employeeType === "REGULAR"
    )
    .map((item) => ({
      value: item.id,
      label: item.empName,
      type: item.employeeType,
    }));

  const Option = (props) => {
    const { data } = props;
    const { label, type } = data;

    return (
      <components.Option {...props}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>{label}</span>
          <span style={{ fontSize: "12px", color: "gray" }}>({type})</span>
        </div>
      </components.Option>
    );
  };
  const designationListOption = designationList?.map((item) => ({
    value: item.id,
    label: item.name,
  }));
  const departmentListOption = departmentList?.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (managerId !== "" && designationId?.value !== "") {
        setIsLoading(true);
        await putApi(
          API_UPDATE_EMPLOYEE_TYPE +
            `?employeeId=${userData?.id}&managerId=${managerId}&designationId=${designationId?.value}&employeeType=REGULAR&doj=${doj}
           `,
          {},
          accessToken
        )
          .then((res) => {
            if (res.status === 200) {
              setIsLoading(false);
              toast.success(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              navigate("/dashboard/employee-list");
            }
          })
          .catch((e) => {
            setIsLoading(false);
            toast.error(e?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            console.log(e);
          });
      } else {
        toast.error("Please fill all required fields.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      toast.error(e?.response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });

      setIsLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="modal-body">
          <div className="row mt-3">
            <div className="col-md-2"></div>
            <div className="col-md-3">
              <label htmlFor="managerName" className="has-asterisk">
                Manager Name
              </label>
            </div>
            <div className="col-md-4">
              <Select
                options={optionList}
                components={{ Option }}
                onChange={handleManager}
                value={manager}
                placeholder="Select employee"
                isSearchable={true}
                isDisabled={employeeList?.length === 0}
                isRequired={true}
                required
              />
            </div>
            <div className="col-md-2"></div>
          </div>
          <div className="row mt-3">
            <div className="col-md-2"></div>
            <div className="col-md-3">
              <label htmlFor="department" className="has-asterisk">
                Department
              </label>
            </div>
            <div className="col-md-4">
              <Select
                options={departmentListOption}
                onChange={handleDepartment}
                value={departmentId}
                placeholder="Select Department"
                isSearchable={true}
                isDisabled={departmentList?.length === 0}
                isRequired={true}
                required
              />
            </div>
            <div className="col-md-2"></div>
          </div>
          <div className="row mt-3">
            <div className="col-md-2"></div>
            <div className="col-md-3">
              <label htmlFor="designation" className="has-asterisk">
                Designation
              </label>
            </div>
            <div className="col-md-4">
              <Select
                options={designationListOption}
                onChange={(e) => setDesignationId(e)}
                value={designationId}
                placeholder="Select Designation"
                isSearchable={true}
                isDisabled={designationList?.length === 0}
                isRequired={true}
                required
              />
            </div>
            <div className="col-md-2"></div>
          </div>
          <div className="row mt-3">
            <div className="col-md-2"></div>
            <div className="col-md-3">
              <label htmlFor="employeeType" className="has-asterisk">
                Employee Type
              </label>
            </div>
            <div className="col-md-4">
              <input
                type="text"
                name="employeeType"
                className="border"
                value="REGULAR"
                readOnly
              />
            </div>
            <div className="col-md-2"></div>
          </div>
          <div className="row mt-3">
            <div className="col-md-2"></div>
            <div className="col-md-3">
              <label htmlFor="employeeType" className="has-asterisk">
                Date of Joining
              </label>
            </div>
            <div className="col-md-4">
              <input
                type="date"
                required
                name="dob"
                className="border"
                value={doj}
                placeholder="08/02/2019"
                onChange={handleInputChange}
                max={today}
                min={getMinDate()}
              />
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>
        <div className="justify-content-center modal-footer">
          <button
            type="submit"
            // onClick={handleSubmit}
            className={isLoading ? "theme-button disabled " : "theme-button "}
          >
            {isLoading ? (
              <ThreeDots
                height="20"
                width="53"
                radius="9"
                color="white"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            ) : (
              "Submit"
            )}
          </button>
          {/* <button
          type="button"
          className="theme-button bg-grey mx-3 w-35 cancel-promote"
          data-bs-dismiss="modal"
          //   onClick={handleClose}
        >
          Cancel
        </button> */}
        </div>
      </form>
    </>
  );
};

export default EmployeeType;
